/**
 * Motif v4.0.0
 * A responsive front-end framework to tell *your* story
 * http://getmotif.com
 */
/**
 * Global Stylesheet
 * The site's main (and perhaps only) stylesheet. All supporting stylesheet
 * files are imported into and compiled from here.
 */
/*

Dynamic Responsive Image

*/
/**
 * Normalize
 * Using the Normalize.css (http://necolas.github.io/normalize.css/) with only minor modification.
 */
/*

HTML5 Display Definitions

Set the appropriate `display` definitions for new HTML5 elements.

The majority will be displayed `block`.

*/
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  background: #0A0203;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}
/*

Type

*/
/*

HTML Font Size Adjust

1. Corrects text resizing oddly in IE6/7 when body `font-size` is set using
   `em` units.
2. Prevents iOS text size adjust after orientation change, without disabling
   user zoom.

*/
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */
}
html,
button,
input,
select,
textarea {
  font-family: sans-serif;
}
abbr[title] {
  border: none;
}
i,
cite,
address {
  font-style: normal;
}
dfn {
  font-style: italic;
}
b {
  font-weight: normal;
}
strong {
  font-weight: bold;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
blockquote,
menu,
ul,
ol,
dl,
table,
figure {
  /* Display & Box Model */
  margin: 1.25rem 0 0 0;
  /* Other */
  font-size: 1em;
}
menu,
ol,
ul {
  padding-left: 2.5em;
}
li ul,
li ol {
  margin-top: 0.5555555555555556em;
  margin-bottom: 0.5555555555555556em;
}
dd {
  margin-left: 2.5em;
}
blockquote {
  padding: 1.25em 2.5em;
}
blockquote > :first-child {
  margin-top: 0;
}
q {
  quotes: none;
}
q:before,
q:after {
  content: "";
  content: none;
}
mark {
  background: #ff0;
  color: #000;
}
code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}
small {
  font-size: 1em;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
/*

Unstyled List

A basic stripping of list styles.

*/
.lists__unstyled,
.lists__horizontal,
.lists__horizontal--forceful,
ul.proto__vertical-nav,
.lists__horizontal--links,
.lists__horizontal--pgs,
.lists__breadcrumb-list,
.lists__horizontal--buttons,
.proto__nav-bar-list,
.tabs nav > ul {
  padding-left: 0;
  list-style: none outside none;
}
/*

Horizontal List

The basic horizontal list module simply removes bullets from lists and inlines the list 
items, making the link `inline-block`. The idea is to give the skeleton of a 
horizontal list/navigation without adding too much style (yet).

*/
.lists__horizontal li,
.lists__horizontal--links li,
.lists__horizontal--pgs li,
.lists__breadcrumb-list li,
.lists__horizontal--buttons li,
.tabs nav > ul li {
  display: inline;
}
.lists__horizontal a,
.lists__horizontal--links a,
.lists__horizontal--pgs a,
.lists__breadcrumb-list a,
.lists__horizontal--buttons a,
.tabs nav > ul a {
  display: inline-block;
}
/*

Horizontal List (Forceful)

Rather than making the list `inline-block`, it floats the list elements 
(except on the smallest screen sizes, where it remains vertical). More precise
than the default Horizontal List.

*/
@media all and (min-width: 30em) {
  .lists__horizontal--forceful:before,
  .lists__horizontal--forceful:after {
    content: "\0020";
    height: 0;
    display: block;
    overflow: hidden;
  }
  .lists__horizontal--forceful:after {
    clear: both;
  }
}
.lists__horizontal--forceful li,
.proto__nav-bar-list > li {
  display: block;
}
@media all and (min-width: 30em) {
  .lists__horizontal--forceful li,
  .proto__nav-bar-list > li {
    float: left;
  }
}
.lists__horizontal--forceful a {
  display: block;
}
/*

Link List

A basic extension of the Horizontal List, this adds some margin to the links in
the list for a more pleasant looking list of links.

````link-list
<ul class="lists__horizontal--links">
    <li>
        <a href="#">Basic Link</a>
    </li>
    <li>
        <a href="#">Basic Link</a>
    </li>
    <li>
        <a href="#">Basic Link</a>
    </li>
</ul>
````

*/
.lists__horizontal--links a {
  margin-right: 30px;
}
.lists__horizontal--links li:last-child a {
  margin-right: 0;
}
/*

Pagination

An extension of the basic horizontal list module that simply adds enough padding on
the links for pagination.

*/
.lists__horizontal--pgs a {
  padding: 0 0.625em;
}
.lists__horizontal--pgs a,
.lists__horizontal--pgs a:hover,
.lists__horizontal--pgs a:focus {
  color: inherit;
}
/*

Breadcrumb

Another extension of the horizontal list module, adding a breadcrumb `label` 
and floating it and the ordered list.

````breadcrumb
<nav class="lists__breadcrumb" role="navigation">
    <ol class="lists__breadcrumb-list">
        <li>
            <a href="#">Breadcrumb Link</a>
        </li>
        <li>
            <a href="#">Breadcrumb Link</a>
        </li>
        <li>
            <a>Breadcrumb Link</a>
        </li>
    </ol>
</nav>
````

*/
.lists__breadcrumb {
  margin-top: 1.1111111111111112em;
}
.lists__breadcrumb-label {
  margin: 0 0.5625rem 0 0;
  float: left;
}
.lists__breadcrumb-list {
  margin: 0;
  float: left;
}
.lists__breadcrumb-list li {
  margin-right: 0.5625rem;
}
/*

Button List

````button-list
<ul class="lists__horizontal--buttons">
    <li>
        <a class="btn--primary" href="#">Button</a>
    </li>
    <li>
        <a class="btn--primary is-current" href="#">Button</a>
    </li>
    <li>
        <a class="btn--primary" href="#">Button</a>
    </li>
</ul>
````

*/
.lists__horizontal--buttons {
  margin-top: 0;
  overflow: hidden;
}
.lists__horizontal--buttons li {
  display: block;
  float: left;
}
.lists__horizontal--buttons a {
  display: block;
}
/*

Ratios

Ratios are used primarily for objects that must scale in both width and height
proportionally, such as embedded videos.

See: (http://daverupert.com/2012/04/uncle-daves-ol-padded-box/)

The `.ratios__` dimension class is placed on a wrapping element, with 
its child contents wrapped by the `.ratios__content` class.

    <div class="ratios__square">
        <div class="ratios__content">
            ...
        </div>
    </div>

For embedded videos, use the `.ratios__video` class, and it will assume an 
`iframe` is the direct child:

    <div class="ratios__video">
        <iframe src="http://youtube.com/myVidEmbed"></iframe>
    </div>

*/
.ratios__base:before {
  content: "";
}
.ratios__video:before {
  padding-top: 56.25%;
}
.ratios__1x1:before,
.ratios__square:before {
  padding-top: 100%;
}
.ratios__2x1:before {
  padding-top: 50%;
}
.ratios__1x2:before {
  padding-top: 200%;
}
.ratios__content,
.ratios__video > iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/*

Figures

Great for integrating images into bodies of text, the `.media__figure` class is 
modified by the `--left` and `--right` keywords depending on where you would 
like it placed.

````plain-media
<figure class="media__figure">
    <img src="http://placehold.it/800x444" alt="A full-width photo">
</figure>
````

````captioned-media
<figure class="media__figure">
    <img src="http://placehold.it/800x444" alt="A full-width photo with a caption">
    <figcaption class="media__figcaption">This caption enhances the photo above. It should only be a brief sentence or two long.</figcaption>
</figure>
````

*/
.media__figure,
[class*="media__figure--"] {
  margin-top: 1.1111111111111112em;
}
@media all and (min-width: 30em) {
  [class*="media__figure--"] {
    margin-top: 0;
    max-width: 50%;
  }
  .media__figure--left,
  .media__figure--right {
    padding-bottom: 0.625rem;
  }
  .media__figure--left {
    float: left;
    padding-right: 2.25rem;
  }
  .media__figure--right {
    float: right;
    padding-left: 2.25rem;
  }
}
.icons__icon {
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: text-bottom;
  fill: currentColor;
}
.no-svg .icons__icon {
  display: none;
}
svg:not(:root) {
  overflow: hidden;
}
img {
  max-width: 100%;
  border: 0;
  height: auto;
}
.lte7 img {
  -ms-interpolation-mode: bicubic;
}
audio,
canvas,
video {
  display: inline-block;
}
.lte7 audio,
.lte7 canvas,
.lte7 video {
  display: inline;
  zoom: 1;
}
/*

Audio

From Normalize.css:

> Prevents modern browsers from displaying `audio` without controls.
> Remove excess height in iOS5 devices.

*/
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden] {
  display: none;
}
/*

Font Declarations

Using the `.web-font` and `.web-font-svg` mixins, declare webfonts using the
"bulletproof" `@font-face` syntax.

See: (http://www.fontspring.com/blog/further-hardening-of-the-bulletproof-syntax/)

*/
/* @import must be at top of file, otherwise CSS will not work */
@font-face {
  font-family: 'HelveticaNeueLTStd-Cn';
  src: url('/resources/motif/fonts/HelveticaNeue/393C8F_0_0.eot');
  src: url('/resources/motif/fonts/HelveticaNeue/393C8F_0_0.eot?#iefix') format('embedded-opentype'), url('/resources/motif/fonts/HelveticaNeue/393C8F_0_0.woff2') format('woff2'), url('/resources/motif/fonts/HelveticaNeue/393C8F_0_0.woff') format('woff'), url('/resources/motif/fonts/HelveticaNeue/393C8F_0_0.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'HelveticaNeueLTStd-BdCn';
  src: url('/resources/motif/fonts/HelveticaNeue/393C8F_1_0.eot');
  src: url('/resources/motif/fonts/HelveticaNeue/393C8F_1_0.eot?#iefix') format('embedded-opentype'), url('/resources/motif/fonts/HelveticaNeue/393C8F_1_0.woff2') format('woff2'), url('/resources/motif/fonts/HelveticaNeue/393C8F_1_0.woff') format('woff'), url('/resources/motif/fonts/HelveticaNeue/393C8F_1_0.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'KlinicSlab';
  src: url('/resources/motif/fonts/KlinicSlab/KlinicSlabBold.otf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'SignatureCollectionItalic';
  src: url('/resources/motif/fonts/signature/3A022B_0_0.eot');
  src: url('/resources/motif/fonts/signature/3A022B_0_0.eot?#iefix') format('embedded-opentype'), url('/resources/motif/fonts/signature/3A022B_0_0.woff2') format('woff2'), url('/resources/motif/fonts/signature/3A022B_0_0.woff') format('woff'), url('/resources/motif/fonts/signature/3A022B_0_0.ttf') format('truetype');
}
/*

Font Classes

Each font family and weight used in the site is given its own LEGO-block class
to enable quick use and modularity.

*/
.klinic-slab {
  font-family: "KlinicSlab";
}
.helvetica {
  font-family: Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
}
.helvetica-neue {
  font-family: "HelveticaNeueLTStd-Cn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
}
.helvetica-neue-bold {
  font-family: "HelveticaNeueLTStd-BdCn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
}
.signature-font {
  font-family: "SignatureCollectionItalic";
}
/*

Heading Styles

In Motif, typography is broken into pieces that separate skin from structure.
In this case, that means the font sizing (structure) is separate from the
styling (skin) -- which includes font family, color, etc.

Heading styles are some of the type skins, and we start out with
`.primary-heading` (which is also the `h`-tag default) and
`.secondary-heading`. Add as necessary.

````primary-heading
<h1 class="primary-heading">Primary Heading</h1>
````

*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 1.75em;
}
h1,
h2,
h3,
h4,
h5,
h6,
.styles__primary-heading {
  font-family: "HelveticaNeueLTStd-Cn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-weight: normal;
}
h1 strong,
h2 strong,
h3 strong,
h4 strong,
h5 strong,
h6 strong,
.styles__primary-heading strong {
  font-family: "HelveticaNeueLTStd-BdCn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-weight: 400;
}
h1,
.styles__primary-heading {
  font-size: 60px;
  font-size: 3.75rem;
  line-height: 1.13333333;
  letter-spacing: -0.00666667em;
  margin-top: 0;
  text-transform: uppercase;
}
@media all and (min-width: 48em) {
  h1,
  .styles__primary-heading {
    font-size: 80px;
    font-size: 5rem;
    line-height: 1.125;
    letter-spacing: -0.00625em;
  }
}
h2,
.styles__secondary-heading {
  font-size: 38px;
  font-size: 2.375rem;
  line-height: 1.10526316;
  letter-spacing: -0.00526316em;
}
@media all and (min-width: 48em) {
  h2,
  .styles__secondary-heading {
    font-size: 48px;
    font-size: 3rem;
    line-height: 1.04166667;
    letter-spacing: -0.00583333em;
  }
}
h3,
.styles__tertiary-heading {
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 1.26666667;
  letter-spacing: -0.00566667em;
}
@media all and (min-width: 48em) {
  h3,
  .styles__tertiary-heading {
    font-size: 38px;
    font-size: 2.375rem;
    line-height: 1.10526316;
    letter-spacing: -0.00526316em;
  }
}
h4,
.styles__quaternary-heading {
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 1.26666667;
  letter-spacing: -0.00566667em;
}
h5,
.styles__quinary-heading {
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 1.54545455;
  letter-spacing: -0.00681818em;
  margin-top: 0;
}
.styles__heading--followLine {
  padding-bottom: 1.875rem;
}
.styles__heading--followLine:after {
  content: "";
  display: block;
  margin-top: 1.875rem;
  width: 100px;
  height: 4px;
  background-color: #A4664E;
}
.styles__heading--followLine + p,
.styles__heading--followLine + ul {
  margin-top: 0;
}
/*

Body Text

Another type style, but this mimicks the default body text of the site.

````body-text
<p>This is a paragraph of text. Some of the text may be <em>emphasised</em> and some it might even be <strong>strongly emphasised</strong>. Occasionally <q>quoted text</q> may be found within a paragraph &hellip;and of course <a href="#">a link</a> may appear at any point in the text. The average paragraph contains five or six sentences although some may contain as little or one or two while others carry on for anything up to ten sentences and beyond.</p>
````

*/
body,
.styles__body-text,
.elements__input-hint,
.elements__input-desc,
[class*="messaging__input-alert"] {
  font-family: "HelveticaNeueLTStd-Cn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
}
/*

Blockquote

A basic blockquote.

````blockquote
<figure class="blockquote">
    <blockquote>
        At last we will reveal ourselves to the the Jedi. At last we will have
        our revenge.
    </blockquote>
    <figcaption class="blockquote__cite">Darth Maul</figcaption>
</figure>
````

*/
.styles__blockquote {
  padding-left: 1.125rem;
}
.styles__blockquote blockquote {
  padding: 0;
}
.styles__blockquote-cite {
  display: block;
}
.styles__blockquote-cite:before {
  content: "\2014";
}
.large-p {
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 1.54545455;
  letter-spacing: 0.00681818em;
}
.normal-p {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.55555556;
  letter-spacing: 0.02222222em;
}
.small-p {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.625;
  letter-spacing: 0.025em;
}
.caption-p {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.85714286;
  letter-spacing: 0.02142857em;
}
.bg-text__outer {
  -webkit-box-decoration-break: clone;
  -ms-box-decoration-break: clone;
  -o-box-decoration-break: clone;
  box-decoration-break: clone;
}
.bg-text__inner {
  position: relative;
  z-index: 2;
}
/*

Typographic Scale

The building blocks for the site's type sizes. Almost exclusively, an element's
font size is assigned with one of these classes. If a new size is needed, a new
class is created here so it can be used and reused elsewhere.

The list of type sizes can grow or shrink, but be vigilant in keeping the
number of sizes to a minimum. A nice rule of thumb is that no two sizes can one
pixel in difference (forcing you to choose one over the other, giving you one
class where you previously had two).

````typographic-scale
<p class="hierarchy__canon-text">Canon Text (38px)</p>
<p class="hierarchy__paragon-text">Paragon Text (30px)</p>
<p class="hierarchy__primer-text">Primer Text (24px)</p>
<p class="hierarchy__tertia-text">Tertia Text (18px)</p>
<p class="hierarchy__normal-text">Normal Text (14px)</p>
<p class="hierarchy__petite-text">Petite Text (12px)</p>
````

Long Modifiers

The type sizes are given the `--long` modifier, allowing you to use
each type size with varying line heights.

    <p class="hierarchy__petite-text">I'm just a small bit of text, my `line-height`
    can be tight.</p>

    <p class="hierarchy__petite-text--long">I'm a long paragraph, so my `line-height`
    should be longer to enhance legibility. ... </p>

*/
.hierarchy__long-text,
.hierarchy__canon-text--long,
.hierarchy__meridian-text--long,
.hierarchy__tertia-text--long,
.hierarchy__paragon-text--long,
.hierarchy__columbian-text--long,
.hierarchy__great-text--long,
.hierarchy__primer-text--long,
.hierarchy__normal-text--long,
.hierarchy__petite-text--long,
.hierarchy__minion-text--long,
.hierarchy__non-pareil-text--long,
body,
.styles__body-text,
.elements__input-hint,
.elements__input-desc,
[class*="messaging__input-alert"] {
  line-height: 1.5;
}
/*

Canon Text

*/
.hierarchy__canon-text,
.hierarchy__canon-text--long {
  font-size: 110px;
  font-size: 6.875rem;
  line-height: 1.43636364;
  letter-spacing: -0.00881818em;
}
/*

Meridian Text

*/
.hierarchy__meridian-text,
.hierarchy__meridian-text--long {
  font-size: 80px;
  font-size: 5rem;
  line-height: 1.125;
  letter-spacing: -0.00625em;
}
/*

Tertia Text

*/
.hierarchy__tertia-text,
.hierarchy__tertia-text--long {
  font-size: 60px;
  font-size: 3.75rem;
  line-height: 1.13333333;
  letter-spacing: -0.00666667em;
}
/*

Paragon Text

*/
.hierarchy__paragon-text,
.hierarchy__paragon-text--long {
  font-size: 48px;
  font-size: 3rem;
  line-height: 1.04166667;
  letter-spacing: -0.00583333em;
}
/*

Columbian Text

*/
.hierarchy__columbian-text,
.hierarchy__columbian-text--long,
.product__section-title,
.stock-ticker .stock-price {
  font-size: 38px;
  font-size: 2.375rem;
  line-height: 1.10526316;
  letter-spacing: -0.00526316em;
}
/*

Great Text

*/
.hierarchy__great-text,
.hierarchy__great-text--long,
.featuredCard__title {
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 1.26666667;
  letter-spacing: -0.00566667em;
}
/*

Primer Text

*/
.hierarchy__primer-text,
.hierarchy__primer-text--long {
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 1.54545455;
  letter-spacing: -0.00681818em;
}
/*

Normal Text

*/
.hierarchy__normal-text,
.hierarchy__normal-text--long,
.relatedMediaCard__title,
body,
.styles__body-text,
.elements__input-hint,
.elements__input-desc,
[class*="messaging__input-alert"] {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.55555556;
  letter-spacing: -0.00722222em;
}
/*

Petite Text

*/
.hierarchy__petite-text,
.hierarchy__petite-text--long,
.figure figcaption,
.figcaption {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.625;
}
/*

Minion Text

*/
.hierarchy__minion-text,
.hierarchy__minion-text--long,
.wysiwyg h6,
.wysiwyg .hierarchy__minion-text {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.14285714;
}
/*

Non-Pareil Text

*/
.hierarchy__non-pareil-text,
.hierarchy__non-pareil-text--long {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.16666667;
}
/*

Interactive Elements

*/
/*

button

Default interactive button styles. This `.btn` class can be applied to `<a>`,
`<button>`, or `<input>` elements. Button color and style classes can be combined
with size classes, much like the type modules.

````button
<a class="button__btn" href="#">Default</a>
<a class="button__primary" href="#">Primary</a>
<a class="button__danger" href="#">Danger</a>
````

*/
button {
  font-size: 100%;
  margin: 0;
  -webkit-appearance: button;
  display: inline-block;
  cursor: pointer;
  line-height: normal;
}
button[disabled] {
  cursor: default;
}
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}
.button__base,
[class*="button__"],
.button__primary,
.button__secondary,
.product__sidebarCta,
.file-upload-button {
  width: auto;
  height: auto;
  margin: 0;
  line-height: normal;
  text-decoration: none;
  position: relative;
  -webkit-appearance: none;
  -webkit-background-clip: padding;
  -moz-background-clip: padding;
  background-clip: padding-box;
  outline: 0;
  overflow: visible;
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  background: #cfcdcd;
  color: #252325;
  text-align: center;
  padding: 0.625rem 1.125rem;
  border: 0;
  margin-top: 1.1111111111111112em;
}
p > .button__base,
p > [class*="button__"],
p > .button__primary,
p > .button__secondary,
p > .product__sidebarCta,
p > .file-upload-button {
  margin-top: 0;
}
.button__base:hover,
[class*="button__"]:hover,
.button__base:focus,
[class*="button__"]:focus,
.button__base.is-current,
[class*="button__"].is-current,
.button__primary:hover,
.button__primary:focus,
.button__primary.is-current,
.button__secondary:hover,
.button__secondary:focus,
.button__secondary.is-current,
.product__sidebarCta:hover,
.product__sidebarCta:focus,
.product__sidebarCta.is-current,
.file-upload-button:hover,
.file-upload-button:focus,
.file-upload-button.is-current {
  background: #e8e7e7;
}
/*

Button Variations

 */
.button__primary,
.file-upload-button {
  font-family: "HelveticaNeueLTStd-BdCn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  background: #0266B2;
  color: #fff;
  transition: background-color 0.2s ease;
}
.button__primary:hover,
.button__primary:focus,
.button__primary.is-current {
  background: #013b66;
  color: #fff;
}
.button__primary:hover[disabled],
.button__primary:focus[disabled],
.button__primary.is-current[disabled] {
  background: rgba(2, 102, 178, 0.35);
  color: rgba(255, 255, 255, 0.35);
}
.button__primary[disabled] {
  background: rgba(2, 102, 178, 0.35);
  color: rgba(255, 255, 255, 0.35);
}
.button__secondary {
  font-family: "HelveticaNeueLTStd-BdCn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  background: #fff;
  color: #0266B2;
  position: relative;
  transition: background-color 0.2s ease;
}
.button__secondary:hover,
.button__secondary:focus,
.button__secondary.is-current {
  background: #cfcdcd;
  color: #0266B2;
}
.button__secondary:hover[disabled],
.button__secondary:focus[disabled],
.button__secondary.is-current[disabled] {
  background: rgba(255, 255, 255, 0.35);
  color: rgba(2, 102, 178, 0.35);
}
.button__secondary[disabled] {
  background: rgba(255, 255, 255, 0.35);
  color: rgba(2, 102, 178, 0.35);
}
.button__arrow {
  position: relative;
  overflow: hidden;
  padding-right: calc(1.125rem + 70px);
}
.button__arrow:before {
  content: "";
  background: transparent url(/resources/motif/images/button__arrow-icon.svg) 0 0 no-repeat;
  width: 38px;
  height: 12px;
  position: absolute;
  right: 10px;
  top: 50%;
  z-index: 3;
  transform: translateY(-50%);
}
.button__arrow:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -10px;
  width: 70px;
  background-color: #0092C8;
  transform: skewX(-15deg);
}
.button__arrow[disabled]:before {
  opacity: 0.35;
}
.button__arrow[disabled]:after {
  background-color: rgba(0, 146, 200, 0.35);
}
.button__email {
  position: relative;
  overflow: hidden;
  padding-right: calc(1.125rem + 70px);
}
.button__email:before {
  content: "";
  background: transparent url(/resources/motif/images/mail-icon.svg) 0 0 no-repeat;
  width: 38px;
  height: 25px;
  position: absolute;
  right: 4px;
  top: 50%;
  z-index: 3;
  transform: translateY(-50%);
}
.button__email:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -10px;
  width: 70px;
  background-color: #0092C8;
  transform: skewX(-15deg);
}
.button__download {
  position: relative;
  overflow: hidden;
  padding-right: calc(1.125rem + 70px);
}
.button__download:before {
  content: "";
  background: transparent url(/resources/motif/images/download-icon.svg) 0 0 no-repeat;
  width: 24px;
  height: 21px;
  position: absolute;
  right: 17px;
  top: 50%;
  z-index: 3;
  transform: translateY(-50%);
}
.button__download:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -10px;
  width: 70px;
  background-color: #0092C8;
  transform: skewX(-15deg);
}
.button__search {
  position: relative;
  overflow: hidden;
  padding-right: calc(1.125rem + 40px);
}
.button__search:before {
  content: "";
  background: transparent url(/resources/motif/images/search-icon.svg) 0 0 no-repeat;
  width: 24px;
  height: 21px;
  position: absolute;
  right: 17px;
  top: 50%;
  z-index: 3;
  transform: translateY(-50%);
}
.button__search:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -10px;
  width: 70px;
  background-color: #0092C8;
  transform: skewX(-15deg);
}
/*

````tiny-button
<a class="button__tiny" href="#">Tiny Button</a>
<a class="button__tiny button__primary" href="#">Tiny Button Primary</a>
<a class="button__tiny button__danger" href="#">Tiny Button Danger</a>
````

*/
/*

````small-button
<a class="button__small" href="#">Small Button</a>
<a class="button__small button__primary" href="#">Small Button Primary</a>
<a class="button__small button__danger" href="#">Small Button Danger</a>
````

*/
/*

````large-button
<a class="button__large" href="#">Large Button</a>
<a class="button__large button__primary" href="#">Large Button Primary</a>
<a class="button__large button__danger" href="#">Large Button Danger</a>
````

*/
.button__tiny {
  font-size: 16px;
  font-size: 1rem;
  padding: 0.3125rem 0.5625rem;
}
.button__small {
  font-size: 16px;
  font-size: 1rem;
}
.button__large {
  font-size: 60px;
  font-size: 3.75rem;
}
.button__full-width {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}
/*

Link Styles

Very basic stuff. The primary link color can be altered in the `config.less` 
file. Hover, focus, and current states are accounted for.

*/
a:focus,
a:active,
a:hover {
  outline: 0;
}
a,
.links__link,
.lists__horizontal--pgs a[href] {
  color: #fff;
}
a:hover,
.links__link:hover,
a:focus,
.links__link:focus,
a.is-current,
.links__link.is-current,
.lists__horizontal--pgs a[href]:hover,
.lists__horizontal--pgs a[href]:focus,
.lists__horizontal--pgs a[href].is-current {
  color: #0092C8;
}
/*

Unlink

Basic class to remove some link styling.

@TODO: Put in `presentational.less` file?

*/
.links__unlink {
  text-decoration: none;
}
.links__unbutton {
  border: none;
  background-color: transparent;
}
/*

Skip-To Link

*/
.links__skip-to {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
}
.links__skip-to:not(:focus) {
  width: 1px;
  height: 1px;
  padding: 0;
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  border: 0;
  overflow: hidden;
}
.links__skip-to:not(:focus).focusable:active,
.links__skip-to:not(:focus).focusable:focus {
  width: auto;
  height: auto;
  margin: 0;
  clip: auto;
  overflow: visible;
  position: static;
}
body:hover .links__skip-to:not(:focus) a,
body:hover .links__skip-to:not(:focus) input,
body:hover .links__skip-to:not(:focus) button {
  display: none;
}
/*

Forms

*/
form {
  margin: 0;
}
fieldset {
  border: none;
  margin: 0;
  padding: 0;
}
legend {
  width: 100%;
  border: 0;
  padding: 0;
  display: block;
  white-space: normal;
}
/*

Basic Form Elements

Inputs and textareas are set to `width: 100%` to adapt to the width of their 
wrapper. (Great for grids.) Select boxes are set to the width of their content,
maxing out at `100%`.

Almost all the form elements in Motif are best used inside a `label`.

    <label>
        Text Field
        <input type="text" name="text" required>
    </label>

*/
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  display: block;
}
input,
textarea {
  width: 100%;
  font-size: 1em;
  padding: 0.3125rem 0.25rem;
  outline: none;
}
input:hover,
textarea:hover,
input:focus,
textarea:focus,
input:active,
textarea:active,
input.is-current,
textarea.is-current {
  z-index: 1;
}
input {
  line-height: normal;
}
input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  display: inline-block;
  cursor: pointer;
}
input[disabled] {
  cursor: default;
}
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input[type="checkbox"],
input[type="radio"] {
  margin: 3px;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  vertical-align: top;
  width: auto;
}
input[type="search"] {
  -webkit-appearance: textfield;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
textarea {
  overflow: auto;
  vertical-align: top;
  height: 5rem;
}
select {
  width: auto;
  max-width: 100%;
  font-size: 0.8125em;
}
/*

Input Label Elements

Child form elements of the `.elements__input-label` class are given a little bit of 
spacing to separate them from the label text. (See also `.elements__input-label` in the
form `type.less` file.)

````input-label-elements
<label class="elements__input-label">
    Text Field
    <input type="text" name="text" placeholder="Placeholder text" required>
</label>
<label class="elements__input-label">
    Textarea
    <textarea placeholder="Placeholder text"></textarea>
</label>
<label class="elements__input-label">
    Select
    <select>
        <option>Placeholder text</option>
    </select>
</label>
````

*/
.elements__input-label input,
.elements__input-label textarea,
.elements__input-label select {
  margin-top: 0.5555555555555556em;
  margin-bottom: 0.5555555555555556em;
}
/*

Input Widths

While you are encouraged to use the responsive grid system in conjunction with 
your form styles, sometimes it's useful to have form field widths that are 
simply consistent across viewports. These are those.

    <label class="elements__input-label">
        Text Field
        <input class="elements__input--xs" type="text" name="text" required>
    </label>

*/
.elements__input--eighth {
  width: 12.5%;
}
.elements__input--quarter {
  width: 25%;
}
.elements__input--half {
  width: 50%;
}
.elements__input--xxxs {
  max-width: 30px;
}
.elements__input--xxs {
  max-width: 50px;
}
.elements__input--xs {
  max-width: 100px;
}
.elements__input--s {
  max-width: 175px;
}
.elements__input--m {
  max-width: 250px;
}
.elements__input--l {
  max-width: 350px;
}
/*

Form Modules

````attached-label-before
<label class="elements__attached-label">
    <small class="label">$</small>
    <input type="num" id="attached-label-before" required>
</label>
````

````attached-label-after
<label class="elements__attached-label">
    <input type="text" id="attached-label-after" required>
    <small class="label">.com</small>
</label>
````

````attached-label-before-and-after
<label class="elements__attached-label">
    <small class="label">www.</small>
    <input type="text" id="attached-label" placeholder="domain" required>
    <small class="label">.com</small>
</label>
````

````attached-label-submit
<form class="elements__attached-label">
    <label class="is-hidden" for="attached-label-submit">Search</label>
    <input type="search" id="attached-label-submit" required>
    <div>
        <button type="submit" class="button__primary">
            <svg class="icon"><use xlink:href="#search"></svg>
            <b class="is-hidden">Submit</b>
        </button>
    </div>
</form>
````

*/
.elements__attached-label {
  display: table;
  overflow: hidden;
}
.elements__attached-label > * {
  margin-top: 0;
  display: table-cell;
  vertical-align: middle;
}
.elements__attached-label > * > :first-child {
  margin-top: 0;
}
.elements__attached-label [class*="button"] {
  display: block;
  width: 100%;
}
/*

Input Label

The `.input-label` class should be added to form labels that want a little 
extra styling for the label text.

(You may need to do some fancy things with hidden or altered labels, which is
why these styles aren't added to the `label` element by default.)

    <label class="elements__input-label">
        Text Field
        <input type="text" name="text" required>
    </label>

There are also input descriptions, which go directly underneath the label text,
and input hints that typically go underneath the input field.

    <label class="elements__input-label">
        Text Field
        
        <small class="elements__input-desc">
            This is a small description of the field.
        </small>
        
        <input type="text" name="text" required>
        
        <strong class="elements__input-hint">
            This is an important hint.
        </strong>
    </label>

*/
.elements__input-label {
  padding-top: 1.1111111111111112em;
}
.elements__input-hint {
  color: #a9a6a7;
  margin-top: 0.2777777777777778em;
}
.elements__input-desc {
  margin-top: 0em;
}
/*

Fieldset

A simple class to add to fieldsets that want a little more spacing.

*/
.elements__fieldset {
  margin-top: 1.1111111111111112em;
  padding-bottom: 1.1111111111111112em;
}
/*

Checkbox List

Lists of radio or checkbox inputs should typically be just that: lists. In 
Motif, you're encouraged to mark up your input lists as semantically as 
possible, meaning a fieldset, with a legend, and a list of inputs:

    <fieldset>
        <legend>
            <b class="elements__input-label">This is a question for a radio list?</b>
        </legend>

        <ul class="elements__checkbox-list">
            <li>
                <label class="media__media-object">
                    <input class="media__media-object__figure"
                     type="radio"
                     name="radioList"
                     value="Yes">

                    <b class="media__media-object__desc">
                        Yes
                    </b>
                </label>
            </li>
            <li>
                <label class="media__media-object">
                    <input class="media__media-object__figure"
                     type="radio"
                     name="radioList"
                     value="No">

                    <b class="media__media-object__desc">
                        No
                    </b>
                </label>
            </li>
        </ul>
    </fieldset>

*/
.elements__checkbox-list {
  margin-top: 0.5555555555555556em;
}
legend + .elements__checkbox-list {
  margin-top: 0em;
}
legend .elements__input-label {
  padding-bottom: 0.2777777777777778em;
}
legend + .elements__input-desc {
  /* Positioning */
  position: relative;
  top: -0.3125rem;
}
/*

Form Validation

*/
/*

Input States

A couple of classes added to inputs (typically by a JS Validation plugin) that
alter the child elements to convey states of success and error.

    <label class="elements__input-label messaging__is-erroneous">
        Text Field
        <input type="text" name="text" required>
    </label>

*/
.messaging__is-successful input,
.messaging__is-successful textarea,
.messaging__is-successful select {
  border-color: #72bc44;
}
.messaging__is-erroneous input,
.messaging__is-erroneous textarea,
.messaging__is-erroneous select {
  border-color: #F7951B;
}
/*

Form Messaging

Within labels, there are input alerts that apply to individual fields.

    <label class="elements__input-label messaging__is-erroneous">
        Text Field
        <input type="text" name="text" required>
        <strong class="messaing__input-alert--error">
            This field is required.
        </strong>
    </label>

*/
[class*="messaging__input-alert"] {
  /* Display & Box Model */
  display: inline-block;
  /* Pretty */
  color: #013b66;
}
.messaging__input-alert--warning {
  color: #F7941F;
}
.messaging__input-alert--error {
  color: #F7951B;
}
.messaging__input-alert--success {
  color: #72bc44;
}
/*

Spacing Helpers

Spacing helpers are quick but powerful classes that add margin or padding to
any given element. These classes are `!important`, so they override all other
styles.

* `p`, `m`                          =   padding, margin
* `a`, `t`, `r`, `b`, `l`, `h`, `v` =   all, top, right, bottom, left, horizontal, vertical
* `s`, `m`, `l`, `x`, `n`                =   small (~`5px`), medium (~`10px`), large (~`20px`), x-large (~`40px`) none (`0`)

For example: `.spacing__pam` means "padding, all, medium".

**Note:** These are all based on the current element's font size and the
project's vertical rhythm (even on the sides, to provide uniform spacing).
As such, they require `vars.less`.

*/
.spacing__ptn,
.spacing__pvn,
.spacing__pan {
  padding-top: 0 !important;
}
.spacing__pts,
.spacing__pvs,
.spacing__pas {
  padding-top: 0.3125rem !important;
}
.spacing__ptm,
.spacing__pvm,
.spacing__pam {
  padding-top: 0.625rem !important;
}
.spacing__ptl,
.spacing__pvl,
.spacing__pal {
  padding-top: 1.25rem !important;
}
.spacing__ptx,
.spacing__pvx,
.spacing__pax {
  padding-top: 2.5rem !important;
}
.spacing__prn,
.spacing__phn,
.spacing__pan {
  padding-right: 0 !important;
}
.spacing__prs,
.spacing__phs,
.spacing__pas {
  padding-right: 0.3125rem !important;
}
.spacing__prm,
.spacing__phm,
.spacing__pam {
  padding-right: 0.625rem !important;
}
.spacing__prl,
.spacing__phl,
.spacing__pal {
  padding-right: 1.25rem !important;
}
.spacing__prx,
.spacing__phx,
.spacing__pax {
  padding-right: 2.5rem !important;
}
.spacing__pbn,
.spacing__pvn,
.spacing__pan {
  padding-bottom: 0 !important;
}
.spacing__pbs,
.spacing__pvs,
.spacing__pas {
  padding-bottom: 0.3125rem !important;
}
.spacing__pbm,
.spacing__pvm,
.spacing__pam {
  padding-bottom: 0.625rem !important;
}
.spacing__pbl,
.spacing__pvl,
.spacing__pal {
  padding-bottom: 1.25rem !important;
}
.spacing__pbx,
.spacing__pvx,
.spacing__pax {
  padding-bottom: 2.5rem !important;
}
.spacing__pln,
.spacing__phn,
.spacing__pan {
  padding-left: 0 !important;
}
.spacing__pls,
.spacing__phs,
.spacing__pas {
  padding-left: 0.3125rem !important;
}
.spacing__plm,
.spacing__phm,
.spacing__pam {
  padding-left: 0.625rem !important;
}
.spacing__pll,
.spacing__phl,
.spacing__pal {
  padding-left: 1.25rem !important;
}
.spacing__plx,
.spacing__phx,
.spacing__pax {
  padding-left: 2.5rem !important;
}
.spacing__mtn,
.spacing__mvn,
.spacing__man,
.cta__desc > span,
.banner__desc > span {
  margin-top: 0 !important;
}
.spacing__mts,
.spacing__mvs,
.spacing__mas {
  margin-top: 0.3125rem !important;
}
.spacing__mtm,
.spacing__mvm,
.spacing__mam {
  margin-top: 0.625rem !important;
}
.spacing__mtl,
.spacing__mvl,
.spacing__mal {
  margin-top: 1.25rem !important;
}
.spacing__mtx,
.spacing__mvx,
.spacing__max {
  margin-top: 2.5rem !important;
}
.spacing__mrn,
.spacing__mhn,
.spacing__man {
  margin-right: 0 !important;
}
.spacing__mrs,
.spacing__mhs,
.spacing__mas {
  margin-right: 0.3125rem !important;
}
.spacing__mrm,
.spacing__mhm,
.spacing__mam {
  margin-right: 0.625rem !important;
}
.spacing__mrl,
.spacing__mhl,
.spacing__mal {
  margin-right: 1.25rem !important;
}
.spacing__mrx,
.spacing__mhx,
.spacing__max {
  margin-right: 2.5rem !important;
}
.spacing__mbn,
.spacing__mvn,
.spacing__man {
  margin-bottom: 0 !important;
}
.spacing__mbs,
.spacing__mvs,
.spacing__mas {
  margin-bottom: 0.3125rem !important;
}
.spacing__mbm,
.spacing__mvm,
.spacing__mam {
  margin-bottom: 0.625rem !important;
}
.spacing__mbl,
.spacing__mvl,
.spacing__mal {
  margin-bottom: 1.25rem !important;
}
.spacing__mbx,
.spacing__mvx,
.spacing__max {
  margin-bottom: 2.5rem !important;
}
.spacing__mln,
.spacing__mhn,
.spacing__man {
  margin-left: 0 !important;
}
.spacing__mls,
.spacing__mhs,
.spacing__mas {
  margin-left: 0.3125rem !important;
}
.spacing__mlm,
.spacing__mhm,
.spacing__mam {
  margin-left: 0.625rem !important;
}
.spacing__mll,
.spacing__mhl,
.spacing__mal {
  margin-left: 1.25rem !important;
}
.spacing__mlx,
.spacing__mhx,
.spacing__max {
  margin-left: 2.5rem !important;
}
/*

Text Align

Simple classes to adjust your text alignment. They can be added to individual elements or on wrapping elements (an outer `div`, perhaps) to adjust the alignment of all the child elements.

*/
.presentational__align-left {
  text-align: left;
}
.presentational__align-center {
  text-align: center;
}
.presentational__align-right {
  text-align: right;
}
.presentational__align-sub {
  vertical-align: sub;
}
.presentational__align-middle {
  vertical-align: middle;
}
.presentational__align-top {
  vertical-align: top;
}
/*

Floats

Next, we have classes for floating elements.

*/
.presentational__float-left {
  float: left;
}
.presentational__float-center {
  margin-left: auto;
  margin-right: auto;
}
.presentational__float-right {
  float: right;
}
.presentational__float-none {
  float: none;
}
.presentational__clear {
  clear: both;
}
/*

"New Line"

Turns an `inline` or `inline-block` element into a `block` one.

*/
.presentational__new-line,
.ratios__base:before,
.media__figure > a,
[class*="media__figure--"] > a,
.media__figure img,
[class*="media__figure--"] img,
.elements__input-label,
.elements__input-hint,
.elements__input-desc {
  display: block;
}
.presentational__group,
.lists__breadcrumb {
  /* Mixins */
}
.presentational__group:before,
.presentational__group:after {
  content: "\0020";
  height: 0;
  display: block;
  overflow: hidden;
}
.presentational__group:after {
  clear: both;
}
/*

"Is Hidden"

Visually hides content in a way that leaves it accessible to screen readers.
Like more magic, makes the visible invisible.

*/
.presentational__is-hidden,
.js .reveal__target {
  width: 1px;
  height: 1px;
  padding: 0;
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  border: 0;
  overflow: hidden;
}
.presentational__is-hidden.focusable:active,
.presentational__is-hidden.focusable:focus {
  width: auto;
  height: auto;
  margin: 0;
  clip: auto;
  overflow: visible;
  position: static;
}
body:hover .presentational__is-hidden a,
body:hover .presentational__is-hidden input,
body:hover .presentational__is-hidden button {
  display: none;
}
@media all and (max-width: 47.9375em) {
  .presentational__is-hidden-small {
    width: 1px;
    height: 1px;
    padding: 0;
    position: absolute;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    border: 0;
    overflow: hidden;
  }
  .presentational__is-hidden-small.focusable:active,
  .presentational__is-hidden-small.focusable:focus {
    width: auto;
    height: auto;
    margin: 0;
    clip: auto;
    overflow: visible;
    position: static;
  }
  body:hover .presentational__is-hidden-small a,
  body:hover .presentational__is-hidden-small input,
  body:hover .presentational__is-hidden-small button {
    display: none;
  }
}
@media all and (min-width: 48em) and (max-width: 61.9375em) {
  .presentational__is-hidden-medium {
    width: 1px;
    height: 1px;
    padding: 0;
    position: absolute;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    border: 0;
    overflow: hidden;
  }
  .presentational__is-hidden-medium.focusable:active,
  .presentational__is-hidden-medium.focusable:focus {
    width: auto;
    height: auto;
    margin: 0;
    clip: auto;
    overflow: visible;
    position: static;
  }
  body:hover .presentational__is-hidden-medium a,
  body:hover .presentational__is-hidden-medium input,
  body:hover .presentational__is-hidden-medium button {
    display: none;
  }
}
@media all and (min-width: 62em) {
  .presentational__is-hidden-large {
    width: 1px;
    height: 1px;
    padding: 0;
    position: absolute;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    border: 0;
    overflow: hidden;
  }
  .presentational__is-hidden-large.focusable:active,
  .presentational__is-hidden-large.focusable:focus {
    width: auto;
    height: auto;
    margin: 0;
    clip: auto;
    overflow: visible;
    position: static;
  }
  body:hover .presentational__is-hidden-large a,
  body:hover .presentational__is-hidden-large input,
  body:hover .presentational__is-hidden-large button {
    display: none;
  }
}
/*

"Isn't Hidden"

Undoes `.is-hidden`.

*/
.presentational__isnt-hidden,
.js .reveal__target.is-revealed {
  /* Mixins */
  width: auto;
  height: auto;
  margin: 0;
  position: relative;
  clip: auto;
}
body:hover .presentational__isnt-hidden a,
body:hover .presentational__isnt-hidden input,
body:hover .presentational__isnt-hidden button {
  display: inline-block;
}
.lte7 body:hover .presentational__isnt-hidden a,
.lte7 body:hover .presentational__isnt-hidden input,
.lte7 body:hover .presentational__isnt-hidden button {
  display: inline;
  zoom: 1;
}
/*

"Relative Container"

Exactly what it sounds like.

*/
.presentational__relative-container,
.ratios__base,
[class*="messaging__input-alert"],
.js .expand__target,
.ratios__video,
.ratios__1x1,
.ratios__square,
.ratios__2x1,
.ratios__1x2 {
  position: relative;
}
/*

"No Scroll"

Paired with "motif.utitlies.js" to make the page non-scrollable

*/
html.presentational__no-scroll,
body.presentational__no-scroll {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: fixed;
}
/*

Responsive Grid

The default responsive grid in Motif is built with a few rules in mind:

1. Each row of columns must be wrapped in a row element
2. There's no need to specify if a column is first or last

With that in mind, there is a basic syntax to learn for what classes to use.
Our breakpoints are classified as:

- Base
- Small
- Medium
- Large
- Extra Large

Our default responsive grid system only uses 3 grids to cover all of our bases:

- `grid, small` covers Base and Small viewports, but cuts off at Medium
- `grid, medium` covers only Medium
- `grid, large` starts at Large and covers Extra Large as well (there is no max
cutoff value for `grid, large`)

Understanding that, the syntax should be easy to understand. In these examples,
we will talk about `grid, small`:

- **Rows**: `.grid__sm-row` (Grid, Small, Row)
- **Columns**: `.grid__sm` (Grid, Column, Small)
- **Column Width**: `.grid__sm-half` (Grid, Small, Half-Width)

    <div class="grid__sm-row">
        <div class="grid__sm-half">...</div>
        <div class="grid__sm-half">...</div>
    </div>

However, to be slightly more succinct, you can use the condensed column syntax:

- **Columns (Condensed)**: `.grid__sm-half` (Grid, Column, Small, Half-Width)

    <div class="grid__sm-row">
        <div class="grid__sm-half">...</div>
        <div class="grid__sm-half">...</div>
    </div>

In brief, the way the grid works is that `.grid__sm` floats the element to the left
(by default), and adds padding to both the left and the right. `-half` gives
it a width of `50%`. The combined padding of the two columns bumped up against 
each other form the full "gutter". The `.grid__sm-row` then clears the floats and has
a *negative* margin on both the left and the right that pops the columns out to
the sides the exact width of their paddings, meaning the column content lines 
up with the site boundaries on the left and right without the need to specify 
"first" or "last" column classes.

To use this responsively, then, you just add the classes by breakpoint:

    <div class="grid__sm-row grid__med-row grid__lg-row">
        <div class="grid__sm-half grid__med-third grid__lg-quarter">
            ...
        </div>
        <div class="grid__sm-half grid__med-two-thirds grid__lg-three-quarters">
            ...
        </div>
    </div>

By default, the column breakdown of each grid breakpoint:

- `grid, small`: 4 columns
- `grid, medium`: 9 columns
- `grid, large`: 12 columns

For the larger grids, the classes follow the numbers closely...

    - `.grid__lg-10of12
    - `.grid__med-4of9`

...in just about every combination (based on their total column number), but 
all of the grids also have a few "fuzzy" shortcuts as well:

    - `half`
    - `third`
    - `quarter`
    - `three-quarters`
    - `two-thirds`

*/
.grid__halves,
.grid__thirds,
.grid__quarters {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
  /* Pretty */
  letter-spacing: -0.31em;
  text-rendering: optimizespeed;
}
.grid__halves:before,
.grid__thirds:before,
.grid__quarters:before,
.grid__halves:after,
.grid__thirds:after,
.grid__quarters:after {
  content: "\0020";
  height: 0;
  display: block;
  overflow: hidden;
}
.grid__halves:after,
.grid__thirds:after,
.grid__quarters:after {
  clear: both;
}
ul.grid__halves,
ul.grid__thirds,
ul.grid__quarters {
  padding-left: 0;
  list-style: none outside none;
}
.grid__halves > *,
.grid__thirds > *,
.grid__quarters > * {
  display: inline;
  float: left;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  /* Display & Box Model */
  display: inline-block;
  /* Positioning */
  float: none;
  /* Pretty */
  letter-spacing: normal;
  word-spacing: normal;
  vertical-align: top;
  text-rendering: auto;
}
.grid__halves > * {
  width: 50%;
}
.grid__thirds > * {
  width: 33.33333%;
}
.grid__quarters > * {
  width: 25%;
}
.opera-only :-o-prefocus,
.grid__thirds {
  word-spacing: -0.43em;
}
/**
 * Mobile Grid
 */
@media all and (max-width: 47.9375em) {
  [class*="grid__sm-row"],
  .grid__sm-quarters,
  .grid__sm-thirds,
  .grid__sm-halves {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  [class*="grid__sm-row"]:before,
  [class*="grid__sm-row"]:after,
  .grid__sm-quarters:before,
  .grid__sm-quarters:after,
  .grid__sm-thirds:before,
  .grid__sm-thirds:after,
  .grid__sm-halves:before,
  .grid__sm-halves:after {
    content: "\0020";
    height: 0;
    display: block;
    overflow: hidden;
  }
  [class*="grid__sm-row"]:after,
  .grid__sm-quarters:after,
  .grid__sm-thirds:after,
  .grid__sm-halves:after {
    clear: both;
  }
  ul[class*="grid__sm-row"],
  ul.grid__sm-quarters,
  ul.grid__sm-thirds,
  ul.grid__sm-halves {
    padding-left: 0;
    list-style: none outside none;
  }
  .grid__sm-row--rtl > [class*="grid__sm"] {
    float: right;
  }
  .grid__sm-row--ltr > [class*="grid__sm"] {
    float: left;
  }
  [class*="grid__sm"]:not([class*="grid__sm-row"]) {
    display: inline;
    float: left;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .grid__sm-quarter,
  .grid__sm-quarters > * {
    width: 25%;
  }
  .grid__sm-third,
  .grid__sm-thirds > * {
    width: 33.33333%;
  }
  .grid__sm-half,
  .grid__sm-halves > * {
    width: 50%;
  }
  .grid__sm-two-thirds {
    width: 66.66667%;
  }
  .grid__sm-three-quarters {
    width: 75%;
  }
  .grid__sm-full {
    width: 100%;
  }
  .grid__sm-row--flex,
  .grid__sm-quarters,
  .grid__sm-thirds,
  .grid__sm-halves {
    letter-spacing: -0.31em;
    text-rendering: optimizespeed;
  }
  .grid__sm-row--flex > [class*="grid__sm"],
  .grid__sm-quarters > *,
  .grid__sm-thirds > *,
  .grid__sm-halves > * {
    float: none;
    display: inline-block;
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    text-rendering: auto;
  }
  .opera-only :-o-prefocus,
  .grid__sm-row--flex,
  .grid__sm-quarters,
  .grid__sm-thirds,
  .grid__sm-halves {
    word-spacing: -0.43em;
  }
}
/**
 * Tablet Grid
 */
@media all and (min-width: 48em) and (max-width: 61.9375em) {
  [class*="grid__med-row"],
  .grid__med-quarters,
  .grid__med-thirds,
  .grid__med-halves {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  [class*="grid__med-row"]:before,
  [class*="grid__med-row"]:after,
  .grid__med-quarters:before,
  .grid__med-quarters:after,
  .grid__med-thirds:before,
  .grid__med-thirds:after,
  .grid__med-halves:before,
  .grid__med-halves:after {
    content: "\0020";
    height: 0;
    display: block;
    overflow: hidden;
  }
  [class*="grid__med-row"]:after,
  .grid__med-quarters:after,
  .grid__med-thirds:after,
  .grid__med-halves:after {
    clear: both;
  }
  ul[class*="grid__med-row"],
  ul.grid__med-quarters,
  ul.grid__med-thirds,
  ul.grid__med-halves {
    padding-left: 0;
    list-style: none outside none;
  }
  .grid__med-row--rtl > [class*="grid__med"] {
    float: right;
  }
  .grid__med-row--ltr > [class*="grid__med"] {
    float: left;
  }
  [class*="grid__med"]:not([class*="grid__med-row"]) {
    display: inline;
    float: left;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .grid__med-quarter,
  [class*="grid__med-2of8"],
  .grid__med-quarters > * {
    width: 25%;
  }
  .grid__med-third,
  [class*="grid__med-3of9"],
  [class*="grid__med-2of6"],
  .grid__med-thirds > * {
    width: 33.33333%;
  }
  .grid__med-half,
  [class*="grid__med-4of8"],
  [class*="grid__med-3of6"],
  .grid__med-halves > * {
    width: 50%;
  }
  .grid__med-two-thirds,
  [class*="grid__med-6of9"],
  [class*="grid__med-4of6"] {
    width: 66.66667%;
  }
  .grid__med-three-quarters,
  [class*="grid__med-6of8"] {
    width: 75%;
  }
  .grid__med-full {
    width: 100%;
  }
  [class*="grid__med-8of9"] {
    width: 88.88889%;
  }
  [class*="grid__med-7of9"] {
    width: 77.77778%;
  }
  [class*="grid__med-5of9"] {
    width: 55.55556%;
  }
  [class*="grid__med-4of9"] {
    width: 44.44444%;
  }
  [class*="grid__med-2of9"] {
    width: 22.22222%;
  }
  [class*="grid__med-1of9"] {
    width: 11.11111%;
  }
  [class*="grid__med-7of8"] {
    width: 87.5%;
  }
  [class*="grid__med-5of8"] {
    width: 62.5%;
  }
  [class*="grid__med-3of8"] {
    width: 37.5%;
  }
  [class*="grid__med-1of8"] {
    width: 12.5%;
  }
  [class*="grid__med-6of7"] {
    width: 85.71429%;
  }
  [class*="grid__med-5of7"] {
    width: 71.42857%;
  }
  [class*="grid__med-4of7"] {
    width: 57.14286%;
  }
  [class*="grid__med-3of7"] {
    width: 42.85714%;
  }
  [class*="grid__med-2of7"] {
    width: 28.57143%;
  }
  [class*="grid__med-1of7"] {
    width: 14.28571%;
  }
  [class*="grid__med-5of6"] {
    width: 83.33333%;
  }
  [class*="grid__med-1of6"] {
    width: 16.66667%;
  }
  [class*="grid__med-4of5"] {
    width: 80%;
  }
  [class*="grid__med-3of5"] {
    width: 60%;
  }
  [class*="grid__med-2of5"] {
    width: 40%;
  }
  [class*="grid__med-1of5"] {
    width: 20%;
  }
  .grid__med-row--flex,
  .grid__med-quarters,
  .grid__med-thirds,
  .grid__med-halves {
    letter-spacing: -0.31em;
    text-rendering: optimizespeed;
  }
  .grid__med-row--flex > [class*="grid__med"],
  .grid__med-quarters > *,
  .grid__med-thirds > *,
  .grid__med-halves > * {
    float: none;
    display: inline-block;
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    text-rendering: auto;
  }
  .opera-only :-o-prefocus,
  .grid__med-row--flex,
  .grid__med-quarters,
  .grid__med-thirds,
  .grid__med-halves {
    word-spacing: -0.43em;
  }
}
/**
 * Desktop Grid
 */
/*

````desktop-grid
<div class="grid__lg-quarters">
    <div>
        <div class="block">Quarter</div>
    </div>
    <div>
        <div class="block">Quarter</div>
    </div>
    <div>
        <div class="block">Quarter</div>
    </div>
    <div>
        <div class="block">Quarter</div>
    </div>
</div>
<div class="grid__lg-thirds">
    <div>
        <div class="block">Third</div>
    </div>
    <div>
        <div class="block">Third</div>
    </div>
    <div>
        <div class="block">Third</div>
    </div>
</div>
<div class="grid__lg-halves">
    <div>
        <div class="block">Half</div>
    </div>
    <div>
        <div class="block">Half</div>
    </div>
</div>
<div class="grid__lg-row">
    <div class="grid__lg-two-thirds">
        <div class="block">Two Thirds</div>
    </div>
    <div class="grid__lg-third">
        <div class="block">Third</div>
    </div>
</div>
<div class="grid__lg-row">
    <div class="grid__lg-three-quarters">
        <div class="block">Three Quarters</div>
    </div>
    <div class="grid__lg-quarter">
        <div class="block">Quarter</div>
    </div>
</div>
````

*/
@media all and (min-width: 62em) {
  [class*="grid__lg-row"],
  .grid__lg-quarters,
  .grid__lg-thirds,
  .grid__lg-halves {
    margin-left: -1.125rem;
    margin-right: -1.125rem;
  }
  [class*="grid__lg-row"]:before,
  [class*="grid__lg-row"]:after,
  .grid__lg-quarters:before,
  .grid__lg-quarters:after,
  .grid__lg-thirds:before,
  .grid__lg-thirds:after,
  .grid__lg-halves:before,
  .grid__lg-halves:after {
    content: "\0020";
    height: 0;
    display: block;
    overflow: hidden;
  }
  [class*="grid__lg-row"]:after,
  .grid__lg-quarters:after,
  .grid__lg-thirds:after,
  .grid__lg-halves:after {
    clear: both;
  }
  ul[class*="grid__lg-row"],
  ul.grid__lg-quarters,
  ul.grid__lg-thirds,
  ul.grid__lg-halves {
    padding-left: 0;
    list-style: none outside none;
  }
  [class*="grid__lg"]:not([class*="grid__lg-row"]) {
    display: inline;
    float: left;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
  }
  .grid__lg-row--rtl > [class*="grid__lg"] {
    float: right;
  }
  .grid__lg-row--ltr > [class*="grid__lg"] {
    float: left;
  }
  .grid__lg-quarter,
  .grid__lg-quarter,
  [class*="grid__lg-3of12"],
  [class*="grid__lg-3of12"],
  [class*="grid__lg-2of8"],
  [class*="grid__lg-2of8"],
  .grid__lg-quarters > *,
  .grid__lg-quarters > * {
    width: 25%;
  }
  .grid__lg-third,
  .grid__lg-third,
  [class*="grid__lg-4of12"],
  [class*="grid__lg-4of12"],
  [class*="grid__lg-3of9"],
  [class*="grid__lg-3of9"],
  [class*="grid__lg-2of6"],
  [class*="grid__lg-2of6"],
  .grid__lg-thirds > *,
  .grid__lg-thirds > * {
    width: 33.33333%;
  }
  .grid__lg-half,
  .grid__lg-half,
  [class*="grid__lg-6of12"],
  [class*="grid__lg-6of12"],
  [class*="grid__lg-5of10"],
  [class*="grid__lg-5of10"],
  [class*="grid__lg-4of8"],
  [class*="grid__lg-4of8"],
  [class*="grid__lg-3of6"],
  [class*="grid__lg-3of6"],
  .grid__lg-halves > *,
  .grid__lg-halves > * {
    width: 50%;
  }
  .grid__lg-two-thirds,
  .grid__lg-two-thirds,
  [class*="grid__lg-8of12"],
  [class*="grid__lg-8of12"],
  [class*="grid__lg-6of9"],
  [class*="grid__lg-6of9"],
  [class*="grid__lg-4of6"],
  [class*="grid__lg-4of6"] {
    width: 66.66667%;
  }
  .grid__lg-three-quarters,
  .grid__lg-three-quarters,
  [class*="grid__lg-9of12"],
  [class*="grid__lg-9of12"],
  [class*="grid__lg-6of8"],
  [class*="grid__lg-6of8"] {
    width: 75%;
  }
  .grid__lg-full,
  .grid__lg-full {
    width: 100%;
  }
  [class*="grid__lg-11of12"] {
    width: 91.66667%;
  }
  [class*="grid__lg-10of12"] {
    width: 83.33333%;
  }
  [class*="grid__lg-7of12"] {
    width: 58.33333%;
  }
  [class*="grid__lg-5of12"] {
    width: 41.66667%;
  }
  [class*="grid__lg-2of12"] {
    width: 16.66667%;
  }
  [class*="grid__lg-1of12"] {
    width: 8.33333%;
  }
  [class*="grid__lg-10of11"] {
    width: 90.90909%;
  }
  [class*="grid__lg-9of11"] {
    width: 81.81818%;
  }
  [class*="grid__lg-8of11"] {
    width: 72.72727%;
  }
  [class*="grid__lg-7of11"] {
    width: 63.63636%;
  }
  [class*="grid__lg-6of11"] {
    width: 54.54545%;
  }
  [class*="grid__lg-5of11"] {
    width: 45.45455%;
  }
  [class*="grid__lg-4of11"] {
    width: 36.36364%;
  }
  [class*="grid__lg-3of11"] {
    width: 27.27273%;
  }
  [class*="grid__lg-2of11"] {
    width: 18.18182%;
  }
  [class*="grid__lg-1of11"] {
    width: 9.09091%;
  }
  [class*="grid__lg-9of10"] {
    width: 90%;
  }
  [class*="grid__lg-8of10"] {
    width: 80%;
  }
  [class*="grid__lg-7of10"] {
    width: 70%;
  }
  [class*="grid__lg-6of10"] {
    width: 60%;
  }
  [class*="grid__lg-4of10"] {
    width: 40%;
  }
  [class*="grid__lg-3of10"] {
    width: 30%;
  }
  [class*="grid__lg-2of10"] {
    width: 20%;
  }
  [class*="grid__lg-1of10"] {
    width: 10%;
  }
  [class*="grid__lg-8of9"] {
    width: 88.88889%;
  }
  [class*="grid__lg-7of9"] {
    width: 77.77778%;
  }
  [class*="grid__lg-5of9"] {
    width: 55.55556%;
  }
  [class*="grid__lg-4of9"] {
    width: 44.44444%;
  }
  [class*="grid__lg-2of9"] {
    width: 22.22222%;
  }
  [class*="grid__lg-1of9"] {
    width: 11.11111%;
  }
  [class*="grid__lg-7of8"] {
    width: 87.5%;
  }
  [class*="grid__lg-5of8"] {
    width: 62.5%;
  }
  [class*="grid__lg-3of8"] {
    width: 37.5%;
  }
  [class*="grid__lg-1of8"] {
    width: 12.5%;
  }
  [class*="grid__lg-6of7"] {
    width: 85.71429%;
  }
  [class*="grid__lg-5of7"] {
    width: 71.42857%;
  }
  [class*="grid__lg-4of7"] {
    width: 57.14286%;
  }
  [class*="grid__lg-3of7"] {
    width: 42.85714%;
  }
  [class*="grid__lg-2of7"] {
    width: 28.57143%;
  }
  [class*="grid__lg-1of7"] {
    width: 14.28571%;
  }
  [class*="grid__lg-5of6"] {
    width: 83.33333%;
  }
  [class*="grid__lg-1of6"] {
    width: 16.66667%;
  }
  [class*="grid__lg-4of5"] {
    width: 80%;
  }
  [class*="grid__lg-3of5"] {
    width: 60%;
  }
  [class*="grid__lg-2of5"] {
    width: 40%;
  }
  [class*="grid__lg-1of5"] {
    width: 20%;
  }
  /*

    Media Grid

    The Media Grid allows you to place a grid of photos or videos (catalog or
    retail products, etc.) without floating them, alleviating the need to concern
    yourself with clearing the floats with rows should an object extend further
    than another. Also, because they are not floating, it allows you to align
    the entire group center, making sure any "orphaned" grid entries are centered.

    The syntax is simply the same as the regular responsive grid system, except
    the row class has a `--flex` modifier.

    ````media-grid
    <ul class="grid__sm-halves grid__med-thirds grid__lg-quarters">
        <li>
            <figure class="figure">
                <img src="http://placehold.it/400">
                <figcaption>Alderaan is peaceful. We have no weapons.</figcaption>
            </figure>
        </li>
        <li>
            <figure class="figure">
                <img src="http://placehold.it/400">
            </figure>
        </li>
        <li>
            <figure class="figure">
                <img src="http://placehold.it/400">
                <figcaption>Alderaan is peaceful. We have no weapons.</figcaption>
            </figure>
        </li>
        <li>
            <figure class="figure">
                <img src="http://placehold.it/400">
            </figure>
        </li>
        <li>
            <figure class="figure">
                <img src="http://placehold.it/400">
                <figcaption>Alderaan is peaceful. We have no weapons.</figcaption>
            </figure>
        </li>
        <li>
            <figure class="figure">
                <img src="http://placehold.it/400">
            </figure>
        </li>
        <li>
            <figure class="figure">
                <img src="http://placehold.it/400">
            </figure>
        </li>
    </ul>
    ````

    */
  .grid__lg-row--flex,
  .grid__lg-quarters,
  .grid__lg-thirds,
  .grid__lg-halves {
    letter-spacing: -0.31em;
    text-rendering: optimizespeed;
  }
  .grid__lg-row--flex > [class*="grid__lg"],
  .grid__lg-quarters > *,
  .grid__lg-thirds > *,
  .grid__lg-halves > * {
    float: none;
    display: inline-block;
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    text-rendering: auto;
  }
  .opera-only :-o-prefocus,
  .grid__lg-row--flex,
  .grid__lg-quarters,
  .grid__lg-thirds,
  .grid__lg-halves {
    word-spacing: -0.43em;
  }
}
/*

Off-Canvas Trigger (Small Screens Only)

The Off-Canvas Trigger is a simple way of pushing a column of content
off-canvas and sliding it on upon trigger. In this case, the `is-active` 
class is placed on the `.off-canvas` wrapping element so that it can 
adjust both child elements.

@TODO: Adjust for IE9?
@TODO: Show better example w/ Reveal plugin

    <div class="off-canvas__shift--left">
        <div class="off-canvas__primary">
            ...
        </div>
        <div class="off-canvas__secondary">
            ...
        </div>
    </div>

*/
.off-canvas__shift-base,
.js .off-canvas__shift--left,
.js .off-canvas__shift--right,
.js .off-canvas__shift--top,
.js .off-canvas__shift--bottom {
  position: relative;
  overflow: hidden;
}
.js .off-canvas__primary-base,
.js .off-canvas__primary {
  position: relative;
  width: 100%;
  z-index: 1;
}
.off-canvas__shift-base.is-active > .off-canvas__primary-base,
.js .off-canvas__shift--left.is-active > .off-canvas__primary-base,
.js .off-canvas__shift--right.is-active > .off-canvas__primary-base,
.js .off-canvas__shift--top.is-active > .off-canvas__primary-base,
.js .off-canvas__shift--bottom.is-active > .off-canvas__primary-base,
.off-canvas__shift-base.is-active > .off-canvas__primary {
  transform: translate3d(0, 0, 0);
  height: 100vh;
  overflow: hidden;
}
.off-canvas__shift-base.is-active > .off-canvas__primary-base,
.off-canvas__shift-base.was-active > .off-canvas__primary-base,
.js .off-canvas__shift--left.is-active > .off-canvas__primary-base,
.js .off-canvas__shift--left.was-active > .off-canvas__primary-base,
.js .off-canvas__shift--right.is-active > .off-canvas__primary-base,
.js .off-canvas__shift--right.was-active > .off-canvas__primary-base,
.js .off-canvas__shift--top.is-active > .off-canvas__primary-base,
.js .off-canvas__shift--top.was-active > .off-canvas__primary-base,
.js .off-canvas__shift--bottom.is-active > .off-canvas__primary-base,
.js .off-canvas__shift--bottom.was-active > .off-canvas__primary-base,
.off-canvas__shift-base.is-active > .off-canvas__primary,
.off-canvas__shift-base.was-active > .off-canvas__primary {
  transition: all 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}
@media all and (min-width: 48em) {
  .off-canvas__shift-base.is-active > .off-canvas__primary-base,
  .off-canvas__shift-base.was-active > .off-canvas__primary-base,
  .js .off-canvas__shift--left.is-active > .off-canvas__primary-base,
  .js .off-canvas__shift--left.was-active > .off-canvas__primary-base,
  .js .off-canvas__shift--right.is-active > .off-canvas__primary-base,
  .js .off-canvas__shift--right.was-active > .off-canvas__primary-base,
  .js .off-canvas__shift--top.is-active > .off-canvas__primary-base,
  .js .off-canvas__shift--top.was-active > .off-canvas__primary-base,
  .js .off-canvas__shift--bottom.is-active > .off-canvas__primary-base,
  .js .off-canvas__shift--bottom.was-active > .off-canvas__primary-base,
  .off-canvas__shift-base.is-active > .off-canvas__primary,
  .off-canvas__shift-base.was-active > .off-canvas__primary {
    transition-duration: 0.25s;
  }
}
.js .off-canvas__secondary-base,
.js .off-canvas__secondary {
  transform: translate3d(0, 0, 0);
  position: relative;
  width: 100%;
  z-index: 2;
}
.off-canvas__shift-base.is-active > .off-canvas__secondary-base,
.off-canvas__shift-base.was-active > .off-canvas__secondary-base,
.js .off-canvas__shift--left.is-active > .off-canvas__secondary-base,
.js .off-canvas__shift--left.was-active > .off-canvas__secondary-base,
.js .off-canvas__shift--right.is-active > .off-canvas__secondary-base,
.js .off-canvas__shift--right.was-active > .off-canvas__secondary-base,
.js .off-canvas__shift--top.is-active > .off-canvas__secondary-base,
.js .off-canvas__shift--top.was-active > .off-canvas__secondary-base,
.js .off-canvas__shift--bottom.is-active > .off-canvas__secondary-base,
.js .off-canvas__shift--bottom.was-active > .off-canvas__secondary-base,
.off-canvas__shift-base.is-active > .off-canvas__secondary,
.off-canvas__shift-base.was-active > .off-canvas__secondary {
  transition: all 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}
@media all and (min-width: 48em) {
  .off-canvas__shift-base.is-active > .off-canvas__secondary-base,
  .off-canvas__shift-base.was-active > .off-canvas__secondary-base,
  .js .off-canvas__shift--left.is-active > .off-canvas__secondary-base,
  .js .off-canvas__shift--left.was-active > .off-canvas__secondary-base,
  .js .off-canvas__shift--right.is-active > .off-canvas__secondary-base,
  .js .off-canvas__shift--right.was-active > .off-canvas__secondary-base,
  .js .off-canvas__shift--top.is-active > .off-canvas__secondary-base,
  .js .off-canvas__shift--top.was-active > .off-canvas__secondary-base,
  .js .off-canvas__shift--bottom.is-active > .off-canvas__secondary-base,
  .js .off-canvas__shift--bottom.was-active > .off-canvas__secondary-base,
  .off-canvas__shift-base.is-active > .off-canvas__secondary,
  .off-canvas__shift-base.was-active > .off-canvas__secondary {
    transition-duration: 0.25s;
  }
}
.js .off-canvas__shift--left.is-active {
  height: 100vh;
  overflow: hidden;
}
.js .off-canvas__shift--right.is-active {
  height: 100vh;
  overflow: hidden;
}
.js .off-canvas__shift--top {
  min-height: 100vh;
}
.js .off-canvas__shift--top.is-active {
  height: 100vh;
  overflow: hidden;
}
.js .off-canvas__shift--bottom {
  min-height: 100vh;
}
.js .off-canvas__shift--bottom.is-active {
  height: 100vh;
  overflow: hidden;
}
.js .off-canvas__shift--left > .off-canvas__primary,
.js .off-canvas__shift--right > .off-canvas__primary {
  min-height: 100vh;
}
.js .off-canvas__shift--left > .off-canvas__primary {
  transform: translate3d(-100%, 0, 0);
  float: right;
  margin-right: -100%;
}
.js .off-canvas__shift--right > .off-canvas__primary {
  transform: translate3d(100%, 0, 0);
  float: left;
  margin-left: -100%;
}
@media all and (min-width: 48em) {
  .js .off-canvas__shift--right.is-active > .off-canvas__primary {
    transform: translate3d(30%, 0, 0);
  }
}
@media all and (min-width: 62em) {
  .js .off-canvas__shift--right.is-active > .off-canvas__primary {
    transform: translate3d(40%, 0, 0);
  }
}
@media all and (min-width: 48em) {
  .js .off-canvas__shift--left.is-active > .off-canvas__primary {
    transform: translate3d(-30%, 0, 0);
  }
}
@media all and (min-width: 62em) {
  .js .off-canvas__shift--left.is-active > .off-canvas__primary {
    transform: translate3d(-40%, 0, 0);
  }
}
.js .off-canvas__shift--top.is-active > .off-canvas__primary,
.js .off-canvas__shift--bottom.is-active > .off-canvas__primary {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}
.js .off-canvas__shift--top.is-active > .off-canvas__primary {
  transform: translate3d(0, 80vh, 0);
}
.js .off-canvas__shift--bottom.is-active > .off-canvas__primary {
  transform: translate3d(0, -80vh, 0);
}
.js .off-canvas__shift--left > .off-canvas__secondary,
.js .off-canvas__shift--right > .off-canvas__secondary {
  min-height: 100vh;
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
}
@media all and (min-width: 48em) {
  .js .off-canvas__shift--left > .off-canvas__secondary,
  .js .off-canvas__shift--right > .off-canvas__secondary {
    width: 70%;
  }
}
@media all and (min-width: 62em) {
  .js .off-canvas__shift--left > .off-canvas__secondary,
  .js .off-canvas__shift--right > .off-canvas__secondary {
    width: 60%;
  }
}
.js .off-canvas__shift--left > .off-canvas__secondary {
  transform: translate3d(0, 0, 0);
  float: left;
  margin-left: -100%;
}
@media all and (min-width: 48em) {
  .js .off-canvas__shift--left > .off-canvas__secondary {
    margin-left: -70%;
  }
}
@media all and (min-width: 62em) {
  .js .off-canvas__shift--left > .off-canvas__secondary {
    margin-left: -60%;
  }
}
.js .off-canvas__shift--right > .off-canvas__secondary {
  transform: translate3d(0, 0, 0);
  float: right;
  margin-right: -100%;
}
@media all and (min-width: 48em) {
  .js .off-canvas__shift--right > .off-canvas__secondary {
    margin-right: -70%;
  }
}
@media all and (min-width: 62em) {
  .js .off-canvas__shift--right > .off-canvas__secondary {
    margin-right: -60%;
  }
}
.js .off-canvas__shift--left.is-active > .off-canvas__secondary {
  transform: translate3d(100%, 0, 0);
}
.js .off-canvas__shift--right.is-active > .off-canvas__secondary {
  transform: translate3d(-100%, 0, 0);
}
.js .off-canvas__shift--top > .off-canvas__secondary,
.js .off-canvas__shift--bottom > .off-canvas__secondary {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 80vh;
  overflow: hidden;
}
.js .off-canvas__shift--top > .off-canvas__secondary {
  transform: translate3d(0, -100%, 0);
  top: 0;
}
.js .off-canvas__shift--bottom > .off-canvas__secondary {
  transform: translate3d(0, 100%, 0);
  bottom: 0;
}
.js .off-canvas__shift--top.is-active > .off-canvas__secondary,
.js .off-canvas__shift--bottom.is-active > .off-canvas__secondary {
  transform: translate3d(0, 0, 0);
  overflow: auto;
}
.off-canvas__slide-base,
.js .off-canvas__slide--left,
.js .off-canvas__slide--right,
.js .off-canvas__slide--top,
.js .off-canvas__slide--bottom {
  position: fixed;
  z-index: 110;
  overflow: auto;
  overflow-x: hidden;
  transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.off-canvas__slide-base.is-revealed,
.js .off-canvas__slide--left.is-revealed,
.js .off-canvas__slide--right.is-revealed,
.js .off-canvas__slide--top.is-revealed,
.js .off-canvas__slide--bottom.is-revealed {
  transform: translate3d(0, 0, 0);
}
.js .off-canvas__slide--left {
  top: 0;
  bottom: 0;
  left: 0;
  width: 80%;
  transform: translate3d(-100%, 0, 0);
}
@media all and (min-width: 48em) {
  .js .off-canvas__slide--left {
    width: 33%;
  }
}
@media all and (min-width: 62em) {
  .js .off-canvas__slide--left {
    width: 25%;
  }
}
@media all and (min-width: 77.5em) {
  .js .off-canvas__slide--left {
    width: 20%;
  }
}
.js .off-canvas__slide--right {
  top: 0;
  right: 0;
  bottom: 0;
  width: 80%;
  transform: translate3d(100%, 0, 0);
}
@media all and (min-width: 48em) {
  .js .off-canvas__slide--right {
    width: 33%;
  }
}
@media all and (min-width: 62em) {
  .js .off-canvas__slide--right {
    width: 25%;
  }
}
@media all and (min-width: 77.5em) {
  .js .off-canvas__slide--right {
    width: 20%;
  }
}
.js .off-canvas__slide--top {
  top: 0;
  right: 0;
  left: 0;
  height: 90%;
  transform: translate3d(0, -100%, 0);
}
@media all and (min-width: 48em) {
  .js .off-canvas__slide--top {
    height: 33%;
  }
}
@media all and (min-width: 62em) {
  .js .off-canvas__slide--top {
    height: 25%;
  }
}
@media all and (min-width: 77.5em) {
  .js .off-canvas__slide--top {
    height: 20%;
  }
}
.js .off-canvas__slide--bottom {
  right: 0;
  bottom: 0;
  left: 0;
  height: 90%;
  transform: translate3d(0, 100%, 0);
}
@media all and (min-width: 48em) {
  .js .off-canvas__slide--bottom {
    height: 33%;
  }
}
@media all and (min-width: 62em) {
  .js .off-canvas__slide--bottom {
    height: 25%;
  }
}
@media all and (min-width: 77.5em) {
  .js .off-canvas__slide--bottom {
    height: 20%;
  }
}
.overflow-list {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
}
.overflow-list__item {
  display: inline-block;
  white-space: normal;
  vertical-align: top;
  position: relative;
}
@media all and (min-width: 48em) {
  .overflow-list--until-medium {
    overflow: visible;
    white-space: normal;
  }
}
@media all and (min-width: 62em) {
  .overflow-list--until-large {
    overflow: visible;
    white-space: normal;
  }
}
@media all and (min-width: 77.5em) {
  .overflow-list--until-x-large {
    overflow: visible;
    white-space: normal;
  }
}
/*

Wrapper

This is the site-wide containing class. Put it around anything you want to be
contained in the "site width". By default, caps off at `1150px`.

*/
.wrappers__wrapper {
  width: 92%;
  margin-left: auto;
  margin-right: auto;
}
@media all and (max-width: 47.9375em) {
  .wrappers__wrapper {
    width: 90%;
  }
}
@media all and (min-width: 62em) {
  .wrappers__wrapper {
    max-width: 1150px;
  }
}
/*

Tables

*/
/*

Default Tables

Simple, clean default styles. Just mark it up like a normal table.

Undoes table to more of a definition list on smaller viewports.

See: http://codepen.io/aarongustafson/pen/ucJGv

````tables
<table>
    <thead>
        <tr>
            <th scope="col">#</th>
            <th scope="col">First Name</th>
            <th scope="col">Last Name</th>
            <th scope="col">Language</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td data-th="#">1</td>
            <td data-th="First Name">Some</td>
            <td data-th="Last Name">One</td>
            <td data-th="Language">English</td>
        </tr>
        <tr>
            <td data-th="#">2</td>
            <td data-th="First Name">Joe</td>
            <td data-th="Last Name">Sixpack</td>
            <td data-th="Language">English</td>
        </tr>
        <tr>
            <td data-th="#">3</td>
            <td data-th="First Name">Stu</td>
            <td data-th="Last Name">Dent</td>
            <td data-th="Language">Code</td>
        </tr>
    </tbody>
</table>
````

*/
table {
  width: 100%;
  max-width: 100%;
  border-spacing: 0;
}
th,
td {
  padding: 0.625rem 0.5625rem;
  text-align: left;
}
th {
  padding-top: 0.625rem;
}
@media all and (max-width: 47.9375em) {
  table {
    display: block;
    width: 100%;
    max-width: 100%;
    padding-bottom: 30px;
    overflow-x: scroll;
  }
  tbody,
  tr,
  th,
  td {
    text-align: left;
    white-space: normal;
  }
  thead {
    display: none;
    visibility: hidden;
  }
  tr {
    margin-top: 1.1111111111111112em;
  }
  td[data-th]:before {
    content: attr(data-th) ":\00A0";
    display: inline-block;
    font-weight: bold;
  }
  td:empty {
    display: none;
  }
}
@media all and (min-width: 48em) {
  th,
  td {
    padding: 0.625rem 1.125rem;
  }
}
/*

panel

Used to provide messaging to the user, whether it be direct or through calls to 
action. The panel typically indicates a visual separation and implied 
sub-grouping of its content.

```panel
<div class="panel">
    <h3>This is a Panel</h3>
    <p>This is a <a href="#">feedback message</a> for the user.</p>
    <p><a href="#" class="btn">User Action</a></p>
</div>
<div class="panel">
    <p>This is a feedback panel with no heading.</p>
</div>
````

*/
.panel,
[class*="panel"],
[class*="panel__"] {
  margin-top: 1.1111111111111112em;
  padding: 1.25rem;
  background-color: #f2f1f1;
}
.panel > :first-child,
[class*="panel"] > :first-child,
[class*="panel__"] > :first-child {
  margin-top: 0;
}
.panel h1,
[class*="panel"] h1,
[class*="panel__"] h1,
.panel h2,
[class*="panel"] h2,
[class*="panel__"] h2,
.panel h3,
[class*="panel"] h3,
[class*="panel__"] h3,
.panel h4,
[class*="panel"] h4,
[class*="panel__"] h4,
.panel h5,
[class*="panel"] h5,
[class*="panel__"] h5,
.panel h6,
[class*="panel"] h6,
[class*="panel__"] h6 {
  color: inherit;
}
[class*="panel--thin"] {
  padding: 0.625rem;
}
/*

Alert Panel

This variation of the standard `.panel` is used specifically for delivering 
direct messages to the user, whether they be informative, complimentary, 
or preventative.

````info-alert-panel
<div class="alert-panel--info">
    <h3>This is a Panel</h3>
    <p>This is an <a href="#">alert message</a> for the user.</p>
    <p><a href="#" class="btn">User Action</a></p>
</div>
<div class="alert-panel--info">
    <p>This is an alert panel with no heading.</p>
</div>
````

*/
/*

````error-alert-panel
<div class="panel__alert--error">
    <h3>This is a Panel</h3>
    <p>This is an <a href="#">alert message</a> for the user.</p>
    <p><a href="#" class="btn">User Action</a></p>
</div>
<div class="panel__alert--error">
    <p>This is an alert panel with no heading.</p>
</div>
````

*/
/*

````success-alert-panel
<div class="panel__alert--success">
    <h3>This is a Panel</h3>
    <p>This is an <a href="#">alert message</a> for the user.</p>
    <p><a href="#" class="btn">User Action</a></p>
</div>
<div class="panel__alert--success">
    <p>This is an alert panel with no heading.</p>
</div>
````

*/
[class*="panel__alert"] {
  color: #fff;
}
.panel__alert {
  background: #013b66;
}
.panel__alert--error {
  background: #F7951B;
}
.panel__alert--success {
  background: #72bc44;
}
/*

WYSIWYG

Though we keep our styles as flexible and modular as possible, there may be
times, as when using a WYSIWYG editor, when some styles need to be baked in.
Here, we specify a `.wysiwyg` class that wraps around that content, and
hardcode some of those styles

    <div class="wysiwyg">
        [WYSIWYG Content]
    </div>

*/
.wysiwyg {
  color: #fff;
}
.wysiwyg h1 {
  margin-top: 0;
}
.wysiwyg h2,
.wysiwyg h3,
.wysiwyg h4,
.wysiwyg h5,
.wysiwyg h6 {
  margin-top: 1.5em;
  color: #fff;
  max-width: 75%;
  max-width: 85ch;
  text-transform: uppercase;
}
.wysiwyg h2,
.wysiwyg h3 {
  padding-bottom: 1.875rem;
}
.wysiwyg h2:after,
.wysiwyg h3:after {
  content: "";
  display: block;
  margin-top: 1.875rem;
  width: 100px;
  height: 4px;
  background-color: #A4664E;
}
.wysiwyg h2 + p,
.wysiwyg h3 + p,
.wysiwyg h2 + ul,
.wysiwyg h3 + ul {
  margin-top: 0;
}
.wysiwyg h1,
.wysiwyg .hierarchy__canon-text {
  font-family: "HelveticaNeueLTStd-Cn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-weight: normal;
  font-size: 60px;
  font-size: 3.75rem;
  line-height: 1.13333333;
  letter-spacing: -0.00666667em;
  margin-top: 0;
  text-transform: uppercase;
}
.wysiwyg h1 strong,
.wysiwyg .hierarchy__canon-text strong {
  font-family: "HelveticaNeueLTStd-BdCn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-weight: 400;
}
@media all and (min-width: 48em) {
  .wysiwyg h1,
  .wysiwyg .hierarchy__canon-text {
    font-size: 80px;
    font-size: 5rem;
    line-height: 1.125;
    letter-spacing: -0.00625em;
  }
}
.wysiwyg h2,
.wysiwyg .hierarchy__paragon-text {
  font-size: 38px;
  font-size: 2.375rem;
  line-height: 1.10526316;
  letter-spacing: -0.00526316em;
}
@media all and (min-width: 48em) {
  .wysiwyg h2,
  .wysiwyg .hierarchy__paragon-text {
    font-size: 48px;
    font-size: 3rem;
    line-height: 1.04166667;
    letter-spacing: -0.00583333em;
  }
}
.wysiwyg h3,
.wysiwyg .primer-text {
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 1.26666667;
  letter-spacing: -0.00566667em;
}
@media all and (min-width: 48em) {
  .wysiwyg h3,
  .wysiwyg .primer-text {
    font-size: 38px;
    font-size: 2.375rem;
    line-height: 1.10526316;
    letter-spacing: -0.00526316em;
  }
}
.wysiwyg h4 {
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 1.26666667;
  letter-spacing: -0.00566667em;
}
.wysiwyg h5,
.wysiwyg .hierarchy__petite-text {
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 1.54545455;
  letter-spacing: -0.00681818em;
  margin-top: 0;
}
.wysiwyg p {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.55555556;
  letter-spacing: 0.02222222em;
  max-width: 75%;
  max-width: 75ch;
}
.wysiwyg p a:not([class*="button"]) {
  font-family: "HelveticaNeueLTStd-BdCn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-weight: 400;
  text-decoration: underline;
}
.wysiwyg .copper {
  color: #A4664E;
}
.wysiwyg ul,
.wysiwyg dl {
  margin: 0.5625rem 0 0;
  padding: 0 0 0 0.5625rem;
  list-style: none;
}
.wysiwyg ul li,
.wysiwyg dl li,
.wysiwyg ul dd,
.wysiwyg dl dd {
  padding-left: 1.125rem;
  padding-bottom: 0.3125rem;
  position: relative;
}
.wysiwyg ul li:before,
.wysiwyg dl li:before,
.wysiwyg ul dd:before,
.wysiwyg dl dd:before {
  content: "";
  background-color: #A4664E;
  position: absolute;
  top: 2px;
  left: 4px;
  width: 4px;
  height: 21px;
}
.wysiwyg ol {
  counter-reset: ordered-list;
  margin: 0.5625rem 0 0;
  padding: 0 0 0 0.5625rem;
  list-style: none;
}
.wysiwyg ol li {
  padding-left: 1.125rem;
  padding-bottom: 0.3125rem;
  position: relative;
}
.wysiwyg ol li:before {
  counter-increment: ordered-list;
  content: counter(ordered-list);
  font-family: "HelveticaNeueLTStd-BdCn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  color: #A4664E;
  position: absolute;
  top: 0;
  left: 0;
}
.wysiwyg strong {
  font-family: "HelveticaNeueLTStd-BdCn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-weight: 400;
}
.wysiwyg__editor {
  background: #1A1A1A;
}
/*

Animated Revealing/Hiding Block Modules

When JavaScript is enabled, these blocks are used (most easily in conjunction 
with Motif jQuery plugins) to reveal and hide blocks of content, sometimes 
accompanied by CSS3 animations.
    
Reveal Target

`.reveal__target` is a simple class that is accessibly hidden by default.
Add the `.is-revealed` class to show it.

    <div class="reveal__target">
        I am hidden.
    </div>
    <div class="reveal__target is-revealed">
        I am no longer hidden.
    </div>

*/
.js .reveal__target.is-revealed {
  overflow: visible;
}
/*

Animated Revealing/Hiding Block Modules

When JavaScript is enabled, these blocks are used (most easily in conjunction 
with Motif jQuery plugins) to reveal and hide blocks of content, sometimes 
accompanied by CSS3 animations.
    
Expand Target

`.expand__target` has a maximum height of `0` by default, and once it 
receives the `.is-expanded` class, animates in height to its maximum value. 
This is the cheap CSS way of expanding animation, as in collapsible 
accordions. More precise timing most likely requires JS enhancement.

    <div class="expand__target">
        I am hidden from view.
    </div>
    <div class="expand__target is-expanded">
        I have expanded into view.
    </div>

*/
.js .expand__target {
  max-height: 0;
  overflow: hidden;
}
.js .expand__target.is-expanded {
  max-height: 999px;
}
.js .expand__target.is-expanded,
.js .expand__target.was-expanded {
  transition: all 0.65s cubic-bezier(0.645, 0.045, 0.355, 1);
}
/*

Animated Revealing/Hiding Block Modules

When JavaScript is enabled, these blocks are used (most easily in conjunction 
with Motif jQuery plugins) to reveal and hide blocks of content, sometimes 
accompanied by CSS3 animations.

Fade Target

`.fade__target` is the same as `.expand__target` (in that it grows in 
height), but it adds opacity fading to the mix.

    <div class="fade__target">
        I am hidden.
    </div>
    <div class="fade__target is-faded">
        I have faded and expanded into view.
    </div>

*/
.js .fade__target {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
}
.js .fade__target.is-faded {
  opacity: 1;
  max-height: 999px;
}
.js .fade__target.is-faded,
.js .fade__target.was-faded {
  transition: all 0.65s cubic-bezier(0.645, 0.045, 0.355, 1);
}
/*

Main Nav Module

A minor enhancement to the Reveal or Expand Target modules if added to the
main navigation bar. This snippet, on Medium screens and larger, makes sure
the expandable Nav (on smaller screens) is no longer hidden, and that the 
Menu Title (be it "Main Menu", the hamburger icon, etc.) is hidden from view.

    <nav class="nav-bar--menu" role="navigation">
        <h3 class="menu__title js-expand" id="reveal-main-nav">
            <svg class="icon"><use xlink:href="#rows"></svg>
            Main Menu
        </h3>
        <ul class="menu__list expand__target" id="main-nav-list">
            ...
        </ul>
    </nav>

*/
@media all and (min-width: 48em) {
  .js .menu__list {
    max-height: 999px;
  }
}
@media all and (min-width: 48em) {
  .menu__title {
    display: none;
  }
}
/*

Tabs

The tabs module (and accompanying plugin) creates a very basic widget that 
allows embedding and basic styling.

````tabs
<section class="tabs js-tabs">
    <nav role="navigation">
        <ul>
            <li>
                <a href="#first-tab">
                    First Tab
                </a>
            </li>
            <li>
                <a href="#second-tab">
                    Second Tab
                </a>
            </li>
        </ul>
    </nav>
    <div>
        <section id="first-tab">
            <h3>First Tab</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </section>
        <section id="second-tab">
            <h3>Second Tab</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </section>
    </div>
</section>
````

*/
.js .tabs > nav + *,
.js .tabs__section {
  position: relative;
  overflow: hidden;
}
.js .tabs > nav + * > *,
.js .tabs .tab {
  display: none;
}
.js .tabs > nav + * > *.is-current,
.js .tabs .tab.is-current {
  display: block;
}
.header {
  background-color: #4D4D4F;
}
.header__wrapper {
  position: relative;
}
@media all and (min-width: 62em) {
  .header__wrapper {
    margin: 0 auto;
  }
}
.header__figure {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  margin: 0;
}
.header__content {
  width: 92%;
  margin-left: auto;
  margin-right: auto;
  /* Mixins */
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-end;
  margin-top: 56px;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
@media all and (max-width: 47.9375em) {
  .header__content {
    width: 90%;
  }
}
@media all and (min-width: 62em) {
  .header__content {
    max-width: 1150px;
  }
}
.header__content:before,
.header__content:after {
  content: "\0020";
  height: 0;
  display: block;
  overflow: hidden;
}
.header__content:after {
  clear: both;
}
@media all and (min-width: 62em) {
  .header__content {
    margin-top: 0;
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
}
.header__heading {
  font-size: 48px;
  font-size: 3rem;
  line-height: 1.04166667;
  letter-spacing: -0.00583333em;
  color: #fff;
}
.header__heading span {
  display: inline;
  background: #0A0203;
  margin-left: 0;
  line-height: 4rem;
}
.header__heading span.bg-text__outer {
  padding: 0.625rem;
}
@media all and (min-width: 48em) {
  .header__heading {
    font-size: 60px;
    font-size: 3.75rem;
    line-height: 1.13333333;
    letter-spacing: -0.00666667em;
    max-width: calc((100% / 12) * 7);
  }
}
.header__subheading {
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 1.26666667;
  letter-spacing: -0.00566667em;
  font-family: "HelveticaNeueLTStd-Cn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  color: #fff;
  font-style: italic;
  margin-top: 1.25rem;
}
.header__desc {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.55555556;
  letter-spacing: -0.00722222em;
  color: #fff;
  margin-top: 1.25rem;
}
.header__desc > * {
  padding: 0.625rem;
}
.header__desc * {
  display: inline;
  margin-left: 0;
}
.header__desc *.bg-text__outer {
  background: #252325;
}
@media all and (min-width: 48em) {
  .header__desc {
    font-size: 22px;
    font-size: 1.375rem;
    line-height: 1.54545455;
    letter-spacing: -0.00681818em;
    max-width: calc((100% / 12) * 6);
  }
}
.simpleHeader {
  position: relative;
  background-color: #A4664E;
  margin-top: 72px;
}
.simpleHeader__wrapper {
  position: relative;
}
@media all and (min-width: 62em) {
  .simpleHeader__wrapper {
    margin: 0 auto;
  }
}
.simpleHeader__content {
  width: 92%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
@media all and (max-width: 47.9375em) {
  .simpleHeader__content {
    width: 90%;
  }
}
@media all and (min-width: 62em) {
  .simpleHeader__content {
    max-width: 1150px;
  }
}
@media all and (min-width: 48em) {
  .simpleHeader__content {
    flex-direction: row;
    align-items: center;
  }
}
@media all and (min-width: 48em) {
  .simpleHeader__heading--wrapper {
    flex: 0 0 auto;
    width: 46%;
    padding-right: 1.125rem;
    margin-right: 1.125rem;
    border-right: 1px solid white;
  }
}
@media all and (min-width: 62em) {
  .simpleHeader__heading--wrapper {
    width: 44%;
  }
}
.simpleHeader__meta--wrapper {
  padding-right: 10%;
}
@media all and (min-width: 48em) {
  .simpleHeader__meta--wrapper {
    padding-right: 0;
    flex: 0 0 auto;
    width: 54%;
    margin-left: 20px;
  }
}
@media all and (min-width: 62em) {
  .simpleHeader__meta--wrapper {
    width: 56%;
  }
}
.simpleHeader__heading {
  font-family: "HelveticaNeueLTStd-Cn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-weight: normal;
  font-size: 60px;
  font-size: 3.75rem;
  line-height: 1.13333333;
  letter-spacing: -0.00666667em;
  margin-top: 0;
  text-transform: uppercase;
  font-size: 48px;
  font-size: 3rem;
  line-height: 1.04166667;
  letter-spacing: -0.00583333em;
  margin-right: 1.125rem;
  color: #fff;
  text-transform: none;
}
.simpleHeader__heading strong {
  font-family: "HelveticaNeueLTStd-BdCn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-weight: 400;
}
@media all and (min-width: 48em) {
  .simpleHeader__heading {
    font-size: 80px;
    font-size: 5rem;
    line-height: 1.125;
    letter-spacing: -0.00625em;
  }
}
@media all and (min-width: 48em) {
  .simpleHeader__heading {
    font-size: 60px;
    font-size: 3.75rem;
    line-height: 1.13333333;
    letter-spacing: -0.00666667em;
  }
}
.simpleHeader__subheading {
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 1.26666667;
  letter-spacing: -0.00566667em;
  font-family: "HelveticaNeueLTStd-Cn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  color: #fff;
  font-style: italic;
}
.simpleHeader__desc {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.55555556;
  letter-spacing: -0.00722222em;
  color: #fff;
  margin-top: 1.25rem;
}
@media all and (min-width: 48em) {
  .simpleHeader__desc {
    padding-right: 2.25rem;
    margin-top: 0;
  }
}
.cards {
  padding-top: 3em;
  position: relative;
  background: #0A0203;
}
@media all and (min-width: 62em) {
  .cards {
    padding-top: 5em;
  }
}
.card__figure {
  height: 220px;
  margin-bottom: 1.25rem;
  margin-top: 0;
}
.cards__wrapper {
  width: 92%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 5rem;
  position: relative;
}
@media all and (max-width: 47.9375em) {
  .cards__wrapper {
    width: 90%;
  }
}
@media all and (min-width: 62em) {
  .cards__wrapper {
    max-width: 1150px;
  }
}
.cards__wrapper:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
}
.cards__heading {
  font-size: 38px;
  font-size: 2.375rem;
  line-height: 1.10526316;
  letter-spacing: -0.00526316em;
  color: #fff;
  margin-bottom: 2.5rem;
  padding-top: 2.5rem;
  position: relative;
}
@media all and (min-width: 48em) {
  .cards__heading {
    font-size: 48px;
    font-size: 3rem;
    line-height: 1.04166667;
    letter-spacing: -0.00583333em;
  }
}
@media all and (max-width: 47.9375em) {
  .card__list .card__listItem:nth-child(n+2) a {
    margin-top: 3em;
  }
}
@media all and (min-width: 48em) {
  .card__list {
    display: flex;
    flex-wrap: wrap;
  }
  .card__list .card__listItem:nth-child(n+4) {
    margin-top: 3em;
  }
}
ul.card__list {
  list-style: none;
  padding: 0;
}
.card__list .styles__tertiary-heading {
  margin-top: 1em;
}
.card__listItem {
  color: #fff;
}
@media all and (min-width: 48em) {
  .card__listItem {
    flex: 0 0 auto;
    width: calc((100% / 12) * 6);
    display: flex;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
  }
}
@media all and (min-width: 62em) {
  .card__listItem {
    width: calc((100% / 12) * 4);
  }
}
@media all and (max-width: 47.9375em) {
  .card__listItem {
    margin-bottom: 5rem;
  }
}
.card__wrapper {
  background-color: #252325;
  box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.2);
  display: block;
  position: relative;
  text-align: center;
  text-decoration: none;
}
@media all and (min-width: 48em) {
  .card__wrapper {
    flex: 0 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
.card__wrapper:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 10px;
}
.card__contentWrapper {
  color: #fff;
  padding: 1em 1.5em;
}
@media all and (min-width: 48em) {
  .card__contentWrapper {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
  }
}
.card__heading {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.55555556;
  letter-spacing: -0.00722222em;
  font-family: "HelveticaNeueLTStd-BdCn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  text-transform: uppercase;
  color: #fff;
}
.card__desc {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.625;
  font-weight: normal;
}
@media all and (min-width: 48em) {
  .card__desc {
    flex: 1 0 auto;
  }
}
.card__ctaWrapper {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.55555556;
  letter-spacing: -0.00722222em;
}
.cta {
  background: #0A0203;
}
.cta__wrapper {
  position: relative;
}
@media all and (min-width: 62em) {
  .cta__wrapper {
    margin: 0 auto;
  }
}
.cta__figure {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  margin: 0;
}
.cta__content {
  width: 92%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
@media all and (max-width: 47.9375em) {
  .cta__content {
    width: 90%;
  }
}
@media all and (min-width: 62em) {
  .cta__content {
    max-width: 1150px;
  }
}
@media all and (min-width: 48em) {
  .cta__content {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
}
.cta__heading {
  font-size: 38px;
  font-size: 2.375rem;
  line-height: 1.10526316;
  letter-spacing: -0.00526316em;
  color: #fff;
  text-transform: uppercase;
}
.cta__heading span {
  display: inline;
  margin-left: 0;
}
.cta__heading span.bg-text__outer {
  background: #0A0203;
  padding: 0.625rem;
}
@media all and (min-width: 48em) {
  .cta__heading {
    font-size: 60px;
    font-size: 3.75rem;
    line-height: 1.16666667;
    max-width: calc((100% / 12) * 7);
    letter-spacing: 0.01083333em;
  }
}
.cta__desc {
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 1.54545455;
  letter-spacing: -0.00681818em;
  margin-top: 1.25rem;
  padding-left: 9px;
}
.cta__desc > span {
  display: inline;
  color: #fff;
}
.cta__desc > span.bg-text__outer {
  background-color: #252325;
  box-shadow: 0 0 0 10px #252325;
}
@media all and (min-width: 48em) {
  .cta__desc {
    font-size: 22px;
    font-size: 1.375rem;
    line-height: 1.54545455;
    letter-spacing: -0.00681818em;
    max-width: calc((100% / 12) * 6);
  }
}
.download {
  margin-top: 2.5rem;
}
@media all and (min-width: 48em) {
  .download {
    margin-top: 5rem;
  }
}
.download__wrapper {
  width: 92%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 2.5rem;
}
@media all and (max-width: 47.9375em) {
  .download__wrapper {
    width: 90%;
  }
}
@media all and (min-width: 62em) {
  .download__wrapper {
    max-width: 1150px;
  }
}
.download__heading {
  padding-bottom: 1.875rem;
  color: #fff;
}
.download__heading:after {
  content: "";
  display: block;
  margin-top: 1.875rem;
  width: 100px;
  height: 4px;
  background-color: #A4664E;
}
.download__heading + p,
.download__heading + ul {
  margin-top: 0;
}
.download__item {
  background-color: #fff;
  display: flex;
  position: relative;
}
.download__item:nth-child(odd) {
  background-color: #F2EAE7;
}
.download__item:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 58px;
  width: 20px;
  z-index: 2;
  background-color: #fff;
  transform: skewX(-15deg);
}
.download__item:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -10px;
  width: 70px;
  z-index: 1;
  background-color: #0092C8;
  transform: skewX(-15deg);
}
.download__type {
  font-family: "HelveticaNeueLTStd-BdCn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 1.54545455;
  letter-spacing: -0.00681818em;
  color: #A4664E;
}
@media all and (min-width: 48em) {
  .download__type {
    width: 30%;
  }
}
.download__link {
  font-family: "HelveticaNeueLTStd-Cn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.14285714;
}
@media all and (min-width: 48em) {
  .download__link {
    width: 20%;
  }
}
@media all and (min-width: 48em) {
  .download__cta {
    width: 25%;
  }
}
@media all and (min-width: 48em) {
  .download__link {
    width: 25%;
  }
}
.list {
  margin-top: 2.5rem;
}
@media all and (min-width: 48em) {
  .list {
    margin-top: 5rem;
  }
}
.list__wrapper {
  width: 92%;
  margin-left: auto;
  margin-right: auto;
}
@media all and (max-width: 47.9375em) {
  .list__wrapper {
    width: 90%;
  }
}
@media all and (min-width: 62em) {
  .list__wrapper {
    max-width: 1150px;
  }
}
.list__row + .accordion {
  margin-top: 3em;
}
.list__heading {
  font-family: "HelveticaNeueLTStd-BdCn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 1.26666667;
  letter-spacing: -0.00566667em;
  text-transform: uppercase;
}
.list__meta {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.14285714;
  font-family: "HelveticaNeueLTStd-Cn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
}
.feature {
  background: #0A0203;
  position: relative;
  padding: 1.25rem 0;
}
@media all and (min-width: 48em) {
  .feature {
    padding: 5rem 0;
  }
}
.feature + .feature {
  padding-top: 0.625rem;
  padding-bottom: 5rem;
}
.feature__background {
  position: absolute;
  top: 0;
  left: 0;
}
.feature__wrapper {
  width: 92%;
  margin-left: auto;
  margin-right: auto;
}
@media all and (max-width: 47.9375em) {
  .feature__wrapper {
    width: 90%;
  }
}
@media all and (min-width: 62em) {
  .feature__wrapper {
    max-width: 1150px;
  }
}
.feature__row {
  display: flex;
  flex-direction: column-reverse;
}
@media all and (min-width: 48em) {
  .feature__row {
    flex-direction: row;
  }
}
.feature__contentCol {
  color: #fff;
}
@media all and (min-width: 48em) {
  .feature__contentCol {
    flex: 0 0 auto;
    width: calc((100% / 12) * 5.5);
    height: 100%;
    padding-right: 2.5rem;
    order: 1;
    display: flex;
    flex-direction: column;
    padding-right: 3.75rem;
  }
  .rtl .feature__contentCol {
    order: 2;
    padding-left: calc(100% / 12);
  }
}
@media all and (min-width: 62em) {
  .feature__contentCol {
    padding-right: 3.75rem;
    width: calc((100% / 12) * 5);
  }
  .rtl .feature__contentCol {
    padding-left: calc(100% / 12);
  }
}
.feature__heading {
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 1.26666667;
  letter-spacing: -0.00566667em;
  font-family: "HelveticaNeueLTStd-Cn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  position: relative;
  color: #fff;
  text-transform: uppercase;
  padding-bottom: 1.25rem;
}
@media all and (min-width: 48em) {
  .feature__heading {
    font-size: 38px;
    font-size: 2.375rem;
    line-height: 1.10526316;
    letter-spacing: -0.00526316em;
  }
}
.feature__heading:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 67px;
  height: 4px;
  background: #A4664E;
}
.feature__imageCol {
  margin-top: 2.5rem;
}
@media all and (min-width: 48em) {
  .feature__imageCol {
    flex: 0 0 auto;
    width: calc((100% / 12) * 6.5);
    margin-top: 0;
    order: 2;
    display: flex;
    flex-direction: column;
  }
  .rtl .feature__imageCol {
    order: 1;
  }
}
@media all and (min-width: 62em) {
  .feature__imageCol {
    width: calc((100% / 12) * 7);
  }
}
.feature__image--wrapper {
  position: relative;
}
.feature__image--wrapper:before {
  content: '';
  display: block;
  width: 100%;
  padding-top: 66.66666667%;
}
.feature__image {
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.feature__table {
  display: table;
  border-collapse: collapse;
}
.feature__table .feature__table-row {
  display: table-row;
}
.feature__table .feature__table-row .feature__table-col {
  display: table-cell;
}
.feature__table .feature__table-row .feature__table-col:first-child {
  min-width: 75px;
}
.feature__table .feature__table-row .feature__table-col:last-child {
  padding-left: 0.5625rem;
}
.testimonial {
  padding: 5rem 0;
  background: #0A0203;
}
.testimonial__wrapper {
  width: 92%;
  margin-left: auto;
  margin-right: auto;
}
@media all and (max-width: 47.9375em) {
  .testimonial__wrapper {
    width: 90%;
  }
}
@media all and (min-width: 62em) {
  .testimonial__wrapper {
    max-width: 1150px;
  }
}
@media all and (min-width: 48em) {
  .testimonial__row,
  .testimonialFeatureDual__row {
    display: flex;
  }
}
.testimonial__imageCol,
.testimonialFeatureDual__testimonial-imageCol {
  flex: 0 0 auto;
  padding: 0.625rem 0.5625rem 0;
  position: relative;
}
@media all and (min-width: 48em) {
  .testimonial__imageCol,
  .testimonialFeatureDual__testimonial-imageCol {
    width: 50%;
  }
}
.testimonial__imageCol:before,
.testimonialFeatureDual__testimonial-imageCol:before {
  content: "";
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+60&1+0,1+60,0+61 */
  background: linear-gradient(115deg, #A4664E 0%, #A4664E 75%, rgba(0, 0, 0, 0) 76%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#A4664E', endColorstr='#00000000', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100px;
  height: 65px;
}
.testimonial__imageCol:after,
.testimonialFeatureDual__testimonial-imageCol:after {
  content: "";
  background: transparent url(/resources/motif/images/quote-mark.svg) 0 0 no-repeat;
  width: 33px;
  height: 24px;
  position: absolute;
  top: 17px;
  left: 23px;
  z-index: 2;
}
.testimonial__figure,
.testimonialFeatureDual__testimonial-figure {
  margin: 0;
  position: relative;
}
.testimonial__figure:before {
  content: '';
  display: block;
  width: 100%;
  padding-top: 66.66666667%;
}
.testimonial__image,
.testimonialFeatureDual__testimonial-image {
  position: absolute;
  top: 0;
  left: 0;
}
.testimonial__contentCol,
.testimonialFeatureDual__testimonial-content {
  flex: 0 0 50%;
  color: #fff;
  align-self: center;
  margin-top: 1.25rem;
}
@media all and (min-width: 48em) {
  .testimonial__contentCol,
  .testimonialFeatureDual__testimonial-content {
    width: 50%;
    margin-top: 0;
    padding: 0 1.125rem;
  }
}
@media all and (min-width: 62em) {
  .testimonial__contentCol,
  .testimonialFeatureDual__testimonial-content {
    padding: 0 2.5rem;
  }
}
.testimonial__signature,
.testimonialFeatureDual__testimonial-signature {
  margin-top: -1rem;
}
.testimonial__attribute {
  font-family: "SignatureCollectionItalic";
  font-size: 60px;
  font-size: 3.75rem;
  line-height: 1.13333333;
  letter-spacing: -0.00666667em;
  color: #A4664E;
}
.testimonial__heading,
.testimonialFeatureDual__testimonial-heading {
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 1.26666667;
  letter-spacing: -0.00566667em;
  font-family: "HelveticaNeueLTStd-Cn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-style: italic;
}
.testimonial__byline,
.testimonialFeatureDual__testimonial-byline {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.14285714;
  font-family: "HelveticaNeueLTStd-Cn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-weight: 200;
  margin-top: 0;
}
.wysiwyg__block {
  margin-top: 2.5rem;
}
@media all and (min-width: 48em) {
  .wysiwyg__block {
    margin-top: 5rem;
  }
}
.accordion {
  width: 92%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2.5rem;
  padding-bottom: 2.5rem;
}
@media all and (max-width: 47.9375em) {
  .accordion {
    width: 90%;
  }
}
@media all and (min-width: 62em) {
  .accordion {
    max-width: 1150px;
  }
}
@media all and (min-width: 48em) {
  .accordion {
    margin-top: 5rem;
    padding-bottom: 5rem;
  }
}
.accordion__heading {
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 1.26666667;
  letter-spacing: -0.00566667em;
  color: #fff;
  text-transform: uppercase;
}
@media all and (min-width: 48em) {
  .accordion__heading {
    font-size: 38px;
    font-size: 2.375rem;
    line-height: 1.10526316;
    letter-spacing: -0.00526316em;
  }
}
.accordion__container {
  margin-top: 1.25rem;
}
.accordion__reveal {
  font-family: "HelveticaNeueLTStd-BdCn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.625;
  background-color: #3A3A3A;
  color: #fff;
  display: block;
  padding: 0.625rem 1.125rem;
  text-decoration: none;
  transition: background-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  z-index: 0;
  overflow: hidden;
  padding-right: calc(2.25rem + 60px);
}
@media all and (min-width: 48em) {
  .accordion__reveal {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.55555556;
    letter-spacing: -0.00722222em;
    padding-right: calc(1.125rem + 60px);
  }
}
.accordion__reveal:before {
  content: "";
  display: block;
  position: absolute;
  top: -1px;
  bottom: -1px;
  right: 58px;
  width: 20px;
  z-index: 2;
  background-color: #0A0203;
  transform: skewX(-15deg);
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.accordion__reveal:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -20px;
  width: 80px;
  z-index: 1;
  background-color: #0092C8;
  transform: skewX(-15deg);
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
@media all and (min-width: 48em) {
  .accordion__reveal:after {
    right: -20px;
    width: 80px;
  }
}
.accordion__reveal:hover,
.accordion__reveal:focus {
  color: #0092C8;
}
.accordion__reveal span.icon {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 20px;
  top: 50%;
  z-index: 3;
  transform: translateY(-50%);
}
.accordion__reveal span.icon:before {
  content: '';
  display: block;
  width: 16px;
  height: 0px;
  border-bottom: solid 2px #fff;
  position: absolute;
  bottom: 7px;
  transform: rotate(90deg);
  transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.accordion__reveal span.icon:after {
  content: '';
  display: block;
  width: 16px;
  height: 0px;
  border-bottom: solid 2px #fff;
  position: absolute;
  bottom: 7px;
}
.accordion__reveal.is-revealed {
  background-color: #A4664E;
  color: #fff;
}
.accordion__reveal.is-revealed:before {
  transform: skewX(15deg);
}
.accordion__reveal.is-revealed:after {
  transform: skewX(15deg);
}
.accordion__reveal.is-revealed span.icon:before {
  opacity: 0;
}
.accordion__content {
  border-bottom: 10px solid #A4664E;
  padding: 1.25rem 1.125rem;
  margin-top: 0;
}
.testimonialFeatureDual {
  background: #0A0203;
  position: relative;
  overflow: hidden;
}
@media all and (min-width: 48em) {
  .testimonialFeatureDual {
    padding-bottom: 100px;
  }
}
.testimonialFeatureDual__figureCol {
  position: relative;
}
.testimonialFeatureDual__figure {
  position: relative;
  margin: 0;
  z-index: 0;
}
.testimonialFeatureDual__figure:before {
  content: '';
  position: relative;
  padding-top: 75%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
}
@media all and (min-width: 48em) {
  .testimonialFeatureDual__figure {
    position: absolute;
    padding-top: 37.28343728%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .testimonialFeatureDual__figure:before {
    display: none;
  }
}
.testimonialFeatureDual__figure:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, #0A0203);
}
@media all and (min-width: 48em) {
  .testimonialFeatureDual__figure:after {
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 50%, #0A0203);
  }
  .switched .testimonialFeatureDual__figure:after {
    background: linear-gradient(to left, rgba(0, 0, 0, 0) 50%, #0A0203);
  }
}
@media all and (min-width: 48em) {
  .testimonialFeatureDual__wrapper {
    width: 92%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media all and (min-width: 62em) {
  .testimonialFeatureDual__wrapper {
    max-width: 1150px;
  }
}
.testimonialFeatureDual__row {
  position: relative;
  z-index: 2;
}
.switched .testimonialFeatureDual__row {
  flex-direction: row-reverse;
}
.testimonialFeatureDual__testimonial {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  background: none;
}
@media all and (min-width: 48em) {
  .testimonialFeatureDual__testimonial {
    flex-basis: 40%;
    padding-top: 5rem;
    padding-bottom: 5rem;
    padding-left: calc(100% / 12);
    width: 100%;
  }
  .switched .testimonialFeatureDual__testimonial {
    padding-right: calc(100% / 12);
    padding-left: 0;
  }
}
.testimonialFeatureDual__feature {
  position: relative;
  flex-basis: 60%;
  align-self: end;
  color: #fff;
}
:not(.switched) .testimonialFeatureDual__feature:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(164, 102, 78, 0.9);
}
@media all and (min-width: 48em) {
  :not(.switched) .testimonialFeatureDual__feature:before {
    width: 100%;
    clip-path: polygon(0 0, 0 100%, calc(100% - 75px) 100%, 100% 0);
  }
}
.switched .testimonialFeatureDual__feature:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(164, 102, 78, 0.9);
}
@media all and (min-width: 48em) {
  .switched .testimonialFeatureDual__feature:before {
    width: 100%;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 75px 100%);
  }
}
@media all and (min-width: 48em) {
  .testimonialFeatureDual__feature {
    margin-top: 20%;
  }
}
.testimonialFeatureDual__feature:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  transform: translateX(calc(-100% + 1px));
  background-color: rgba(164, 102, 78, 0.9);
}
.switched .testimonialFeatureDual__feature:after {
  left: auto;
  right: 0;
  transform: translateX(calc(100% - 1px));
}
@media all and (min-width: 78.125em) {
  .testimonialFeatureDual__feature:after {
    width: calc(calc((100vw - 1150px) * 0.5) + 1px);
  }
}
.testimonialFeatureDual__image {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.objectfit .testimonialFeatureDual__image {
  object-fit: cover;
}
.testimonialFeatureDual__copy {
  position: relative;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.testimonialFeatureDual__copy p {
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 1.54545455;
  letter-spacing: -0.00681818em;
}
@media all and (max-width: 47.9375em) {
  .testimonialFeatureDual__copy {
    width: 90%;
    margin: 0 auto;
  }
}
@media all and (min-width: 48em) {
  .testimonialFeatureDual__copy {
    padding-left: 0;
    padding-right: 150px;
  }
  .switched .testimonialFeatureDual__copy {
    margin-left: auto;
    padding-left: 150px;
    padding-right: 0;
  }
}
.testimonialFeatureDual__testimonial-imageCol {
  width: 100%;
  max-width: 285px;
  padding-left: 0;
  padding-right: 0;
}
.testimonialFeatureDual__testimonial-imageCol:before {
  width: 58px;
  height: 40px;
}
.testimonialFeatureDual__testimonial-imageCol:after {
  background-size: 21px 17px;
  top: 8px;
  left: 12px;
}
.testimonialFeatureDual__testimonial-figure:before {
  content: '';
  display: block;
  width: 100%;
  padding-top: 66.66666667%;
}
.testimonialFeatureDual__testimonial-content {
  position: relative;
  width: auto;
  padding: 1.25rem 0;
  margin-top: 0;
}
.testimonialFeatureDual__testimonial-heading {
  font-size: 22px;
  font-size: 1.375rem;
  margin-top: 0.625rem;
  position: relative;
}
.testimonialFeatureDual__testimonial-heading:before {
  content: '"';
  display: block;
  position: absolute;
  right: 100%;
  margin-right: 0.28125rem;
  top: 0;
}
.testimonialFeatureDual__testimonial-heading:after {
  content: '"';
  display: inline;
}
.testimonialFeatureDual__testimonial-signature {
  max-width: 170px;
}
.testimonialFeatureDual__testimonial-byline {
  font-size: 14px;
  font-size: 0.875rem;
}
.testimonialFeatureDual__attribute {
  font-family: "SignatureCollectionItalic";
  font-size: 60px;
  font-size: 3.75rem;
  line-height: 1.13333333;
  letter-spacing: -0.00666667em;
  color: #A4664E;
}
.hero {
  position: relative;
  background: #0A0203;
  margin-top: 56px;
}
@media all and (min-width: 48em) {
  .hero {
    margin-top: 72px;
  }
}
.hero__media-container {
  position: relative;
}
.hero__figure {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  overflow: hidden;
}
.hero__figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
.no-objectfit .hero__figure img {
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.hero__image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}
.hero__video {
  position: absolute;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}
@media all and (min-width: 48em) {
  .hero__video {
    top: 50%;
  }
}
.hero__title-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5rem 0;
  z-index: 1;
}
@media all and (min-width: 48em) {
  .hero__title-container {
    padding: 150px 0;
  }
}
@media all and (min-width: 62em) {
  .hero__title-container {
    padding: 200px 0;
  }
}
.hero__links {
  display: flex;
  align-items: center;
}
.hero__link {
  color: #fff;
  background-color: #252325;
  padding: 0.3125rem 0.5625rem;
  margin-top: 0.625rem;
  text-decoration: none;
}
.hero__link-arrow {
  width: 35px;
  height: 35px;
  background: #0266B2;
  margin-left: 0.5625rem;
  margin-top: 0.625rem;
  text-decoration: none;
  cursor: pointer;
}
.hero__link-arrow:after {
  content: "";
  display: inline-block;
  margin-left: 0.5625rem;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 9px solid #fff;
}
.hero__link-arrow:after {
  margin-top: 12px;
}
.hero__heading {
  font-size: 38px;
  font-size: 2.375rem;
  line-height: 1.10526316;
  letter-spacing: -0.00526316em;
  color: #fff;
  padding: 1.25rem 2.25rem;
  text-transform: uppercase;
  text-align: center;
  margin-top: 0;
  width: 100%;
}
.hero__heading span.bg-text__outer {
  padding: 0.625rem;
  background: #0A0203;
}
.hero__heading span > * {
  display: inherit;
}
@media all and (min-width: 48em) {
  .hero__heading {
    font-size: 60px;
    font-size: 3.75rem;
    line-height: 1.13333333;
    letter-spacing: -0.00666667em;
  }
}
.hero__description-container {
  color: #fff;
  position: relative;
  padding: 2.5rem 0;
  z-index: 1;
}
.hero__description-container:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(164, 102, 78, 0.9);
}
@media all and (min-width: 48em) {
  .hero__description-container:before {
    width: 75%;
    clip-path: polygon(0 0, 0 100%, calc(100% - 75px) 100%, 100% 0);
  }
}
@media all and (min-width: 48em) {
  .hero__description-container {
    transform: translateY(-2.5rem);
  }
}
.hero__subheading {
  font-size: 38px;
  font-size: 2.375rem;
  line-height: 1.10526316;
  letter-spacing: -0.00526316em;
  position: relative;
}
@media all and (max-width: 47.9375em) {
  .hero__subheading {
    margin-top: 0;
  }
}
.hero__desc {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.55555556;
  letter-spacing: -0.00722222em;
  font-weight: 200;
  padding: 0;
  position: relative;
  margin-top: 1.25rem;
}
@media all and (min-width: 48em) {
  .hero__desc {
    font-size: 22px;
    font-size: 1.375rem;
    line-height: 1.54545455;
    letter-spacing: -0.00681818em;
    max-width: calc((100% / 12) * 8);
  }
}
.hero__lightbox {
  background-color: rgba(0, 0, 0, 0.8);
  overflow: scroll;
  position: fixed;
  display: none;
  z-index: 1;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}
.hero__lightbox-container {
  position: relative;
  max-width: 960px;
  margin: 7% auto;
  display: block;
  padding: 0 3%;
  height: auto;
  z-index: 10;
}
@media screen and (max-width: 768px) {
  .hero__lightbox-container {
    margin-top: 10%;
  }
}
@media screen and (max-width: 414px) {
  .hero__lightbox-container {
    margin-top: 13%;
  }
}
.hero__lightbox-content {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.7);
}
.hero__lightbox-close {
  text-transform: uppercase;
  background: transparent;
  position: absolute;
  font-weight: 300;
  font-size: 12px;
  display: block;
  border: none;
  color: white;
  top: -22px;
  right: 3%;
}
.hero__video-container {
  padding-bottom: 56.25%;
  position: relative;
  padding-top: 30px;
  overflow: hidden;
  height: 0;
}
.hero__video-container iframe,
.hero__video-container object,
.hero__video-container embed {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
.homeHero {
  position: relative;
  background: #0A0203;
  margin-top: 56px;
}
@media all and (min-width: 48em) {
  .homeHero {
    margin-top: 0;
  }
}
.homeHero__figure {
  margin: 0;
}
.homeHero__media-container {
  position: relative;
  height: calc(100vh - 56px);
}
@media all and (min-width: 48em) {
  .homeHero__media-container {
    height: auto;
  }
  .homeHero__media-container:before {
    content: "";
    position: relative;
    display: block;
    padding-top: 46.66666667%;
  }
}
.homeHero__image {
  width: 100%;
}
.homeHero__video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}
.homeHero__title-container {
  width: 92%;
  margin-left: auto;
  margin-right: auto;
  /* Mixins */
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
}
@media all and (max-width: 47.9375em) {
  .homeHero__title-container {
    width: 90%;
  }
}
@media all and (min-width: 62em) {
  .homeHero__title-container {
    max-width: 1150px;
  }
}
.homeHero__title-container:before,
.homeHero__title-container:after {
  content: "\0020";
  height: 0;
  display: block;
  overflow: hidden;
}
.homeHero__title-container:after {
  clear: both;
}
.homeHero__links {
  display: flex;
  align-items: center;
}
.homeHero__link {
  color: #fff;
  background-color: #252325;
  padding: 0.3125rem 0.5625rem;
  margin-top: 0.625rem;
  text-decoration: none;
  cursor: pointer;
}
.homeHero__link-arrow {
  width: 35px;
  height: 35px;
  background: #0266B2;
  margin-left: 0.5625rem;
  margin-top: 0.625rem;
  text-decoration: none;
  cursor: pointer;
}
.homeHero__link-arrow:after {
  content: "";
  display: inline-block;
  margin-left: 0.5625rem;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 9px solid #fff;
}
.homeHero__link-arrow:after {
  margin-top: 12px;
}
.homeHero__heading {
  font-size: 38px;
  font-size: 2.375rem;
  line-height: 1.10526316;
  letter-spacing: -0.00526316em;
  color: #fff;
  padding: 1.25rem 2.25rem;
  text-transform: uppercase;
  text-align: center;
}
.homeHero__heading span {
  background: #0A0203;
}
.homeHero__heading span.bg-text__outer {
  padding: 0.625rem;
}
.homeHero__heading span > * {
  display: inherit;
}
@media all and (min-width: 48em) {
  .homeHero__heading {
    font-size: 48px;
    font-size: 3rem;
    line-height: 1.04166667;
    letter-spacing: -0.00583333em;
    max-width: calc((100% / 12) * 7);
  }
}
@media all and (min-width: 62em) {
  .homeHero__heading {
    font-size: 60px;
    font-size: 3.75rem;
    line-height: 1.13333333;
    letter-spacing: -0.00666667em;
  }
}
.homeHero__description-container,
.contact__color-slash {
  color: #fff;
  position: relative;
  padding: 2.5rem 0;
  z-index: 1;
}
.homeHero__description-container:before,
.contact__color-slash:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(164, 102, 78, 0.9);
}
@media all and (min-width: 48em) {
  .homeHero__description-container:before,
  .contact__color-slash:before {
    width: 75%;
    clip-path: polygon(0 0, 0 100%, calc(100% - 75px) 100%, 100% 0);
  }
}
@media all and (min-width: 62em) {
  .homeHero__description-container,
  .contact__color-slash {
    transform: translateY(-2.5rem);
  }
}
.homeHero__subheading,
.contact__color-slash h3 {
  position: relative;
}
@media all and (max-width: 47.9375em) {
  .homeHero__subheading,
  .contact__color-slash h3 {
    margin-top: 0;
  }
}
.homeHero__desc,
.contact__color-slash .copy {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.55555556;
  letter-spacing: -0.00722222em;
  font-weight: 200;
  padding: 0;
  position: relative;
  margin-top: 1.25rem;
}
@media all and (min-width: 48em) {
  .homeHero__desc,
  .contact__color-slash .copy {
    font-size: 22px;
    font-size: 1.375rem;
    line-height: 1.54545455;
    letter-spacing: -0.00681818em;
    max-width: calc((100% / 12) * 8);
  }
}
.homeHero__lightbox,
.relatedMediaCard__video-lightbox,
.featuredCard__video-lightbox {
  background-color: rgba(0, 0, 0, 0.8);
  overflow: scroll;
  position: fixed;
  z-index: 4;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}
.homeHero__lightbox:not(.is-revealed),
.relatedMediaCard__video-lightbox:not(.is-revealed),
.featuredCard__video-lightbox:not(.is-revealed) {
  display: none;
}
.homeHero__lightbox-container,
.relatedMediaCard__iframe-container,
.featuredCard__iframe-container {
  position: relative;
  max-width: 960px;
  margin: 0 auto;
  display: block;
  padding: 0 3%;
  height: auto;
  z-index: 10;
  top: 35%;
  transform: translateY(-50%);
}
@media all and (min-width: 48em) {
  .homeHero__lightbox-container,
  .relatedMediaCard__iframe-container,
  .featuredCard__iframe-container {
    margin: 7% auto;
  }
}
.homeHero__lightbox-content {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.7);
}
.homeHero__lightbox-close,
.relatedMediaCard__video-close,
.featuredCard__video-close {
  text-transform: uppercase;
  background: transparent;
  position: absolute;
  font-weight: 300;
  font-size: 12px;
  display: block;
  border: none;
  color: white;
  top: -22px;
  right: 3%;
}
.homeHero__video-container,
.relatedMediaCard__video-container,
.featuredCard__video-container {
  padding-bottom: 56.25%;
  position: relative;
  padding-top: 30px;
  overflow: hidden;
  height: 0;
}
.homeHero__video-container iframe,
.homeHero__video-container object,
.homeHero__video-container embed,
.relatedMediaCard__video-container iframe,
.relatedMediaCard__video-container object,
.relatedMediaCard__video-container embed,
.featuredCard__video-container iframe,
.featuredCard__video-container object,
.featuredCard__video-container embed {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
.banner {
  position: relative;
  background: #0A0203;
}
.banner__figure {
  position: relative;
  margin: 0;
}
.banner__figure:before {
  content: '';
  display: block;
  width: 100%;
  padding-top: 100%;
}
@media all and (min-width: 48em) {
  .banner__figure:before {
    padding-top: 37.43055556%;
  }
}
.banner__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
}
.banner__content {
  width: 92%;
  margin-left: auto;
  margin-right: auto;
  /* Mixins */
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
@media all and (max-width: 47.9375em) {
  .banner__content {
    width: 90%;
  }
}
@media all and (min-width: 62em) {
  .banner__content {
    max-width: 1150px;
  }
}
.banner__content:before,
.banner__content:after {
  content: "\0020";
  height: 0;
  display: block;
  overflow: hidden;
}
.banner__content:after {
  clear: both;
}
@media all and (min-width: 62em) {
  .banner__content {
    padding-top: 132px;
    padding-bottom: 3.75rem;
  }
}
.banner__heading {
  font-size: 38px;
  font-size: 2.375rem;
  line-height: 1.10526316;
  letter-spacing: -0.00526316em;
  color: #fff;
  padding: 1.25rem 2.25rem;
  text-transform: uppercase;
  text-align: center;
}
.banner__heading span.bg-text__outer {
  background: #0A0203;
  padding: 0.625rem 0.5625rem;
}
.banner__heading span * {
  display: inherit;
}
@media all and (min-width: 48em) {
  .banner__heading {
    font-size: 60px;
    font-size: 3.75rem;
    line-height: 1.13333333;
    letter-spacing: -0.00666667em;
    max-width: calc((100% / 12) * 7);
  }
}
.banner__desc {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.55555556;
  letter-spacing: -0.00722222em;
  padding: 0;
  position: relative;
  text-align: center;
}
.banner__desc > span {
  display: inline;
  color: #fff;
}
.banner__desc > span.bg-text__outer {
  background-color: #252325;
  box-shadow: 0 0 0 10px #252325;
}
@media all and (min-width: 48em) {
  .banner__desc {
    font-size: 22px;
    font-size: 1.375rem;
    line-height: 1.54545455;
    letter-spacing: -0.00681818em;
    max-width: calc((100% / 12) * 6);
  }
}
.carousel {
  overflow: hidden;
  color: #fff;
}
@media all and (max-width: 47.9375em) {
  .carousel {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
}
.carousel__flex {
  display: flex;
  flex-direction: column-reverse;
}
@media all and (min-width: 48em) {
  .carousel__flex {
    flex-direction: row;
  }
}
.carousel__intro {
  position: relative;
  z-index: 2;
}
@media all and (min-width: 48em) {
  .carousel__intro {
    width: 33.33333%;
    padding-right: 1.125rem;
    background: #0A0203;
  }
}
.carousel__intro:before {
  content: '';
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: #0A0203;
  transform: translateX(-100%);
}
@media all and (min-width: 48em) {
  .carousel__intro:before {
    display: block;
  }
}
.carousel__intro-title {
  font-family: "HelveticaNeueLTStd-Cn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  text-transform: uppercase;
}
@media all and (min-width: 48em) {
  .carousel__intro-title {
    font-size: 48px;
    font-size: 3rem;
    line-height: 1.04166667;
  }
}
.carousel__intro-copy {
  position: relative;
  margin-top: 1.25rem;
  padding-top: 1.25rem;
}
.carousel__intro-copy:before {
  content: '';
  position: absolute;
  width: 60px;
  height: 4px;
  background: #A4664E;
  top: 0;
  left: 0;
  transform: translateY(-50%);
}
.carousel__intro-copy p:first-of-type {
  margin-top: 0;
}
.carousel__inner {
  white-space: nowrap;
  margin-top: 2.5rem;
  position: relative;
  outline: 0;
}
@media all and (min-width: 48em) {
  .carousel__inner {
    width: 66.66667%;
    margin-top: 0;
  }
}
.carousel__slides {
  --slidesOffset: 0px;
  list-style: none outside none;
  margin: 0;
  padding: 0;
  display: flex;
  will-change: transform;
  transform: translate3d(var(--slidesOffset), 0, 0);
  transition: transform 0.35s ease-out;
  white-space: nowrap;
}
.carousel__slides.is-dragging {
  transition-duration: 0.05s;
  transition-easing: linear;
}
.carousel__slide {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  top: 0;
  left: 0;
  margin: 0 0.5625rem;
  white-space: normal;
  overflow: hidden;
}
.carousel__slide:after {
  display: none !important;
}
.carousel__slide:not(.is-current):before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(10, 2, 3, 0.5);
  z-index: 2;
}
.carousel__controller {
  margin-top: 1.25rem;
}
.carousel__button,
.carousel__button--next,
.carousel__button--prev {
  display: inline-block;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  overflow: visible;
  font-size: 100%;
  font-family: inherit;
  line-height: normal;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  -webkit-appearance: none;
  background-clip: padding-box;
  vertical-align: middle;
  background: transparent;
  position: relative;
  display: flex;
  width: 45px;
  height: 45px;
  justify-content: center;
  align-items: center;
  background: #0A0203;
  color: #fff;
  float: left;
  border: 1px solid #a9a6a7;
}
@media all and (min-width: 48em) {
  .carousel__button,
  .carousel__button--next,
  .carousel__button--prev {
    width: 35px;
    height: 35px;
  }
}
.carousel__button--next:after,
.carousel__button--prev:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  display: block;
}
.carousel__button--next {
  border-left: none;
}
.carousel__button--next:after {
  border-left: 10px solid #fff;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
}
.carousel__button--next:hover:after,
.carousel__button--next:active:after,
.carousel__button--next:focus:after {
  border-left-color: #fff;
}
.carousel__button--prev:after {
  border-right: 10px solid #fff;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
}
.carousel__button--prev:hover:after,
.carousel__button--prev:active:after,
.carousel__button--prev:focus:after {
  border-right-color: #fff;
}
.carousel__slide-figure {
  position: relative;
  margin-top: 0;
}
.carousel__slide-figure:after {
  content: '';
  position: relative;
  padding-top: 139.79328165%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
}
.carousel__slide-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.carousel__slide-info {
  flex: 1;
  width: 100%;
  padding: 0 20px 20px 20px;
  background: linear-gradient(90deg, #414141 0%, #080808 100%);
}
@media all and (min-width: 48em) {
  .carousel__slide-info {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(calc( 100% - ( 1rem * ( 50 / 16 ) ) ));
    transition: transform 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  }
}
.carousel__slide:hover .carousel__slide-info {
  transform: none;
}
.carousel__slide-title {
  font-family: "HelveticaNeueLTStd-BdCn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 1;
  padding: 0.875rem 0;
}
.carousel__slide-copy {
  margin-top: 0;
}
.carousel__slide-link {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 20;
}
.contact__form-block {
  color: #fff;
  padding-bottom: 5rem;
}
.contact__color-slash {
  transform: none;
}
.contact__color-slash:before {
  left: -200px;
  width: 125%;
}
@media all and (max-width: 61.9375em) {
  .contact__color-slash:before {
    left: -40px;
  }
}
.contact__form-selection-wrapper {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 8px -2px #333;
  color: #0A0203;
  overflow: hidden;
  padding: 0.6rem;
  position: relative;
  max-width: 300px;
}
.contact__form-selection-wrapper:hover {
  background: #ffffff;
}
.contact__form-selection-wrapper:before {
  content: "";
  display: inline-block;
  margin-top: 0.5625rem;
  margin-left: 0.5625rem;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 9px solid #fff;
  position: absolute;
  right: 18px;
  top: 34%;
  z-index: 3;
  transform: translateY(-50%);
}
.contact__form-selection-wrapper:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: -1px;
  right: -10px;
  width: 60px;
  background-color: #0092C8;
  transform: skewX(-15deg);
}
.contact__form {
  padding: 2.5rem 9.5rem 2.5rem 0;
}
.contact__form h2 {
  text-transform: uppercase;
}
.contact__form hr {
  background: #A4664E;
  border-color: #A4664E;
  height: 0.3rem;
  margin-left: 0;
  margin-top: 1.5rem;
  width: 100px;
}
.contact__form input,
.contact__form textarea {
  border-radius: 0;
}
.contact__form input[type="text"],
.contact__form input[type="email"],
.contact__form input[type="tel"],
.contact__form input[type="url"],
.contact__form textarea {
  background: rgba(255, 255, 255, 0.8);
  border: solid 2px #fff;
}
.contact__form input[type="text"]:not(:placeholder-shown),
.contact__form input[type="email"]:not(:placeholder-shown),
.contact__form input[type="tel"]:not(:placeholder-shown),
.contact__form input[type="url"]:not(:placeholder-shown),
.contact__form textarea:not(:placeholder-shown) {
  background: #ffffff;
  border-color: #A4664E;
}
.contact__form input[type="text"]:hover,
.contact__form input[type="email"]:hover,
.contact__form input[type="tel"]:hover,
.contact__form input[type="url"]:hover,
.contact__form textarea:hover,
.contact__form input[type="text"]:focus,
.contact__form input[type="email"]:focus,
.contact__form input[type="tel"]:focus,
.contact__form input[type="url"]:focus,
.contact__form textarea:focus {
  background: #ffffff;
  border-color: #0266B2;
}
.contact__form button {
  border: none;
  float: right;
}
.findrep {
  position: relative;
  background: #0A0203;
}
.findrep__header {
  position: relative;
}
.findrep__figure {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  overflow: hidden;
}
.findrep__figure img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
.no-objectfit .findrep__figure img {
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.findrep__bottom-figure {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  margin-top: 0;
}
.findrep__image {
  width: 100%;
}
.findrep__image-secondary {
  width: 100%;
  background-color: linear-gradient(90deg, #080808 0%, #414141 100%);
  -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.4)), to(rgba(0, 0, 0, 0)));
  mask-image: linear-gradient(to left, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
}
.findrep__title-container {
  padding: 7.5rem 0 5rem 0;
  position: relative;
}
@media all and (min-width: 48em) {
  .findrep__title-container {
    padding-top: 330px;
    padding-bottom: 170px;
  }
}
.findrep__heading {
  font-size: 38px;
  font-size: 2.375rem;
  line-height: 1.10526316;
  letter-spacing: -0.00526316em;
  color: #fff;
  padding: 0 2.25rem;
  text-transform: uppercase;
  text-align: left;
}
.findrep__heading span.bg-text__outer {
  background: #0A0203;
  padding: 0.625rem;
}
.findrep__heading span > * {
  display: inherit;
}
@media all and (min-width: 48em) {
  .findrep__heading {
    font-size: 48px;
    font-size: 3rem;
    line-height: 1.04166667;
    letter-spacing: -0.00583333em;
  }
}
@media all and (min-width: 62em) {
  .findrep__heading {
    max-width: calc((100% / 12) * 9);
  }
}
.findrep__heading-description {
  color: #fff;
  padding: 0 2.25rem;
  text-align: left;
  margin-top: 1.25rem;
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 1.54545455;
  letter-spacing: -0.00681818em;
  line-height: 1.85;
}
.findrep__heading-description span.bg-text__outer {
  background: linear-gradient(90deg, #080808 0%, #414141 100%);
  padding: 0.625rem;
}
.findrep__heading-description span * {
  display: inherit;
}
@media all and (min-width: 62em) {
  .findrep__heading-description {
    max-width: calc((100% / 12) * 9);
  }
}
.findrep__content {
  position: relative;
}
.findrep__placeHolder {
  display: flex;
  padding: 1.25rem 1.125rem;
}
.findrep__errorText {
  color: #fff;
  position: relative;
  display: none;
}
.findrep__description-container {
  color: #fff;
  position: relative;
  padding: 2.5rem 0;
  transform: translateY(-48px);
  z-index: 1;
}
@media all and (min-width: 48em) {
  .findrep__description-container {
    width: 70vw;
  }
}
.findrep__description-container:before {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: rgba(164, 102, 78, 0.9);
}
@media all and (min-width: 48em) {
  .findrep__description-container:before {
    transform: skew(-15deg);
    margin-left: calc(50% - 50vw);
  }
}
.findrep__subheading {
  font-size: 38px;
  font-size: 2.375rem;
  line-height: 1.10526316;
  letter-spacing: -0.00526316em;
  position: relative;
}
.findrep__desc {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.55555556;
  letter-spacing: -0.00722222em;
  font-weight: 200;
  padding: 0;
  position: relative;
  margin-top: 1.25rem;
}
@media all and (min-width: 48em) {
  .findrep__desc {
    font-size: 22px;
    font-size: 1.375rem;
    line-height: 1.54545455;
    letter-spacing: -0.00681818em;
    max-width: calc((100% / 12) * 8);
  }
}
.findrep__result-title {
  color: #fff;
  padding-left: 0;
}
.findrep__form {
  position: relative;
}
.findrep__form input[type=text] {
  border: none;
  border-radius: unset;
  float: left;
  width: 80%;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 0px 8px -2px #333;
  max-width: 375px;
  color: #0A0203;
  overflow: hidden;
  padding: 0.625rem 0.5625rem;
  padding-right: 75px;
}
.findrep__form input[type=text]:hover {
  background: #ffffff;
}
.findrep__form input[type=text]:before {
  content: "";
  display: inline-block;
  margin-top: 0.5625rem;
  margin-left: 0.5625rem;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 9px solid #fff;
  position: absolute;
  right: 18px;
  top: 34%;
  z-index: 3;
  transform: translateY(-50%);
}
.findrep__form input[type=text]:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -10px;
  width: 60px;
  background-color: #0092C8;
  transform: skewX(-15deg);
}
.findrep__label {
  display: flex;
}
.findrep__button-search {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.14285714;
  position: relative;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  margin-right: 0.5625rem;
  margin-left: -75px;
  margin-top: -1px;
  margin-bottom: -1px;
  z-index: 2;
  float: left;
  background: transparent;
  font-size: 17px;
  border: none;
}
.findrep__button-search-icon {
  font-size: 25px;
  position: absolute;
  bottom: 100%;
  left: 4px;
  color: #0092C8;
}
.findrep__button-search:after {
  width: 100%;
  height: 100%;
}
.findrep__button-search:hover,
.findrep__button-search:focus {
  background: transparent;
}
.findrep-searchTerm::placeholder {
  color: #0A0203;
  font-family: "HelveticaNeueLTStd-Cn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
}
.findrep__form::after {
  content: "";
  clear: both;
  display: table;
}
.findrep__results {
  padding-bottom: 2.5rem;
  position: relative;
  z-index: 1;
}
.findrep__results-container {
  color: #fff;
  position: absolute;
  padding: 2.5rem 0;
  transform: translateY(-20%);
  z-index: 1;
}
@media all and (min-width: 48em) {
  .findrep__results-container {
    width: 70vw;
  }
}
.findrep__results-container:before {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: rgba(164, 102, 78, 0.9);
}
@media all and (min-width: 48em) {
  .findrep__results-container:before {
    transform: skew(-15deg);
    margin-left: calc(50% - 50vw);
  }
}
.findRep__list {
  padding-left: 0;
  list-style: none outside none;
}
.findRep__list li label {
  display: flex;
}
.findRep__list li label {
  display: flex;
}
.findRep__listItem {
  display: flex;
}
.findRep__logo {
  width: 200px;
  height: auto;
  padding-bottom: 0.5625rem;
  margin: 0 1.125rem 0 0;
}
.findRep__logo img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: top left;
}
.findRep__logo img .no-objectfit {
  height: auto;
}
@media all and (min-width: 48em) {
  .findRep__logo {
    height: 300px;
    width: 100%;
    padding-bottom: 0;
  }
}
.findRep__repInfo {
  color: #fff;
}
.findRep__repInfo .findRep__repInfo-attributes {
  display: table;
  border-collapse: collapse;
}
.findRep__repInfo .findRep__repInfo-attributes .findRep__repInfo-attribute {
  display: table-row;
}
.findRep__repInfo .findRep__repInfo-attributes .findRep__repInfo-attribute .findRep__repInfo-label,
.findRep__repInfo .findRep__repInfo-attributes .findRep__repInfo-attribute .findRep__repInfo-value {
  display: table-cell;
}
.findRep__repInfo .findRep__repInfo-attributes .findRep__repInfo-attribute .findRep__repInfo-value {
  padding-left: 0.5625rem;
}
.findRep__name {
  text-transform: uppercase;
  font-weight: 800;
}
.select,
.contact__form-selection {
  display: block;
  outline: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  max-width: 100%;
  cursor: pointer;
}
.custom-file-upload-hidden {
  display: none;
  visibility: hidden;
  position: absolute;
  left: -9999px;
}
.custom-file-upload {
  display: inline;
  width: auto;
  font-size: 16px;
  margin-top: 30px;
}
.custom-file-upload label {
  display: block;
  margin-bottom: 5px;
}
.file-upload-wrapper {
  position: relative;
  margin-bottom: 5px;
}
.file-upload-wrapper button {
  margin-bottom: 15px;
}
.file-upload-wrapper:last-child button {
  margin-bottom: 0px;
}
.file-upload-input {
  color: #0A0203;
  font-size: 16px;
  padding: 11px 17px;
  border: none;
  background: rgba(255, 255, 255, 0.8);
  float: left;
  /* IE 9 Fix */
}
.file-upload-input:hover,
.file-upload-input:focus {
  background: #ffffff;
  outline: none;
}
/**
 * Shared
 */
.foundation__main-content {
  min-height: calc(100vh - 396px);
  background-color: #0A0203;
}
.globalHeader__main {
  position: fixed;
  background: linear-gradient(to bottom, #0A0203 10%, transparent);
  border-bottom: 1px solid rgba(128, 130, 133, 0.5);
  top: 0;
  width: 100%;
  z-index: 5;
  min-height: 56px;
  will-change: background;
  transition: background 1000ms ease;
}
.globalHeader__main:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #0A0203;
  opacity: 1;
  will-change: opacity;
  transition: opacity 500ms ease;
  z-index: 0;
}
@media all and (min-width: 48em) {
  .globalHeader__main {
    min-height: 72px;
  }
}
.globalHeader__main.is-transparent:before {
  opacity: 0;
}
.globalHeader__container {
  position: relative;
  display: flex;
  align-items: center;
  height: 56px;
}
@media all and (min-width: 48em) {
  .globalHeader__container {
    height: 72px;
  }
}
.globalHeader__logo-container {
  align-self: center;
  margin-top: 0.625rem;
}
.globalHeader__logo {
  display: block;
  width: 148px;
  line-height: 1;
}
@media all and (min-width: 48em) {
  .globalHeader__logo {
    width: 243px;
  }
}
.globalFooter__main {
  background: #0A0203;
  color: #fff;
  margin-top: 50px;
  border-width: 1px 0 1px 0;
  border-color: rgba(128, 130, 133, 0.5);
  border-style: solid;
}
@media all and (min-width: 48em) {
  .globalFooter__content {
    display: flex;
  }
}
@media all and (min-width: 48em) {
  .globalFooter__section-block {
    padding-top: 2.5rem;
    padding-bottom: 3.75rem;
    padding-right: 1.125rem;
  }
  .globalFooter__section-block:first-child {
    flex-grow: 0;
    flex-shrink: 0;
    border-right: 1px solid rgba(128, 130, 133, 0.5);
  }
  .globalFooter__section-block--right {
    flex-grow: 1;
    flex-shrink: 1;
  }
}
.globalFooter__contact-info {
  text-align: center;
}
@media all and (min-width: 48em) {
  .globalFooter__contact-info {
    text-align: left;
  }
}
.globalFooter__contact-numbers {
  display: flex;
  flex-flow: column wrap;
}
.globalFooter__copyright {
  padding: 2.5rem 0;
  border-top: 1px solid rgba(128, 130, 133, 0.5);
  text-align: center;
}
@media all and (min-width: 48em) {
  .globalFooter__copyright {
    text-align: left;
  }
}
.globalFooter__text {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.16666667;
  color: #fff;
  margin-top: 0;
}
.globalFooter__footer-navigation {
  margin: 1.25rem 0;
}
@media all and (min-width: 48em) {
  .globalFooter__footer-navigation {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0 2.25rem;
  }
}
.globalFooter__texas-seal {
  margin: 0.625rem auto 0 auto;
  display: block;
  height: 91px;
  width: 91px;
}
@media all and (min-width: 48em) {
  .globalFooter__texas-seal {
    margin: 1.25rem 2.25rem 0 0;
    flex-grow: 0;
    flex-shrink: 0;
  }
}
@media all and (min-width: 48em) {
  .footerNavigation__nav {
    flex-grow: 1;
    flex-shrink: 1;
  }
}
.globalFooter__logo-container {
  margin-top: 1.25rem;
  text-align: center;
}
@media all and (min-width: 48em) {
  .globalFooter__logo-container {
    text-align: left;
  }
}
.globalFooter__social-link {
  margin: 0.625rem 0.5625rem 0 0.5625rem;
  display: inline-block;
  color: #fff;
}
.globalFooter__social-link:hover {
  color: #0092C8;
}
@media all and (min-width: 48em) {
  .globalFooter__social-link {
    display: block;
    float: left;
    margin-right: 0.5625rem;
    margin-left: 0;
  }
}
.globalFooter__social-link svg {
  width: 28px;
  height: 28px;
}
.primaryNavigation__menu {
  display: none;
}
.primaryNavigation__menu.is-expanded {
  display: block;
}
@media all and (max-width: 77.4375em) {
  .primaryNavigation__menu {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    height: 90vh;
    background-color: #0A0203;
    overflow: scroll;
  }
}
@media all and (min-width: 77.5em) {
  .primaryNavigation__menu {
    display: flex;
    align-items: center;
    margin-left: 1.125rem;
  }
}
.primaryNavigation__title {
  margin-left: auto;
  color: #0092C8;
  font-size: 20px;
  line-height: 1;
}
.primaryNavigation__title.is-expanded {
  color: #fff;
}
@media all and (min-width: 77.5em) {
  .primaryNavigation__title {
    display: none;
  }
}
.primaryNavigation__close {
  display: none;
}
.is-expanded .primaryNavigation__close {
  display: block;
}
.is-expanded .primaryNavigation__open {
  display: none;
}
@media all and (min-width: 77.5em) {
  .primaryNavigation__list-wrap {
    display: flex;
    align-items: center;
    position: relative;
  }
}
.primaryNavigation__list {
  position: relative;
  padding-left: 0;
  margin-top: 0;
}
@media all and (min-width: 77.5em) {
  .primaryNavigation__list {
    display: flex;
    white-space: nowrap;
    margin-right: 0.5625rem;
    padding-left: 0;
  }
  .primaryNavigation__list li {
    display: block;
  }
  .primaryNavigation__list a {
    display: block;
  }
  .primaryNavigation__list:before {
    content: '';
    position: absolute;
    right: -1px;
    top: 0;
    height: 100%;
    width: 1px;
    background: rgba(128, 130, 133, 0.5);
  }
}
@media all and (min-width: 77.5em) and all and (min-width: 30em) {
  .primaryNavigation__list:before,
  .primaryNavigation__list:after {
    content: "\0020";
    height: 0;
    display: block;
    overflow: hidden;
  }
  .primaryNavigation__list:after {
    clear: both;
  }
}
@media all and (min-width: 77.5em) and all and (min-width: 30em) {
  .primaryNavigation__list li {
    float: left;
  }
}
.primaryNavigation__list-item {
  position: relative;
  transition: all 0.2s ease-in-out;
}
@media all and (max-width: 77.4375em) {
  .primaryNavigation__list-item {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    padding-left: 1.125rem;
    border-bottom: 1px solid rgba(128, 130, 133, 0.5);
  }
}
@media all and (min-width: 77.5em) {
  .primaryNavigation__list-item:focus-within {
    background-color: #3A3A3A;
  }
  .primaryNavigation__list-item:focus-within .primaryNavigation__subnav-list {
    display: block;
  }
}
@media all and (min-width: 77.5em) {
  .primaryNavigation__list-item:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 1px;
    background: rgba(128, 130, 133, 0.5);
  }
  .primaryNavigation__list-item:hover,
  .primaryNavigation__list-item:focus {
    background-color: #3A3A3A;
  }
  .primaryNavigation__list-item:hover .primaryNavigation__subnav-list,
  .primaryNavigation__list-item:focus .primaryNavigation__subnav-list {
    display: block;
  }
}
@media all and (min-width: 77.5em) {
  .primaryNavigation__list-item-withChild:after {
    content: "";
    display: inline-block;
    margin-top: 0.5625rem;
    margin-left: 0.5625rem;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 9px solid #fff;
  }
  .primaryNavigation__list-item-withChild:after {
    position: absolute !important;
    top: 27%;
    right: 15px;
  }
  .primaryNavigation__list-item-withChild:hover:after,
  .primaryNavigation__list-item-withChild:focus:after {
    content: "";
    display: inline-block;
    margin-top: 0.5625rem;
    margin-left: 0.5625rem;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 9px solid #fff;
    border-top: 0;
  }
  .primaryNavigation__list-item-withChild:hover:after,
  .primaryNavigation__list-item-withChild:focus:after {
    position: absolute !important;
    top: 27%;
    right: 15px;
  }
}
@media all and (min-width: 77.5em) and all and (max-width: 77.4375em) {
  .primaryNavigation__list-item-withChild:hover:after,
  .primaryNavigation__list-item-withChild:focus:after {
    top: 10px;
  }
}
.primaryNavigation__link {
  font-family: "HelveticaNeueLTStd-BdCn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  margin: 0.84375rem 1.125rem;
}
@media all and (max-width: 77.4375em) {
  .primaryNavigation__link {
    flex: 1;
    line-height: 1;
  }
}
@media all and (min-width: 77.5em) {
  .primaryNavigation__link {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.625;
    margin: 1.25rem 1.25rem;
    border-bottom: 5px solid transparent;
  }
  .primaryNavigation__list-item-withChild .primaryNavigation__link {
    margin: 1.25rem 2.05rem 1.25rem 1.25rem;
  }
}
.primaryNavigation__link:hover,
.primaryNavigation__link:focus {
  color: #fff;
  border-bottom-color: #A4664E;
}
.primaryNavigation__subnav-list {
  display: none;
  min-width: 100%;
  position: absolute;
  background-color: #3A3A3A;
  padding-left: 0;
  margin-top: 0;
  border: 1px solid rgba(128, 130, 133, 0.5);
}
@media all and (max-width: 77.4375em) {
  .primaryNavigation__subnav-list {
    position: relative;
    border: none;
    list-style: none;
    margin-left: -1.125rem;
    padding-top: 0;
    border-top: 1px solid #a9a6a7;
    margin-top: 0.625rem;
    margin-bottom: -10px;
  }
}
.primaryNavigation__subnav-list.is-revealed {
  display: block;
}
.primaryNavigation__subnav-list-item {
  width: 100%;
}
@media all and (max-width: 77.4375em) {
  .primaryNavigation__subnav-list-item {
    padding: 1.125rem 0.625rem;
  }
}
.primaryNavigation__subnav-link {
  color: #fff;
  text-decoration: none;
  padding: 0.625rem 2.25rem;
  display: block;
}
.primaryNavigation__subnav-link:hover,
.primaryNavigation__subnav-link:focus {
  color: #0092C8;
}
.primaryNavigation__subnav-list-item .primaryNavigation__subnav-link:hover,
.primaryNavigation__subnav-list-item .primaryNavigation__subnav-link:focus {
  background-color: #252325;
}
.primaryNavigation__smallNav-list {
  padding-left: 0;
  margin-top: 0;
}
@media all and (max-width: 77.4375em) {
  .primaryNavigation__smallNav-list {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.55555556;
    letter-spacing: -0.00722222em;
    padding-left: 25px;
    padding-bottom: 25px;
  }
}
@media all and (min-width: 77.5em) {
  .primaryNavigation__smallNav-list {
    display: flex;
  }
  .primaryNavigation__smallNav-list li {
    display: block;
  }
  .primaryNavigation__smallNav-list a {
    display: block;
  }
}
@media all and (min-width: 77.5em) and all and (min-width: 30em) {
  .primaryNavigation__smallNav-list:before,
  .primaryNavigation__smallNav-list:after {
    content: "\0020";
    height: 0;
    display: block;
    overflow: hidden;
  }
  .primaryNavigation__smallNav-list:after {
    clear: both;
  }
}
@media all and (min-width: 77.5em) and all and (min-width: 30em) {
  .primaryNavigation__smallNav-list li {
    float: left;
  }
}
@media all and (max-width: 77.4375em) {
  .primaryNavigation__smallNav-list-item {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
}
.primaryNavigation__smallNav-link {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.14285714;
  display: block;
  color: #fff;
  text-decoration: none;
  padding: 0.625rem 0.5625rem;
  transition: all 0.2s ease-in-out;
}
.primaryNavigation__smallNav-link:hover,
.primaryNavigation__smallNav-link:focus {
  color: #0092C8;
  text-decoration: underline;
}
@media all and (max-width: 77.4375em) {
  .primaryNavigation__smallNav-link {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 1.55555556;
    letter-spacing: -0.00722222em;
  }
}
.primaryNavigation__list-item-parent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media all and (min-width: 77.5em) {
  .primaryNavigation__list-item-parent {
    display: inline-block;
  }
}
.primaryNavigation__list-item-button {
  display: inline-block;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  outline: 0;
  border: none;
  overflow: visible;
  font-size: 100%;
  font-family: inherit;
  line-height: normal;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  -webkit-appearance: none;
  background-clip: padding-box;
  vertical-align: middle;
  background: transparent;
  position: relative;
  width: 45px;
  height: 45px;
  color: #fff;
  background: #0A0203;
}
.primaryNavigation__list-item-button:hover,
.primaryNavigation__list-item-button:focus {
  background: #0A0203;
}
.primaryNavigation__list-item-button:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  left: 0;
  top: 50%;
  left: 50%;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 9px solid #fff;
  transform: translate(-50%, -50%);
}
.primaryNavigation__list-item-button.is-revealed:after {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 9px solid #fff;
  border-top: none;
}
@media all and (min-width: 77.5em) {
  .primaryNavigation__list-item-button {
    display: none;
  }
}
.footerNavigation__list {
  padding-left: 0;
  list-style: none outside none;
}
.footerNavigation__list li label {
  display: flex;
}
.footerNavigation__list li label {
  display: flex;
}
@media all and (max-width: 47.9375em) {
  .footerNavigation__list {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.625;
  }
}
@media all and (min-width: 48em) {
  .footerNavigation__list {
    display: flex;
    width: 100%;
  }
}
.footerNavigation__sublist {
  padding-left: 0;
  list-style: none outside none;
}
.footerNavigation__sublist li label {
  display: flex;
}
.footerNavigation__sublist li label {
  display: flex;
}
.footerNavigation__list-item {
  margin-top: 1.25rem;
}
.footerNavigation__list-item:first-of-type {
  margin-top: 0;
}
@media all and (min-width: 48em) {
  .footerNavigation__list-item {
    margin-top: 0;
    padding-right: 2.25rem;
  }
}
.footerNavigation__sublist-item {
  line-height: normal;
}
.footerNavigation__list-link {
  font-family: "HelveticaNeueLTStd-BdCn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  color: #fff;
  font-size: 1rem;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.footerNavigation__list-link:hover,
.footerNavigation__list-link:focus {
  color: #0092C8;
  text-decoration: underline;
}
.footerNavigation__sublist-link,
.footerNavigation__secondary-list-link {
  font-family: "HelveticaNeueLTStd-Cn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.14285714;
  line-height: 1.2;
  letter-spacing: 0.6px;
  font-weight: 200;
  color: #fff;
  text-decoration: none;
  text-transform: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  display: block;
  padding-top: 0.625rem;
}
.footerNavigation__sublist-link:hover,
.footerNavigation__sublist-link:focus,
.footerNavigation__secondary-list-link:hover,
.footerNavigation__secondary-list-link:focus {
  color: #0092C8;
  text-decoration: underline;
}
.footerNavigation__secondary-list {
  list-style: none outside none;
  margin: 1.25rem 0 0 0;
  border-top: 1px solid rgba(128, 130, 133, 0.5);
  padding: 1.25rem 0 0 0;
}
.footerNavigation__secondary-list-item {
  padding-left: 0;
}
.productCard {
  display: flex;
  flex-direction: column;
  background-color: #252325;
  border: 1px solid transparent;
  transition: all 0.4s ease-in-out;
  width: 100%;
}
.productCard:hover,
.productCard:focus {
  background-color: #3A3A3A;
  border: 1px solid #0092C8;
}
.productCard__heading-desc {
  color: #fff;
  text-decoration: none;
  display: block;
  width: 100%;
}
.productCard__heading-desc:hover,
.productCard__heading-desc:focus {
  color: #fff;
}
.productCard__heading {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.55555556;
  letter-spacing: -0.00722222em;
  font-weight: bold;
  cursor: pointer;
}
.productCard__figure {
  height: 160px;
  margin-top: 0;
  cursor: pointer;
}
@media all and (min-width: 48em) {
  .productCard__figure {
    height: 120px;
  }
}
.productCard__image {
  height: 100%;
  width: 100%;
}
.productCard__content {
  padding: 1.25rem 1.125rem 0 1.125rem;
}
.productCard__desc {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.14285714;
  letter-spacing: 0.6px;
  line-height: 18px;
  margin-bottom: 1.25rem;
  padding-bottom: 1.25rem;
  display: flex;
  flex-wrap: wrap;
}
.productCard__link-email,
.productCard__link-specs {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.14285714;
  position: relative;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  margin-top: 0.625rem;
}
.productCard__link-email:hover,
.productCard__link-specs:hover,
.productCard__link-email:focus,
.productCard__link-specs:focus {
  color: #0092C8;
}
.productCard__link-email {
  margin-right: 0.5625rem;
  padding-top: 26px;
}
.productCard__link-email-icon {
  font-size: 25px;
  position: absolute;
  top: 0;
  left: 2px;
  color: #0092C8;
}
.productCard__link-specs {
  margin-right: 0.5625rem;
  padding-top: 26px;
}
.productCard__link-specs-icon {
  font-size: 25px;
  position: absolute;
  top: 0;
  left: 4px;
  color: #0092C8;
}
.productCard__footer {
  margin-top: auto;
  padding: 0 1.125rem 1.25rem 1.125rem;
}
.productCard__details {
  border: 2px solid transparent;
}
.productCard__details:hover,
.productCard__detailsfocus {
  background: #0266B2;
  border: 2px solid #0092C8;
}
.productList {
  display: flex;
  flex-direction: row;
  background: linear-gradient(90deg, #080808 0%, #414141 100%);
  transition: all 0.4s ease-in-out;
  width: 100%;
}
.productList:hover,
.productList:focus {
  background: #3A3A3A;
  border: 1px solid #0092C8;
}
.productList__figure-link {
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding-right: 0.5625rem;
}
@media all and (max-width: 47.9375em) {
  .productList__figure-link {
    display: none;
  }
}
.productList__figure {
  width: 150px;
  height: 96px;
  margin-top: 0;
  cursor: pointer;
}
@media all and (min-width: 48em) {
  .productList__figure {
    width: 230px;
    height: 120px;
  }
}
.productList__image {
  height: 100%;
  width: 100%;
}
.productList__content {
  display: block;
  margin: auto 0;
  padding: 0 1.125rem;
}
@media all and (min-width: 48em) {
  .productList__content {
    border-left: 1px solid #4D4D4F;
  }
}
@media all and (min-width: 62em) {
  .productList__content {
    display: flex;
    align-items: center;
    flex-grow: 2;
    margin: 1.25rem 0;
  }
}
.productList__content-link {
  display: block;
  color: #fff;
  text-decoration: none;
  padding-right: 1.125rem;
  width: 100%;
}
@media all and (min-width: 62em) {
  .productList__content-link {
    width: 40%;
  }
}
.productList__content-link:hover,
.productList__content-link:focus {
  color: #fff;
}
@media all and (min-width: 48em) {
  .productList__content-link--desc {
    width: 60%;
  }
}
.productList__heading {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.55555556;
  letter-spacing: -0.00722222em;
  font-weight: bold;
  cursor: pointer;
  margin-top: 0;
}
.productList__desc {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.14285714;
  letter-spacing: 0.6px;
  line-height: 18px;
  margin-top: 0;
}
@media all and (max-width: 61.9375em) {
  .productList__desc {
    display: none;
  }
}
.productList__link-email,
.productList__link-specs {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.14285714;
  position: relative;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
}
.productList__link-email:hover,
.productList__link-specs:hover,
.productList__link-email:focus,
.productList__link-specs:focus {
  color: #0092C8;
}
.productList__link-email {
  margin-right: 0.5625rem;
  padding-top: 0.3125rem;
}
.productList__link-email-icon {
  font-size: 25px;
  position: absolute;
  bottom: 100%;
  left: 2px;
  color: #0092C8;
}
.productList__link-specs {
  margin-right: 0.5625rem;
  padding-top: 0.3125rem;
}
.productList__link-specs-icon {
  font-size: 25px;
  position: absolute;
  bottom: 100%;
  left: 4px;
  color: #0092C8;
}
.productList__footer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1.25rem 1.125rem;
  margin-top: 1.25rem;
}
@media all and (min-width: 48em) {
  .productList__footer {
    max-width: 210px;
  }
}
.productList__footer-iconlinks {
  display: flex;
  margin-left: auto;
}
.productList__details {
  display: block;
  border: 2px solid transparent;
  margin-top: 0.625rem;
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}
.productList__details:hover,
.productList__detailsfocus {
  background: #0266B2;
  border: 2px solid #0092C8;
}
@media all and (max-width: 47.9375em) {
  .productList__details {
    display: none;
  }
}
.blogPostList {
  display: flex;
  flex-direction: row;
  background: linear-gradient(90deg, #080808 0%, #414141 100%);
  transition: all 0.4s ease-in-out;
  width: 100%;
}
.blogPostList:hover,
.blogPostList:focus {
  background: #3A3A3A;
  border: 1px solid #0092C8;
}
.blogPostList__figure-link {
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding-right: 0.5625rem;
}
@media all and (max-width: 47.9375em) {
  .blogPostList__figure-link {
    display: none;
  }
}
.blogPostList__figure {
  width: 150px;
  height: 96px;
  margin-top: 0;
  cursor: pointer;
}
@media all and (min-width: 48em) {
  .blogPostList__figure {
    width: 230px;
    height: 120px;
  }
}
.blogPostList__image {
  height: 100%;
  width: 100%;
}
.blogPostList__content {
  display: block;
  margin: auto 0;
  padding: 0 1.125rem;
}
@media all and (min-width: 48em) {
  .blogPostList__content {
    border-left: 1px solid #4D4D4F;
  }
}
@media all and (min-width: 62em) {
  .blogPostList__content {
    display: flex;
    align-items: center;
    flex-grow: 2;
    margin: 1.25rem 0;
  }
}
.blogPostList__content-link {
  display: block;
  color: #fff;
  text-decoration: none;
  padding-right: 1.125rem;
  width: 100%;
}
@media all and (min-width: 62em) {
  .blogPostList__content-link {
    width: 40%;
  }
}
.blogPostList__content-link:hover,
.blogPostList__content-link:focus {
  color: #fff;
}
@media all and (min-width: 48em) {
  .blogPostList__content-link--desc {
    width: 60%;
  }
}
.blogPostList__heading {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.55555556;
  letter-spacing: -0.00722222em;
  font-weight: bold;
  cursor: pointer;
  margin-top: 0;
}
.blogPostList__desc {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.14285714;
  letter-spacing: 0.6px;
  line-height: 18px;
  margin-top: 0;
}
@media all and (max-width: 61.9375em) {
  .blogPostList__desc {
    display: none;
  }
}
.blogPostList__link-email,
.blogPostList__link-specs {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.14285714;
  position: relative;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
}
.blogPostList__link-email:hover,
.blogPostList__link-specs:hover,
.blogPostList__link-email:focus,
.blogPostList__link-specs:focus {
  color: #0092C8;
}
.blogPostList__link-email {
  margin-right: 0.5625rem;
  padding-top: 0.3125rem;
}
.blogPostList__link-email-icon {
  font-size: 25px;
  position: absolute;
  bottom: 100%;
  left: 2px;
  color: #0092C8;
}
.blogPostList__link-specs {
  margin-right: 0.5625rem;
  padding-top: 0.3125rem;
}
.blogPostList__link-specs-icon {
  font-size: 25px;
  position: absolute;
  bottom: 100%;
  left: 4px;
  color: #0092C8;
}
.blogPostList__footer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1.25rem 1.125rem;
  margin-top: 1.25rem;
}
@media all and (min-width: 48em) {
  .blogPostList__footer {
    max-width: 210px;
  }
}
.blogPostList__footer-iconlinks {
  display: flex;
  margin-left: auto;
}
.blogPostList__details {
  display: block;
  border: 2px solid transparent;
  margin-top: 0.625rem;
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}
.blogPostList__details:hover,
.blogPostList__detailsfocus {
  background: #0266B2;
  border: 2px solid #0092C8;
}
@media all and (max-width: 47.9375em) {
  .blogPostList__details {
    display: none;
  }
}
.connectNav {
  border-top: 1px solid #cfcdcd;
  border-bottom: 1px solid #cfcdcd;
}
.connectNav__row {
  display: flex;
  justify-content: space-around;
}
.connectNav__link {
  display: flex;
  flex-direction: column;
  justify-content: end;
  text-align: center;
  padding: 10px;
  width: 20%;
  border-left: 1px solid #cfcdcd;
  text-decoration: none;
}
.connectNav__link:last-of-type {
  border-right: 1px solid #cfcdcd;
}
.connectNav__link:hover {
  background-color: #1A1A1A;
}
@media all and (min-width: 48em) {
  .connectNav__link {
    padding: 20px;
  }
}
.connectNav__icon {
  height: 30px;
  margin: 0 auto;
}
@media all and (min-width: 48em) {
  .connectNav__icon {
    height: 50px;
  }
}
.connectNav__title {
  margin-top: 1.25rem;
  font-size: 0.8125rem;
  text-transform: uppercase;
}
@media all and (min-width: 48em) {
  .connectNav__title {
    font-size: 1.25rem;
  }
}
/**
 * Pages
 */
.color-list {
  list-style: none outside none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin: 10px -10px 0;
}
.color-list-item {
  width: 50%;
  float: left;
  padding-left: 10px;
  padding-right: 10px;
  flex: 0 0 auto;
}
@media screen and (min-width: 480px) {
  .color-list-item {
    width: 33.33333%;
  }
}
@media screen and (min-width: 768px) {
  .color-list-item {
    width: 25%;
  }
}
@media screen and (min-width: 992px) {
  .color-list-item {
    width: 20%;
  }
}
@media screen and (min-width: 1240px) {
  .color-list-item {
    width: 16.66667%;
  }
}
.patterns-color {
  margin-top: 10px;
  padding: 2px 2px 0;
  border: 1px solid #ccc;
}
.swatch {
  color: #fff;
  padding: 20px 0;
  text-align: center;
}
.label {
  font-size: 10px;
  display: flex;
  padding: 5px 7px;
  justify-content: space-between;
}
.product {
  padding-top: 56px;
  padding-bottom: 2.5rem;
  color: #fff;
}
@media all and (min-width: 48em) {
  .product {
    padding-top: 72px;
  }
}
.product__wrapper {
  width: 92%;
  margin-left: auto;
  margin-right: auto;
}
@media all and (max-width: 47.9375em) {
  .product__wrapper {
    width: 90%;
  }
}
@media all and (min-width: 62em) {
  .product__wrapper {
    max-width: 1150px;
  }
}
.product__header {
  font-size: 38px;
  font-size: 2.375rem;
  line-height: 1.10526316;
  letter-spacing: -0.00526316em;
  background-color: #A4664E;
  padding-top: 2.5rem;
  padding-bottom: 1.25rem;
}
@media all and (min-width: 48em) {
  .product__header {
    font-size: 60px;
    font-size: 3.75rem;
    line-height: 1.13333333;
    letter-spacing: -0.00666667em;
  }
}
.product__subnav {
  background-color: #252325;
  border-top: 1px solid #4D4D4F;
  border-bottom: 1px solid #4D4D4F;
}
.product__subnav-list {
  display: flex;
  margin-top: 0;
  letter-spacing: 0.8px;
}
@media all and (max-width: 47.9375em) {
  .product__subnav-list {
    flex-direction: column;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media all and (max-width: 47.9375em) {
  .blockTabNav .product__subnav-list {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
}
@media all and (min-width: 48em) and (max-width: 61.9375em) {
  .blockTabNav .product__subnav-list {
    flex-wrap: wrap;
  }
}
@media all and (min-width: 62em) {
  .blockTabNav .product__subnav-list {
    justify-content: space-between;
  }
}
.product__subnav-list-item {
  cursor: pointer;
}
@media all and (min-width: 48em) {
  .product__subnav-list-item {
    border-right: 1px solid #4D4D4F;
  }
  .product__subnav-list-item:first-of-type {
    border-left: 1px solid #4D4D4F;
  }
}
@media all and (min-width: 48em) and (max-width: 61.9375em) {
  .blockTabNav .product__subnav-list-item {
    width: 50%;
    flex: 0 0 auto;
    border: none !important;
  }
}
@media all and (min-width: 62em) {
  .blockTabNav .product__subnav-list-item {
    align-items: center;
    display: flex;
    flex: 1 0 auto;
    justify-content: center;
  }
}
.product__subnav-link {
  padding: 0.3125rem 2.25rem;
  color: white;
  text-decoration: none;
}
.product__subnav-link:hover,
.product__subnav-link:focus {
  color: #F2EAE7;
  text-decoration: underline;
}
@media all and (min-width: 62em) {
  .blockTabNav .product__subnav-link {
    padding: 0.3125rem 0.5625rem;
  }
}
.product__content {
  margin-top: 2.5rem;
}
.product__resources-list {
  padding-left: 0;
  list-style: none outside none;
}
.product__resources-list li label {
  display: flex;
}
.product__resources-list li label {
  display: flex;
}
.product__resources-list-item {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  padding: 0.625rem 1.125rem;
  margin-left: 25px;
  margin-bottom: 0.625rem;
}
@media all and (max-width: 47.9375em) {
  .product__resources-list-item {
    flex-direction: column;
    justify-content: flex-start;
    align-items: normal;
    margin-bottom: 1.25rem;
  }
}
.product__resources-list-item:nth-child(odd) {
  background: #414141;
  background: linear-gradient(90deg, #414141 0%, #080808 100%);
}
.product__resources-list-item:nth-child(odd):after {
  background: #414141;
}
.product__resources-list-item:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 100%;
  top: 0;
  bottom: 0;
  right: calc(100% - 1px);
  clip-path: polygon(90% 0, 100% 0, 100% 100%, 0% 100%);
}
.product__resources-list-item:before {
  content: '';
  position: absolute;
  width: 20px;
  height: 100%;
  top: 0;
  bottom: 0;
  right: calc(100% + 5px);
  background-color: #A4664E;
  clip-path: polygon(45% 0, 100% 0, 55% 100%, 0% 100%);
}
.product__resources-title {
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 1.54545455;
  letter-spacing: -0.00681818em;
  font-family: "HelveticaNeueLTStd-BdCn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  color: #fff;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}
.product__resources-title:hover,
.product__resources-title:focus {
  color: #0092C8;
  text-decoration: underline;
}
.product__infobox {
  background-color: #252325;
  padding: 1.25rem 1.125rem;
}
.product__infobox-figure {
  height: 180px;
  background-color: #252325;
}
.product__infobox-ctas {
  margin-bottom: 1.25rem;
}
@media all and (max-width: 47.9375em) {
  .product__infobox-ctas {
    display: flex;
    justify-content: center;
  }
}
.product__infobox-specs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 1.25rem;
}
.product__infobox-specs-title {
  color: #A4664E;
}
.product__infobox-specs-tag a {
  color: #fff;
}
.product__infobox-specs-tag a:after {
  content: ",";
}
.product__infobox-specs-tag a:last-child:after {
  content: "";
}
.product__sidebarCta {
  font-family: "HelveticaNeueLTStd-BdCn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  background: #0266B2;
  color: #fff;
  transition: background-color 0.2s ease;
  position: relative;
  overflow: hidden;
  padding-right: calc(1.125rem + 70px);
  font-family: "HelveticaNeueLTStd-Cn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  text-align: left;
  font-weight: 200;
  width: 100%;
}
.product__sidebarCta:hover,
.product__sidebarCta:focus,
.product__sidebarCta.is-current {
  background: #013b66;
  color: #fff;
}
.product__sidebarCta:hover[disabled],
.product__sidebarCta:focus[disabled],
.product__sidebarCta.is-current[disabled] {
  background: rgba(2, 102, 178, 0.35);
  color: rgba(255, 255, 255, 0.35);
}
.product__sidebarCta[disabled] {
  background: rgba(2, 102, 178, 0.35);
  color: rgba(255, 255, 255, 0.35);
}
.product__sidebarCta:before {
  content: "";
  background: transparent url(/resources/motif/images/button__arrow-icon.svg) 0 0 no-repeat;
  width: 38px;
  height: 12px;
  position: absolute;
  right: 10px;
  top: 50%;
  z-index: 3;
  transform: translateY(-50%);
}
.product__sidebarCta:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -10px;
  width: 70px;
  background-color: #0092C8;
  transform: skewX(-15deg);
}
.product__sidebarCta[disabled]:before {
  opacity: 0.35;
}
.product__sidebarCta[disabled]:after {
  background-color: rgba(0, 146, 200, 0.35);
}
.product__sidebarCta h5 {
  font-family: "HelveticaNeueLTStd-BdCn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  text-transform: uppercase;
  font-weight: 400;
}
.product__sidebarCta.download {
  position: relative;
  overflow: hidden;
  padding-right: calc(1.125rem + 70px);
}
.product__sidebarCta.download:before {
  content: "";
  background: transparent url(/resources/motif/images/download-icon.svg) 0 0 no-repeat;
  width: 24px;
  height: 21px;
  position: absolute;
  right: 17px;
  top: 50%;
  z-index: 3;
  transform: translateY(-50%);
}
.product__sidebarCta.download:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -10px;
  width: 70px;
  background-color: #0092C8;
  transform: skewX(-15deg);
}
.product__maincontent-title {
  text-transform: uppercase;
}
.product__features,
.product__description,
.product__related-media {
  margin-bottom: 2.5rem;
}
.product__related-media-row {
  margin-top: 1.25rem;
}
@media all and (min-width: 48em) {
  .product__related-media-row {
    display: flex;
    align-items: flex-start;
  }
}
.product__featured-product {
  flex-grow: 1;
  flex-shrink: 1;
}
.product__card-list {
  flex-grow: 0;
  flex-shrink: 0;
  list-style: none;
  margin: 1.25rem 0 0 0;
  padding: 0;
}
@media all and (min-width: 48em) {
  .product__card-list {
    width: 300px;
    padding-left: 1.125rem;
    margin-top: 0;
    max-height: 380px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
.product__card-list li {
  margin-top: 2.5rem;
}
.product__card-list li:first-child {
  margin-top: 0;
}
.product__section-title {
  font-family: "HelveticaNeueLTStd-BdCn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  color: #fff;
  text-transform: uppercase;
  position: relative;
}
.relatedMediaCard__card {
  margin-left: -0.5625rem;
  margin-right: -0.5625rem;
  display: flex;
  align-items: flex-start;
}
.relatedMediaCard__col {
  width: 50%;
  padding-left: 0.5625rem;
  padding-right: 0.5625rem;
}
.relatedMediaCard__figure,
.relatedMediaCard__video-figure,
.featuredCard__figure,
.featuredCard__video-figure {
  margin: 0;
  display: block;
  width: 100%;
  background: #0A0203;
  position: relative;
}
.relatedMediaCard__figure:before,
.relatedMediaCard__video-figure:before,
.featuredCard__figure:before,
.featuredCard__video-figure:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 56.25%;
}
.relatedMediaCard__figure img,
.relatedMediaCard__video-figure img,
.featuredCard__figure img,
.featuredCard__video-figure img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.no-objectfit .relatedMediaCard__figure img,
.no-objectfit .relatedMediaCard__video-figure img,
.no-objectfit .featuredCard__figure img,
.no-objectfit .featuredCard__video-figure img {
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.relatedMediaCard__video-figure:hover,
.relatedMediaCard__video-figure:focus,
.featuredCard__video-figure:hover,
.featuredCard__video-figure:focus {
  cursor: pointer;
}
.relatedMediaCard__video-figure:hover:after,
.relatedMediaCard__video-figure:focus:after,
.featuredCard__video-figure:hover:after,
.featuredCard__video-figure:focus:after {
  box-shadow: 0 4px 11px 0 #0a0203;
}
.relatedMediaCard__video-figure:after,
.featuredCard__video-figure:after {
  content: "";
  height: 30px;
  width: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 1;
  background-image: url("/resources/motif/icons/Button_Play.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: 0 3px 8px 0 #0a0203;
}
.relatedMediaCard__video-lightbox:not(.is-revealed) {
  display: none;
}
.relatedMediaCard__title {
  font-family: "HelveticaNeueLTStd-BdCn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  text-transform: uppercase;
  margin-top: 0;
  color: #A4664E;
}
.featuredCard__video-figure:after {
  height: 60px;
  width: 60px;
}
.featuredCard__video-lightbox:not(.is-revealed) {
  display: none;
}
.featuredCard__title {
  font-family: "HelveticaNeueLTStd-BdCn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  text-transform: uppercase;
  margin-top: 0.625rem;
  color: #A4664E;
}
.productsHome {
  color: #fff;
  padding-top: 56px;
  padding-bottom: 2.5rem;
}
@media all and (min-width: 48em) {
  .productsHome {
    padding-top: 72px;
  }
}
.productsHome__wrapper {
  width: 92%;
  margin-left: auto;
  margin-right: auto;
  /* Mixins */
}
@media all and (max-width: 47.9375em) {
  .productsHome__wrapper {
    width: 90%;
  }
}
@media all and (min-width: 62em) {
  .productsHome__wrapper {
    max-width: 1150px;
  }
}
.productsHome__wrapper:before,
.productsHome__wrapper:after {
  content: "\0020";
  height: 0;
  display: block;
  overflow: hidden;
}
.productsHome__wrapper:after {
  clear: both;
}
.productsHome__header-shell {
  background-color: #A4664E;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.productsHome__header {
  width: 92%;
  margin-left: auto;
  margin-right: auto;
  /* Mixins */
}
@media all and (max-width: 47.9375em) {
  .productsHome__header {
    width: 90%;
  }
}
@media all and (min-width: 62em) {
  .productsHome__header {
    max-width: 1150px;
  }
}
.productsHome__header:before,
.productsHome__header:after {
  content: "\0020";
  height: 0;
  display: block;
  overflow: hidden;
}
.productsHome__header:after {
  clear: both;
}
@media all and (min-width: 48em) {
  .productsHome__header {
    display: flex;
    align-items: center;
  }
}
.productsHome__header-title {
  font-size: 48px;
  font-size: 3rem;
  line-height: 1.04166667;
  letter-spacing: -0.00583333em;
}
@media all and (min-width: 48em) {
  .productsHome__header-title {
    font-size: 60px;
    font-size: 3.75rem;
    line-height: 1.13333333;
    letter-spacing: -0.00666667em;
    padding-right: 2.25rem;
  }
}
.productsHome__header-description {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.625;
}
@media all and (min-width: 48em) {
  .productsHome__header-description {
    border-left: 2px solid #F2EAE7;
    padding-left: 2.25rem;
    max-width: 600px;
  }
}
.lists__unstyled li label {
  display: flex;
}
.productsHome__filters--wrapper {
  padding-bottom: 1.25rem;
}
.productsHome__filters {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  height: 300px;
  flex-direction: row;
  border-bottom: 1px solid #4D4D4F;
  height: auto;
}
.productsHome__filters-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 0;
}
.productsHome__filters-list li {
  margin: 0.625rem 0.5625rem;
}
.productsHome__filters-list label {
  font-family: Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.625;
  font-weight: 200;
  align-items: center;
}
.productsHome__filters-list label:before {
  content: "";
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  margin-bottom: 2px;
  border: 2px solid #fff;
  width: 20px;
  height: 20px;
}
.productsHome__filters-list input {
  display: none;
}
.productsHome__filters-list input:checked + label:before {
  content: '✓';
}
.productsHome__filters-leftside {
  display: flex;
  width: 50%;
}
@media all and (max-width: 47.9375em) {
  .productsHome__filters-leftside {
    width: 100%;
    justify-content: center;
  }
}
.productsHome__filters-rightside {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
}
@media all and (max-width: 47.9375em) {
  .productsHome__filters-rightside {
    width: 100%;
    justify-content: center;
  }
}
.productsHome__filters-title {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.55555556;
  letter-spacing: -0.00722222em;
  color: #fff;
  text-decoration: none;
  font-weight: 400;
  padding: 1.25rem 1.125rem;
  cursor: pointer;
}
.productsHome__filters-title:after {
  content: "";
  display: inline-block;
  margin-top: 0.5625rem;
  margin-left: 0.5625rem;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 9px solid #0266B2;
}
.productsHome__filters-title:hover,
.productsHome__filters-title:focus {
  background-color: #0266B2;
  color: #fff;
}
.productsHome__filters-title:hover:after {
  content: "";
  display: inline-block;
  margin-top: 0.5625rem;
  margin-left: 0.5625rem;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 9px solid #fff;
}
.productsHome__filters-title.is-revealed {
  color: #fff;
  background-color: #0266B2;
}
.productsHome__filters-title.is-revealed:after {
  content: "";
  display: inline-block;
  margin-top: 0.5625rem;
  margin-left: 0.5625rem;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 9px solid #fff;
  border-top: 0;
}
.productsHome__search-bar:not(.is-revealed) {
  display: none;
}
.productsHome__views {
  display: flex;
  padding-left: 0.5625rem;
  margin-left: 0.5625rem;
  border-left: 1px solid #4D4D4F;
}
.productsHome__view {
  padding: 0.625rem 1.125rem;
  cursor: pointer;
}
.productsHome__view svg {
  color: #0266B2;
  margin-right: 0.28125rem;
}
.productsHome__view.is-revealed {
  border: 1px solid #0092C8;
}
.productsHome__filters-types {
  background-color: #252325;
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}
.productsHome__recent {
  margin-top: 2.5rem;
}
.productsHome__products {
  margin-top: 2.5rem;
}
.productsHome__products-title {
  text-transform: uppercase;
}
@media all and (min-width: 48em) {
  .productsHome__row {
    display: flex;
    flex-wrap: wrap;
  }
}
.productsHome__column {
  display: flex !important;
  margin-top: 2.5rem;
}
.investors {
  padding-bottom: 2.5rem;
  color: #fff;
}
.investors__wrapper {
  width: 92%;
  margin-left: auto;
  margin-right: auto;
}
@media all and (max-width: 47.9375em) {
  .investors__wrapper {
    width: 90%;
  }
}
@media all and (min-width: 62em) {
  .investors__wrapper {
    max-width: 1150px;
  }
}
.investors__content {
  width: 92%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
@media all and (max-width: 47.9375em) {
  .investors__content {
    width: 90%;
  }
}
@media all and (min-width: 62em) {
  .investors__content {
    max-width: 1150px;
  }
}
.investors__letter p {
  max-width: none;
}
.investors__letter:not(.is-revealed) {
  position: relative;
  max-height: 450px;
  overflow: hidden;
}
.investors__letter:not(.is-revealed):before {
  content: '';
  position: absolute;
  width: 100%;
  height: 70px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,0a0203+100&0+0,1+100 */
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #0a0203 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #0a0203 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #0a0203 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#0a0203', GradientType=0);
  /* IE6-9 */
}
.investors__readMore {
  display: block;
  width: 100%;
  text-align: center;
  text-decoration: underline;
  text-underline-position: under;
  cursor: pointer;
  margin-top: 1.25rem;
}
.investors__readMore:after {
  content: 'Read More';
}
.investors__readMore.is-revealed:after {
  content: 'Read Less';
}
.investors__tabs {
  width: 92%;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 1px solid #a9a6a7;
}
@media all and (max-width: 47.9375em) {
  .investors__tabs {
    width: 90%;
  }
}
@media all and (min-width: 62em) {
  .investors__tabs {
    max-width: 1150px;
  }
}
.investors__tab {
  position: relative;
  background: linear-gradient(90deg, #080808 0%, #414141 100%);
  color: #fff;
  outline: 0;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #a9a6a7;
  padding: 0.625rem 1.125rem;
  margin-bottom: -1px;
  z-index: 0;
}
.investors__tab.is-revealed {
  background: #0A0203;
  text-decoration: underline;
  text-decoration-color: #A4664E;
  text-underline-position: under;
  border-top: 1px solid #a9a6a7;
  border-left: 1px solid #a9a6a7;
  border-right: 1px solid #a9a6a7;
  border-bottom: 1px solid #0A0203;
  z-index: 1;
}
.accordion-investors:not(.is-revealed) {
  display: none;
}
.accordian__presscard {
  background-color: #1A1A1A;
  position: relative;
  margin-left: 1.25rem;
  margin-bottom: 1.125rem;
  padding: 1.25rem 1.125rem;
}
.accordian__presscard:before {
  content: "";
  background-color: #1A1A1A;
  top: 0;
  left: -1.25rem;
  width: 0.625rem;
  height: 100%;
  background-color: #A4664E;
  position: absolute;
}
.accordian__presscard .presscard__download {
  padding-top: 0.625rem;
}
@media all and (min-width: 48em) {
  .accordian__presscard {
    display: flex;
  }
  .presscard__container {
    flex: 1;
  }
  .presscard__download {
    padding-top: 0;
    padding-left: 1.125rem;
    align-self: flex-end;
  }
}
.foobar {
  margin-bottom: 3.75rem;
}
.stock-ticker-wrapper {
  background-color: #A4664E;
}
.stock-ticker {
  width: 92%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  /*
    text-align: justify;
    text-align-last: justify;
    */
}
@media all and (max-width: 47.9375em) {
  .stock-ticker {
    width: 90%;
  }
}
@media all and (min-width: 62em) {
  .stock-ticker {
    max-width: 1150px;
  }
}
.stock-ticker .stock-name,
.stock-ticker .stock-price,
.stock-ticker .stock-change,
.stock-ticker .stock-time {
  vertical-align: middle;
  display: inline-block;
  text-align: left;
  margin-right: 4rem;
}
.stock-ticker .stock-price {
  font-family: "HelveticaNeueLTStd-Cn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
}
.stock-ticker .stock-btn {
  margin-top: 0;
}
.stock-ticker .stock-arrow-equal:before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 5px;
  margin-right: 4px;
  background: #fff;
  vertical-align: middle;
}
.stock-ticker .stock-arrow-up:before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 4px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 10px solid #fff;
  vertical-align: middle;
}
.stock-ticker .stock-arrow-down:before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 4px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 10px solid #fff;
  vertical-align: middle;
}
.stock-ticker .sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}
.pressRelease {
  color: #fff;
  padding-top: calc(2.5rem * 2);
  padding-bottom: calc(2.5rem * 2);
}
.calculators {
  color: #fff;
  padding-top: calc(2.5rem * 1);
  padding-bottom: calc(2.5rem * 2);
}
.pressRelease__infobox {
  background: linear-gradient(90deg, #080808 0%, #414141 100%);
  padding: 1.25rem 1.125rem;
}
.pressRelease__infobox-date {
  position: relative;
  padding-left: 80px;
}
.pressRelease__infobox-date:before {
  content: 'Date:';
  position: absolute;
  color: #A4664E;
  margin-left: -80px;
}
.pressRelease__infobox-contact {
  position: relative;
  padding-left: 80px;
}
.pressRelease__infobox-contact:before {
  content: 'Contact:';
  position: absolute;
  color: #A4664E;
  margin-left: -80px;
}
@media all and (max-width: 47.9375em) {
  .payoffimg1,
  .payoffimg2 {
    float: none;
  }
}
.blogLanding {
  color: #fff;
  padding-bottom: 2.5rem;
}
.blogLanding__wrapper {
  width: 92%;
  margin-left: auto;
  margin-right: auto;
  /* Mixins */
}
@media all and (max-width: 47.9375em) {
  .blogLanding__wrapper {
    width: 90%;
  }
}
@media all and (min-width: 62em) {
  .blogLanding__wrapper {
    max-width: 1150px;
  }
}
.blogLanding__wrapper:before,
.blogLanding__wrapper:after {
  content: "\0020";
  height: 0;
  display: block;
  overflow: hidden;
}
.blogLanding__wrapper:after {
  clear: both;
}
.blogLanding__header-shell {
  position: relative;
  background-color: #A4664E;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.blogLanding__header {
  width: 92%;
  margin-left: auto;
  margin-right: auto;
  /* Mixins */
  position: relative;
  padding-top: 10rem;
  padding-bottom: 10rem;
}
@media all and (max-width: 47.9375em) {
  .blogLanding__header {
    width: 90%;
  }
}
@media all and (min-width: 62em) {
  .blogLanding__header {
    max-width: 1150px;
  }
}
.blogLanding__header:before,
.blogLanding__header:after {
  content: "\0020";
  height: 0;
  display: block;
  overflow: hidden;
}
.blogLanding__header:after {
  clear: both;
}
@media all and (min-width: 48em) {
  .blogLanding__header {
    display: flex;
    align-items: center;
  }
}
.blogLanding__header-content {
  position: relative;
  width: 100%;
  background: #0A0203;
  padding: 20px;
}
@media all and (min-width: 48em) {
  .blogLanding__header-content {
    display: flex;
    align-items: center;
  }
}
.blogLanding__header-title {
  font-size: 48px;
  font-size: 3rem;
  line-height: 1.04166667;
  letter-spacing: -0.00583333em;
}
@media all and (min-width: 48em) {
  .blogLanding__header-title {
    font-size: 60px;
    font-size: 3.75rem;
    line-height: 1.13333333;
    letter-spacing: -0.00666667em;
    padding-right: 2.25rem;
  }
}
.blogLanding__header-description {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.625;
}
@media all and (min-width: 48em) {
  .blogLanding__header-description {
    border-left: 2px solid #F2EAE7;
    padding-left: 2.25rem;
    max-width: 600px;
  }
}
.lists__unstyled li label {
  display: flex;
}
.blogLanding__filters--wrapper {
  padding-bottom: 1.25rem;
}
.blogLanding__filters {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  height: 300px;
  flex-direction: row;
  border-bottom: 1px solid #4D4D4F;
  height: auto;
}
.blogLanding__filters-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 0;
}
.blogLanding__filters-list li {
  margin: 0.625rem 0.5625rem;
}
.blogLanding__filters-list label {
  font-family: Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.625;
  font-weight: 200;
  align-items: center;
}
.blogLanding__filters-list label:before {
  content: "";
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  margin-bottom: 2px;
  border: 2px solid #fff;
  width: 20px;
  height: 20px;
}
.blogLanding__filters-list input {
  display: none;
}
.blogLanding__filters-list input:checked + label:before {
  content: '✓';
}
.blogLanding__filters-leftside {
  display: flex;
  width: 50%;
}
@media all and (max-width: 47.9375em) {
  .blogLanding__filters-leftside {
    width: 100%;
    justify-content: center;
  }
}
.blogLanding__filters-rightside {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
}
@media all and (max-width: 47.9375em) {
  .blogLanding__filters-rightside {
    width: 100%;
    justify-content: center;
  }
}
.blogLanding__filters-title {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.55555556;
  letter-spacing: -0.00722222em;
  color: #fff;
  text-decoration: none;
  font-weight: 400;
  padding: 1.25rem 1.125rem;
  cursor: pointer;
}
.blogLanding__filters-title:after {
  content: "";
  display: inline-block;
  margin-top: 0.5625rem;
  margin-left: 0.5625rem;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 9px solid #0266B2;
}
.blogLanding__filters-title:hover,
.blogLanding__filters-title:focus {
  background-color: #0266B2;
  color: #fff;
}
.blogLanding__filters-title:hover:after {
  content: "";
  display: inline-block;
  margin-top: 0.5625rem;
  margin-left: 0.5625rem;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 9px solid #fff;
}
.blogLanding__filters-title.is-revealed {
  color: #fff;
  background-color: #0266B2;
}
.blogLanding__filters-title.is-revealed:after {
  content: "";
  display: inline-block;
  margin-top: 0.5625rem;
  margin-left: 0.5625rem;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 9px solid #fff;
  border-top: 0;
}
.blogLanding__search-bar:not(.is-revealed) {
  display: none;
}
.blogLanding__views {
  display: flex;
  padding-left: 0.5625rem;
  margin-left: 0.5625rem;
  border-left: 1px solid #4D4D4F;
}
.blogLanding__view {
  padding: 0.625rem 1.125rem;
  cursor: pointer;
}
.blogLanding__view svg {
  color: #0266B2;
  margin-right: 0.28125rem;
}
.blogLanding__view.is-revealed {
  border: 1px solid #0092C8;
}
.blogLanding__filters-types {
  background-color: #252325;
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}
.blogLanding__recent {
  margin-top: 2.5rem;
}
.blogLanding__blogPost {
  margin-top: 2.5rem;
}
.blogLanding__blogPost .card__listItem {
  list-style: none;
}
.blogLanding__blogPost-title {
  text-transform: uppercase;
}
@media all and (min-width: 48em) {
  .blogLanding__row {
    display: flex;
    flex-wrap: wrap;
  }
}
.blogLanding__column {
  display: flex !important;
  margin-top: 2.5rem;
}
.blogLandingList__column {
  display: flex !important;
  margin-top: 0.3125rem;
}
.blogLanding__pagination {
  text-align: center;
}
.blogLanding__pagination ul {
  list-style-type: none;
}
.blogLanding__pagination ul li {
  display: inline-block;
  margin-left: 0;
}
.blogLanding__pagination ul li a {
  padding: 10px;
  color: inherit;
  text-decoration: none;
}
.blogLanding__pagination ul li.is-active {
  color: #0092C8;
}
.blogLanding__pagination ul li a:hover {
  color: #0092C8;
  text-decoration: underline;
}
.loading {
  display: none;
  width: 100%;
  text-align: center;
  margin-top: 25px;
}
.blogPost__card-figure {
  margin-bottom: 0;
}
.blogPost__card-title {
  margin-top: 0;
}
.blogPost__card-desc {
  margin: 0.5rem 0 1rem;
}
.blogPost {
  color: #fff;
  padding-top: calc(2.5rem * 2);
  padding-bottom: calc(2.5rem * 2);
}
.calculators {
  color: #fff;
  padding-top: calc(2.5rem * 1);
  padding-bottom: calc(2.5rem * 2);
}
.blogPost__infobox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  background: linear-gradient(90deg, #080808 0%, #414141 100%);
  padding: 1.25rem 1.125rem;
}
.blogPost__infobox-date {
  position: relative;
  padding-left: 60px;
}
.blogPost__infobox-date:before {
  content: 'Date:';
  position: absolute;
  color: #A4664E;
  margin-left: -60px;
}
.blogPost__infobox-author {
  position: relative;
  padding-left: 60px;
}
.blogPost__infobox-author:before {
  content: 'Author:';
  position: absolute;
  color: #A4664E;
  margin-left: -60px;
}
.blogPost__infobox-image {
  position: relative;
  width: 100%;
  max-width: 30%;
}
.blogPost__infobox-author-figure {
  position: relative;
  display: flex;
  width: 100%;
  margin: 0;
  padding-top: 100%;
}
.blogPost__infobox-author-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.blogPost_surroundingPosts {
  max-width: 800px;
  margin: auto;
  margin-top: 40px;
}
.blogPost_surroundingPosts .blogPost_surroundingPosts-newer {
  float: left;
}
.blogPost_surroundingPosts .blogPost_surroundingPosts-newer h5 {
  font-size: 18px;
  padding-bottom: 5px;
  text-align: center;
}
.blogPost_surroundingPosts .blogPost_surroundingPosts-older {
  float: right;
}
.blogPost_surroundingPosts .blogPost_surroundingPosts-older h5 {
  font-size: 18px;
  padding-bottom: 5px;
  text-align: center;
}
.blogPost_surroundingPosts .card__listItem {
  width: 100%;
  list-style: none;
}
.blogPost_surroundingPosts .card__listItem .blogPost__card-figure img {
  width: 100%;
}
.blogPost_surroundingPosts .card__listItem .card__heading {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.55555556;
  letter-spacing: -0.00722222em;
  font-family: "HelveticaNeueLTStd-BdCn", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
  text-transform: uppercase;
  color: #fff;
  margin-top: 0;
  padding-bottom: 12px;
  line-height: 1;
}
.blogPost_surroundingPosts .card__listItem .card__heading:after {
  display: none;
}
/**
 * Pages
 */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
/**
 * Print
 */
@media print {
  * {
    background: transparent !important;
    color: black !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  html {
    font: 12pt/1.5em Georgia, "Times New Roman", Times, serif;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    line-height: 1.1em;
    margin-bottom: 0.5em;
    margin-top: 1em;
    page-break-after: avoid;
    orphans: 3;
    widows: 3;
  }
  li h1,
  li h2,
  li h3,
  li h4,
  li h5,
  li h6 {
    margin-top: 0;
  }
  h1 {
    font-size: 24pt;
  }
  h2 {
    font-size: 21pt;
  }
  h3 {
    font-size: 18pt;
  }
  h4 {
    font-size: 16pt;
  }
  h5 {
    font-size: 14pt;
  }
  h6 {
    font-size: 12pt;
  }
  blockquote,
  p,
  ul,
  ol,
  dl,
  figure,
  img {
    margin-bottom: 1em;
  }
  figure img {
    margin-bottom: 0;
  }
  ul,
  ol,
  dd {
    margin-left: 3em;
  }
  pre,
  blockquote {
    border-left: 0.5em solid #999;
    padding-left: 1.5em;
    page-break-inside: avoid;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }
  figcaption {
    text-transform: uppercase;
    font-size: 10pt;
  }
  strong {
    font-weight: bold;
  }
  em {
    font-style: italic;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  form,
  video,
  nav {
    display: none !important;
  }
  @page {
    margin: 2cm;
  }
}
/**
 * Prototype Styles
 * REMOVE WHEN BEGINNING PRODUCTION
 */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url(http://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh7USSwiPHA.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url(http://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjx4wWw.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url(http://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVSwiPHA.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url(http://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50XSwiPHA.ttf) format('truetype');
}
/*

Config

*/
/*

Font Faces

*/
/*

Applied Font Faces

These classes based on every typeface used on the project, including many which were
embedded via `@font-face` declarations.

*/
.proto__lato-light,
.proto__lato-regular,
.proto__lato-bold,
.proto__lato-black,
.tabs nav > ul a,
.tabs__list a {
  font-family: "Lato", Helvetica, Arial, "Arial Unicode", "Lucida Sans Unicode", sans-serif;
}
.proto__lato-light {
  font-weight: 300;
}
.proto__lato-regular {
  font-weight: 400;
}
.proto__lato-bold,
.tabs nav > ul a,
.tabs__list a {
  font-weight: 700;
}
.proto__lato-black {
  font-weight: 900;
}
/*

Type Styles

*/
/*

Body Text

A quick class to help make any element's text look like the site's default body text.

*/
dt,
th,
.input-label,
strong {
  font-weight: 700;
}
/*

Blockquote

*/
.styles__blockquote {
  padding-left: 1.125rem;
  font-size: 128%;
  color: #a9a6a7;
  border-left: 7px solid #ddd;
}
/*

Forms

*/
input,
textarea {
  padding: 0.625rem 1.125rem;
  background: #fff;
  border: 1px solid #ddd;
  line-height: normal;
  border-radius: 0.1875rem;
  -webkit-box-shadow: inset 0 0.1875rem 0.1875rem rgba(10, 2, 3, 0.05);
  -moz-box-shadow: inset 0 0.1875rem 0.1875rem rgba(10, 2, 3, 0.05);
  -o-box-shadow: inset 0 0.1875rem 0.1875rem rgba(10, 2, 3, 0.05);
  box-shadow: inset 0 0.1875rem 0.1875rem rgba(10, 2, 3, 0.05);
}
input:hover,
textarea:hover,
input:focus,
textarea:focus,
input:active,
textarea:active,
input.is-current,
textarea.is-current {
  border-color: #4D4D4F;
}
textarea {
  /* Display & Box Model */
  min-height: 9.1875rem;
}
/*

Buttons

*/
/*

Panels

````panel-alt
<div class="panel--alt">
    <h3>This is a Panel</h3>
    <p>This is a <a href="#">feedback message</a> for the user.</p>
    <p><a href="#" class="btn">User Action</a></p>
</div>
<div class="panel--alt">
    <p>This is a feedback panel with no heading.</p>
</div>
````

````panel-inverse
<div class="panel--inverse">
    <h3>This is a Panel</h3>
    <p>This is a <a href="#">feedback message</a> for the user.</p>
    <p><a href="#" class="btn">User Action</a></p>
</div>
<div class="panel--inverse">
    <p>This is a feedback panel with no heading.</p>
</div>
````

*/
.panel,
[class*="panel-"],
[class*="-panel"] {
  border-radius: 0.1875rem;
  color: #252325;
  border: 1px solid #cfcbca;
  background-color: #f7f7f9;
}
.panel--alt {
  background-color: #fff;
}
.panel--inverse {
  color: #fff;
  background-color: #0266B2;
  border-color: #0266B2;
}
.panel--inverse h1,
.panel--inverse h2,
.panel--inverse h3,
.panel--inverse h4,
.panel--inverse h5,
.panel--inverse h6 {
  color: inherit;
}
.panel--inverse a,
.panel--inverse a:hover,
.panel--inverse a:focus {
  color: inherit;
}
.panel--inverse a:hover,
.panel--inverse a:focus {
  color: #cfcbca;
}
.panel--inverse .btn,
.panel--inverse .btn:hover,
.panel--inverse .btn:focus {
  color: #252325;
}
.alert-panel--info {
  border-color: #00a4d8;
  background-color: #eaf7ff;
}
.alert-panel--error {
  border-color: #e63c4d;
  background-color: #f6e1df;
}
.alert-panel--success {
  border-color: #72bc44;
  background-color: #f4faf2;
}
/*

Figures

*/
.figure > a,
[class*="figure--"] > a {
  padding: 0.3125rem;
  border: 1px solid #cfcdcd;
}
.figure > a:hover,
[class*="figure--"] > a:hover,
.figure > a:focus,
[class*="figure--"] > a:focus {
  border-color: #0092C8;
}
.figure figcaption,
.figcaption {
  padding: 0.625rem 1.125rem;
}
/*

List Modules

*/
/*

Nav Bar

A quick navigation bar, typically good for prototyping the main menu of a site.

````nav-bar
<nav class="nav-bar--menu" role="navigation">
    <h3 class="proto__nav-bar-title js-expand"
     id="reveal-main-nav"
     data-reveal="main-nav-list">
        <svg class="icon"><use xlink:href="#rows"></svg>
        <b class="button-label">Main Menu</b>
    </h3>
    <ul class="proto__nav-bar-list expand__target" id="main-nav-list">
        <li>
            <a class="proto__nav-bar-link is-current" href="#">
                Current Nav Link
            </a>
        </li>
        <li>
            <a class="proto__nav-bar-link" href="#">
                Nav Link
            </a>
        </li>
        <li>
            <a class="proto__nav-bar-link" href="#">
                Nav Link
            </a>
        </li>
    </ul>
</nav>
````

*/
.proto__nav-bar-title {
  margin: 0;
  padding: 0.625rem 4%;
  display: inline-block;
  color: #fff;
  background: #0266B2;
  cursor: pointer;
}
.proto__nav-bar-title:hover,
.proto__nav-bar-title:focus,
.proto__nav-bar-title.is-expanded {
  background: #024f82;
}
@media all and (min-width: 48em) {
  .js .nav-bar--menu .proto__nav-bar-title {
    display: none;
  }
}
.proto__nav-bar-list {
  margin: 0;
  background: #0266B2;
}
@media all and (min-width: 48em) {
  .js .nav-bar--menu .proto__nav-bar-list {
    max-height: 999px;
  }
}
@media all and (min-width: 30em) {
  .proto__nav-bar-list > li {
    width: 50%;
  }
  .proto__nav-bar-list > li:nth-child( odd ) {
    clear: both;
  }
}
@media all and (min-width: 30em) {
  .proto__nav-bar-list > li {
    width: auto;
  }
  .proto__nav-bar-list > li:nth-child( odd ) {
    clear: none;
  }
}
.proto__nav-bar-list ul {
  margin: 0;
}
.proto__nav-bar-link {
  color: #fff;
  text-decoration: none;
  padding: 0.625rem 4%;
}
.proto__nav-bar-link:hover,
.proto__nav-bar-link:focus,
.proto__nav-bar-link.is-current {
  color: #fff;
  background: #024f82;
}
@media all and (min-width: 30em) {
  .proto__nav-bar-link {
    padding: 0.625rem 1.125rem;
  }
}
@media all and (min-width: 48em) {
  .proto__nav-bar-link {
    padding: 1.25rem 1.125rem;
  }
}
/*

Vertical Nav

````vertical-nav
<ul class="proto__vertical-nav">
    <li>
        <a href="#">Nav Link</a>
    </li>
    <li>
        <a href="#" class="is-current">Current Nav Link</a>
        <ul>
            <li>
                <a href="#">Nav Link</a>
            </li>
            <li>
                <a href="#">Nav Link</a>
            </li>
            <li>
                <a href="#" class="is-current">Current Nav Link</a>
            </li>
            <li>
                <a href="#">Nav Link</a>
            </li>
        </ul>
    </li>
    <li>
        <a href="#">Nav Link</a>
    </li>
    <li>
        <a href="#">Nav Link</a>
    </li>
    <li>
        <a href="#">Nav Link</a>
    </li>
</ul>
````

*/
.proto__vertical-nav a {
  color: #024f82;
  padding: 0.3125rem 1.125rem;
  display: block;
  text-decoration: none;
}
.proto__vertical-nav a:hover,
.proto__vertical-nav a:focus,
.proto__vertical-nav .is-current {
  background-color: #eaf7ff;
}
.proto__vertical-nav li ul {
  margin: 0;
  padding: 0 0 0 1.125rem;
}
.proto__vertical-nav li ul li {
  list-style: none outside none;
}
/*

Breadcrumb

*/
.lists__breadcrumb-list {
  text-transform: capitalize;
}
.lists__breadcrumb-list li:before {
  content: "/";
  margin-right: 0.5625rem;
}
.lists__breadcrumb-list li:first-child:before {
  content: "";
  margin: 0;
}
/*

Button List

*/
.lists__horizontal--buttons li:not(:first-child) a {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.lists__horizontal--buttons li:not(:last-child) a {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.lists__horizontal--buttons a {
  border-left-width: 0;
}
.lists__horizontal--buttons li:first-child a {
  border-left-width: 1px;
}
/*

Tabs

*/
.tabs {
  margin-top: 1.1111111111111112em;
}
.tabs nav > ul,
.tabs__list {
  border-bottom: 1px solid #ddd;
  margin-bottom: 1.25rem;
}
.tabs nav > ul a,
.tabs__list a {
  /* Extends .proto__lato-bold */
  border-top-right-radius: 0.1875rem;
  border-top-left-radius: 0.1875rem;
  padding: 0.625rem 1.125rem;
  color: #cfcbca;
  text-decoration: none;
  position: relative;
  bottom: -1px;
  z-index: 30;
  border: 1px solid transparent;
}
.tabs nav > ul a:hover,
.tabs__list a:hover,
.tabs nav > ul a:focus,
.tabs__list a:focus {
  color: #252325;
}
.tabs nav > ul .is-current,
.tabs__list .is-current {
  color: #252325;
  background-color: #fff;
  border-color: #cfcbca;
  border-bottom-color: transparent;
  z-index: 60;
}
.js.csstransitions .tabs > nav + *,
.js.csstransitions .tabs__section {
  -webkit-transition: height 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-transition: height 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: height 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: height 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.tabs > nav + * > * > :first-child,
.tabs .tab > :first-child {
  margin-top: 0;
}
/*

Tables

*/
th {
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 0.0625rem solid #ddd;
}
th + th,
td + td {
  border-left: 0.0625rem solid #ddd;
}
tr + tr td {
  border-top: 0.0625rem solid #ddd;
}
td[data-th]:before {
  /* Pretty */
  font-weight: 700;
}
/*

Messaging

*/
.proto__bubble,
[class*="messaging__input-alert"] {
  margin: 0;
  padding: 0.625rem;
  background-color: #fff;
  position: relative;
  border-radius: 0.1875rem;
  -webkit-box-shadow: 0 0 0.4375rem rgba(10, 2, 3, 0.5);
  -moz-box-shadow: 0 0 0.4375rem rgba(10, 2, 3, 0.5);
  -o-box-shadow: 0 0 0.4375rem rgba(10, 2, 3, 0.5);
  box-shadow: 0 0 0.4375rem rgba(10, 2, 3, 0.5);
  -webkit-transition: opacity 0.15s linear;
  -moz-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.proto__bubble:after,
[class*="messaging__input-alert"]:after {
  position: absolute;
  border: 0.3125rem solid transparent;
  content: "";
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  display: block;
}
.proto__bubble.left-wisp:after,
.proto__bubble.right-wisp:after,
[class*="messaging__input-alert"].left-wisp:after,
[class*="messaging__input-alert"].right-wisp:after {
  top: 50%;
  margin-top: -0.3125rem;
}
.proto__bubble.left-wisp:after,
[class*="messaging__input-alert"].left-wisp:after {
  left: -0.625rem;
  border-right-color: #fff;
}
.proto__bubble.right-wisp:after,
[class*="messaging__input-alert"].right-wisp:after {
  right: -0.625rem;
  border-left-color: #fff;
}
.proto__bubble.top-wisp:after,
.proto__bubble.bottom-wisp:after,
[class*="messaging__input-alert"].top-wisp:after,
[class*="messaging__input-alert"].bottom-wisp:after {
  left: 50%;
  margin-left: -0.3125rem;
}
.proto__bubble.top-wisp:after,
[class*="messaging__input-alert"].top-wisp:after {
  top: -0.625rem;
  border-bottom-color: #fff;
}
.proto__bubble.bottom-wisp:after,
[class*="messaging__input-alert"].bottom-wisp:after {
  bottom: -0.625rem;
  border-top-color: #fff;
}
/*

Input Alerts

*/
[class*="messaging__input-alert"] {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  padding: 0.3125rem 0.625rem;
  color: #fff;
}
[class*="messaging__input-alert"]:after {
  left: 0.625rem;
  margin-left: 0;
  top: -0.625rem;
  border-bottom-color: #fff;
}
.messaging__input-alert--warning {
  background-color: #F7941F;
}
.messaging__input-alert--warning:after {
  border-bottom-color: #F7941F;
}
.messaging__input-alert--info {
  background-color: #013b66;
}
.messaging__input-alert--info:after {
  border-bottom-color: #013b66;
}
.messaging__input-alert--error {
  background-color: #F7951B;
}
.messaging__input-alert--error:after {
  border-bottom-color: #F7951B;
}
.messaging__input-alert--success {
  background-color: #72bc44;
}
.messaging__input-alert--success:after {
  border-bottom-color: #72bc44;
}
.no-boxshadow .proto__bubble,
.no-boxshadow [class*="messaging__input-alert"] {
  border: 1px solid #a9a6a7;
}
/*

Form Modules

*/
.elements__attached-label {
  border: 1px solid #ddd;
  border-radius: 0.1875rem;
}
.elements__attached-label:hover,
.elements__attached-label:focus,
.elements__attached-label:active,
.elements__attached-label.is-current {
  border-color: #4D4D4F;
}
.elements__attached-label .label {
  text-align: center;
  padding: 0 1.125rem;
  background-color: #f7f7f9;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.elements__attached-label .label:first-child,
.elements__attached-label .label + input {
  border-left: none;
}
.elements__attached-label input,
.elements__attached-label [class*="button"] {
  border-radius: 0;
  border: none;
}
.elements__attached-label input + .label,
.elements__attached-label .label:last-child {
  border-right: none;
}
.proto__horizontalLineup {
  display: flex;
}
.proto__horizontalLineup > * {
  padding-right: 1em;
}
.proto__blockWrapper {
  margin-top: 3em;
}
@media all and (min-width: 62em) {
  .proto__blockWrapper {
    margin-top: 5em;
  }
}
@media all and (max-width: 47.9375em) {
  .image-wrapper {
    margin-top: 3em;
  }
}
.image-wrapper > img {
  width: 100%;
}
/*# sourceMappingURL=motif.css.map */